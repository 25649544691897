import PropTypes, { arrayOf } from "prop-types";
import React from "react";
import { Button, Dropdown, Icon, Segment } from "semantic-ui-react";
import { sortBy } from "lodash";
import ActivityController from "../../containers/activity/activityController";
import contactsMap from "../../helpers/contactsMap";
import MemoForm from "./MemoForm";
import MessageForm from "./MessageForm";
import { Account } from "../../../shared/models/account";
import MemosResource from "../../actions/memosActions";
import { ActivityShape } from "../../../shared/shapes/activity.shape";
import { MacroShape } from "../../../shared/shapes/macro.shape";
import { ProjectShape } from "../../../shared/shapes/project.shape";
import { BuyerShape } from "../../../shared/shapes/buyer.shape";
import { ContractorShape } from "../../../shared/shapes/contractor.shape";
import { ContactsShape } from "../../../shared/shapes/contacts.shape";

class ActivityActions extends React.Component {
  constructor(props) {
    super(props);

    const { openActionId, defaultAttachments } = props;

    const openAction =
      openActionId &&
      this.actions().find(
        action => action.id.replace("-new", "") === openActionId
      );
    if (openAction) {
      this.state = {
        message: {
          type: openAction.type,
          formData: openAction.data,
          attachments: defaultAttachments
        }
      };
    } else {
      this.state = { message: null };
    }

    this.showForm = this.showForm.bind(this);
  }

  showForm(event, type, data) {
    event.preventDefault();
    const { handleOpen } = this.props;

    this.setState({ message: { type, formData: data } }, () => {
      if (handleOpen) {
        handleOpen();
      }
    });
  }

  hideForm() {
    const { handleClose } = this.props;

    this.setState({ message: null }, () => {
      if (handleClose) {
        handleClose();
      }
    });
  }

  renderActionBar() {
    const actions = this.filteredActions();

    if (actions.length === 1) {
      return this.renderAction(actions[0]);
    }
    return this.renderActions(actions);
  }

  renderAction(action) {
    return (
      <Button
        id={action.id}
        onClick={event => this.showForm(event, action.type, action.data)}
        content={<div className="leftAlignedWithIcon">{action.label}</div>}
        compact={false}
        fluid={this.props.fluid || false}
        icon={action.icon}
        labelPosition="left"
      />
    );
  }

  renderActions(actions) {
    const { i18n, label, compact, id, fluid } = this.props;

    const items = actions.map(action => {
      return (
        <Dropdown.Item
          key={action.id}
          id={action.id}
          onClick={event => this.showForm(event, action.type, action.data)}
        >
          <Icon name={action.icon} /> {action.label}
        </Dropdown.Item>
      );
    });

    return (
      <Dropdown
        id={id}
        text={label || i18n["meta.actions.message.newMessage"]}
        compact={compact || false}
        fluid={fluid || false}
        icon="plus"
        floating
        labeled
        className="activity-actions icon margin bottom spacious"
        button
      >
        <Dropdown.Menu>{items}</Dropdown.Menu>
      </Dropdown>
    );
  }

  actions() {
    const { i18n, macros } = this.props;

    const macroActions = sortBy(
      (macros || []).map(macro => {
        return {
          id: `${macro.macro_id}-new`,
          type: "email",
          icon: macro.icon,
          label: macro.call_to_action,
          data: macro
        };
      }),
      "label"
    );

    const baseActions = [
      {
        id: "message-new",
        type: "email",
        icon: "mail",
        label: i18n["meta.actions.message.newEmail"],
        data: null
      },
      {
        id: "call-new",
        type: "call",
        icon: "phone",
        label: i18n["meta.actions.message.newCall"]
      },
      {
        id: "note-new",
        type: "note",
        icon: "chat",
        label: i18n["meta.actions.message.newNote"]
      }
    ];

    return macroActions.concat(baseActions);
  }

  filteredActions() {
    const { messageTypes } = this.props;
    const actions = this.actions();

    return actions.filter(action => {
      return (
        messageTypes.length === 0 ||
        messageTypes.find(item => item === action.id.replace("-new", ""))
      );
    });
  }

  renderForm() {
    const {
      account,
      i18n,
      activity,
      buyer,
      buyers,
      contractors,
      project,
      handleUpdate,
      memosResource,
      attachments,
      ctxCtrl,
      contacts
    } = this.props;
    const { message } = this.state;
    if (message.type === "email") {
      return (
        <Segment data-component="messageForm">
          <MessageForm
            account={account}
            i18n={i18n}
            activity={activity}
            buyer={buyer}
            buyers={buyers}
            contractors={contractors}
            project={project}
            handleClose={this.hideForm.bind(this)}
            handleUpdate={handleUpdate}
            contacts={contactsMap(contacts)}
            attachments={attachments}
            defaultAttachments={message.attachments}
            macro={message.formData || {}}
            ctxCtrl={ctxCtrl}
          />
        </Segment>
      );
    }
    return (
      <MemoForm
        i18n={i18n}
        model={{
          body: "",
          body_html: "",
          role: message.type,
          contacts: []
        }}
        type={message.type}
        contacts={contactsMap(this.props.contacts)}
        memosResource={memosResource}
        handleClose={this.hideForm.bind(this)}
        handleUpdate={handleUpdate}
      />
    );
  }

  render() {
    const { message } = this.state;

    if (message) {
      return this.renderForm();
    }
    return this.renderActionBar();
  }
}

ActivityActions.defaultProps = {
  id: "",
  messageTypes: [],
  handleOpen: () => {},
  handleClose: () => {},
  buyer: null,
  openActionId: null,
  defaultAttachments: [],
  buyers: [],
  contractors: [],
  contacts: []
};

ActivityActions.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
  i18n: PropTypes.object.isRequired,
  messageTypes: PropTypes.array,
  activity: ActivityShape.isRequired,
  buyer: BuyerShape,
  buyers: arrayOf(BuyerShape),
  contractors: arrayOf(ContractorShape),
  project: ProjectShape.isRequired,
  contacts: arrayOf(ContactsShape),
  attachments: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  memosResource: PropTypes.instanceOf(MemosResource).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  macros: PropTypes.arrayOf(MacroShape).isRequired,
  id: PropTypes.string,
  ctxCtrl: PropTypes.instanceOf(ActivityController).isRequired,
  openActionId: PropTypes.string,
  defaultAttachments: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default ActivityActions;
