/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import FormattedMessageWithFlag from "shared/components/textFormatting/FormattedMessageWithFlag";
import { cloneDeep, set, isNull } from "lodash";
import {
  Modal,
  Grid,
  Form,
  Button,
  Table,
  List,
  Image,
  Dropdown,
  Checkbox
} from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import CurrencyInput from "shared/components/forms/CurrencyInput";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import {
  AccountShape,
  CostAttributeShape,
  I18nShape,
  ProductShape,
  ProductGroupResourcesShape,
  ProductPriceShape,
  TradeLabelShape,
  DesignLineGroupShape
} from "shared/shapes";
import ProductGroupResource from "builder_portal/actions/productGroupActions";
import HasEditRightsForProjectCatalog from "shared/components/authorization/HasEditRightsForProjectCatalog";
import { getUser } from "shared/selectors";
import TradeLabel from "../helpers/TradeLabel";
import { getTagOptions } from "../../helpers/getTagOptions";
import ProductInfoItem from "../product/ProductInfoItem";

import "./productPriceDialog.scss";
import { IsSystemAdmin } from "../../../shared/components/authorization/IsSystemAdmin";
import { If } from "../../../shared/components/elements/Conditions";
import FeatureToggleActive from "../../../shared/components/elements/FeatureToggleActive";

class ProductPriceDialog extends Component {
  constructor(props) {
    super(props);
    const price = cloneDeep(props.model);
    const productPrice = (price?.product_prices || []).find(
      p => p.price_catalog_id === price.price_catalog_id
    );
    this.state = {
      open: false,
      price,
      productPrice
    };
  }

  onSave() {
    const { price } = this.state;
    const { onSubmit } = this.props;

    this.setState({ isLoading: true });

    onSubmit(price).then(() => {
      this.setState({ open: false, isLoading: false });
    });
  }

  onRemove() {
    const { price } = this.state;
    const { onDelete } = this.props;

    this.setState({ isDeleting: true });

    onDelete(price)
      .then(() => {
        this.setState({ open: false, isDeleting: false });
      })
      .catch(() => {
        this.setState({ open: false, isDeleting: false });
      });
  }

  getDesignLines() {
    const { designLineGroups } = this.props;
    const options = [];

    designLineGroups.forEach(dlg => {
      options.push(<Dropdown.Header content={dlg.name} key={`h-${dlg.id}`} />);
      dlg.design_lines.forEach(dl =>
        options.push({ key: dl.id, value: dl.id, text: dl.name })
      );
    });

    return options;
  }

  toggleExcessCosts(cost, idx) {
    const { price } = this.state;

    if (!isNull(cost.excess_cost)) {
      cost.excess_cost = null;
    } else {
      cost.excess_cost = cost.cost;
    }
    set(price, ["costs_attributes", idx], cost);

    this.setState({ price });
  }

  toggleExcessPrice() {
    const { price } = this.state;
    const newPrice = cloneDeep(price);
    if (isNull(price.excess_price)) {
      newPrice.excess_price = newPrice.price;
      this.setState({ price: newPrice });
    } else {
      newPrice.excess_price = null;
      this.setState({ price: newPrice });
    }
  }

  handleGlencoeChange = ({ checked }) => {
    const { price } = this.state;
    if (checked && !price.glencoe_product_id) {
      this.setState(prev => ({
        price: {
          ...prev.price,
          glencoe_product: checked,
          glencoe_product_id: price.default_glencoe_product_id
        }
      }));
    } else {
      this.setState(prev => ({
        price: { ...prev.price, glencoe_product: checked }
      }));
    }
  };

  toggleActive = () => {
    const { updatePrices, userId } = this.props;
    const { productPrice } = this.state;
    this.setState({ isLoading: true });

    const deprecated_at = productPrice.deprecated_at ? null : new Date();
    const deprecated_by_id = productPrice.deprecated_at ? null : userId;
    updatePrices({
      id: productPrice.id,
      deprecated_at,
      deprecated_by_id
    }).then(() => {
      this.setState({
        productPrice: { ...productPrice, deprecated_at, deprecated_by_id },
        isLoading: false
      });
      setTimeout(() => this.setState({ open: false }), 300);
    });
  };

  render() {
    const { account, i18n, model, catalogId, product } = this.props;
    const { price, productPrice } = this.state;
    const costs_attributes = price.costs_attributes || [];

    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "price",
          rule: "isAmount",
          label: ""
        },
        {
          id: "excess_price",
          rule: "isAmountOrEmpty",
          label: ""
        },
        {
          id: "portal_offer",
          label: "product_price.attributes.portal_offer.label",
          control: "Checkbox"
        },
        {
          id: "glencoe_product",
          label: "product_price.attributes.glencoe_product.label",
          control: "Checkbox"
        },
        {
          id: "glencoe_product_id",
          label: "product_price.attributes.glencoe_product_id.label",
          message: "product_price.attributes.glencoe_product_id.error",
          rule: value => {
            if (price.glencoe_product && !value) return true;
            if (!value || value === model.glencoe_product_id) return false;
            const allowed = value.match(/[a-z0-9-]+/i);
            if (value !== "" && value !== allowed?.[0]) {
              return true;
            }
            return false;
          }
        },
        {
          id: "on_request",
          label: "product_price.attributes.on_request.hint",
          control: "Checkbox"
        },
        {
          id: "product_tags",
          label: "product.attributes.product_tags.label",
          placeholder: "product.attributes.product_tags.placeholder",
          default: []
        },
        {
          id: "design_line_ids",
          label: "product.attributes.design_line_ids.label",
          placeholder: "product.attributes.design_line_ids.placeholder",
          default: []
        },
        {
          id: "external_id",
          label: "product_option.attributes.external_id.label",
          placeholder: "product_option.attributes.external_id.placeholder",
          autoComplete: "off"
        },
        {
          id: "internal_hint_overwrite",
          label: "product.attributes.internal_hint.label",
          placeholder: product.internal_hint,
          autoComplete: "off"
        }
      ].concat(
        costs_attributes.reduce((accu, cost, idx) => {
          return accu.concat([
            {
              id: `costs_attributes_${idx}_cost`,
              accessor: `costs_attributes[${idx}].cost`,
              label: "",
              rule: "isAmount"
            },
            {
              id: `costs_attributes_${idx}_excess_cost`,
              accessor: `costs_attributes[${idx}].excess_cost`,
              label: "",
              rule: "isAmountOrEmpty"
            },
            {
              id: `costs_attributes_${idx}_trade`,
              accessor: `costs_attributes[${idx}].trade`,
              label: ""
            }
          ]);
        }, [])
      )
    });

    const form = FormFactory.create(price, i18n, {
      onChange: p => {
        this.setState({ price: p });
      }
    });

    const title = `product_price.actions.${price.id ? "edit" : "add"}.label`;

    const costs = (
      <Table size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: "24%" }}>
              <FormattedMessage
                id="product_price.attributes.cost.label"
                defaultMessage="product_price.attributes.cost.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "34%" }}>
              <FormattedMessage
                id="product_price.attributes.default_quantity.label"
                defaultMessage="product_price.attributes.default_quantity.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "34%" }}>
              <FormattedMessage
                id="product_price.attributes.excess_quantity.label"
                defaultMessage="product_price.attributes.excess_quantity.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "8%" }} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {costs_attributes &&
            costs_attributes.map((cost, idx) => {
              return (
                <Table.Row key={cost.trade} id={`${cost.trade}-cost`}>
                  <Table.Cell>
                    <TradeLabel trades={this.props.trades} id={cost.trade} />
                  </Table.Cell>
                  <Table.Cell data-attr="cost">
                    <Field
                      component={CurrencyInput}
                      {...form.fields[`costs_attributes_${idx}_cost`]}
                    />
                  </Table.Cell>
                  <Table.Cell data-attr="excess_cost">
                    {isNull(cost.excess_cost) && (
                      <CurrencyInput value={cost.cost} readOnly />
                    )}
                    {!isNull(cost.excess_cost) && (
                      <Field
                        component={CurrencyInput}
                        {...form.fields[`costs_attributes_${idx}_excess_cost`]}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <HasEditRightsForProjectCatalog catalogId={catalogId}>
                      <Button
                        as="a"
                        icon={isNull(cost.excess_cost) ? "pencil" : "remove"}
                        onClick={() => {
                          this.toggleExcessCosts(cost, idx);
                        }}
                      />
                    </HasEditRightsForProjectCatalog>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage
                id="product_price.attributes.total_cost.label"
                defaultMessage="product_price.attributes.total_cost.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell id="total-cost">
              <CurrencyInput value={this.calcTotalCost(price)} readOnly />
            </Table.HeaderCell>
            <Table.HeaderCell id="total-excess-cost">
              <CurrencyInput value={this.calcTotalExcessCost(price)} readOnly />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Footer>
      </Table>
    );

    form.fields.product_tags.props = {
      ...form.fields.product_tags.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      options: this.getProductTags(),
      upward: true
    };

    form.fields.design_line_ids.props = {
      ...form.fields.design_line_ids.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      options: this.getDesignLines(),
      upward: true
    };

    form.fields.glencoe_product.props = {
      ...form.fields.glencoe_product.props,
      onChange: (_, data) => this.handleGlencoeChange(data)
    };

    return (
      <Modal
        size="small"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        closeIcon
        trigger={this.props.button}
        open={this.state.open}
        onOpen={() =>
          this.setState({ open: true, price: cloneDeep(this.props.model) })
        }
        onClose={() => this.setState({ open: false })}
      >
        <Modal.Header>
          <FormattedMessage id={title} defaultMessage={title} />
        </Modal.Header>
        <Modal.Content scrolling>
          <Form id="product_price" data-component="productPriceForm">
            {this.renderProduct()}
            <Table size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ width: "24%" }}>
                    <FormattedMessageWithFlag
                      featureToggleName="show_price_net_label"
                      id="product_price.attributes.price.net"
                      alternativeId="product_price.attributes.price.gross"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "34%" }}>
                    <FormattedMessage
                      id="product_price.attributes.default_quantity.label"
                      defaultMessage="product_price.attributes.default_quantity.label"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "34%" }}>
                    <FormattedMessage
                      id="product_price.attributes.excess_quantity.label"
                      defaultMessage="product_price.attributes.excess_quantity.label"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "8%" }} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Erwerber</Table.Cell>
                  <Table.Cell>
                    <Field component={CurrencyInput} {...form.fields.price} />
                  </Table.Cell>
                  <Table.Cell>
                    {isNull(price.excess_price) && (
                      <CurrencyInput value={price.price} readOnly />
                    )}
                    {!isNull(price.excess_price) && (
                      <Field
                        component={CurrencyInput}
                        {...form.fields.excess_price}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <HasEditRightsForProjectCatalog catalogId={catalogId}>
                      <Button
                        as="a"
                        icon={isNull(price.excess_price) ? "pencil" : "remove"}
                        onClick={() => {
                          this.toggleExcessPrice();
                        }}
                      />
                    </HasEditRightsForProjectCatalog>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {costs}
            <Form.Field>
              <Field component="Checkbox" {...form.fields.on_request} />
            </Form.Field>
            {account.isEnabled("buyer_portal") && (
              <>
                <Form.Field>
                  <Field component="Checkbox" {...form.fields.portal_offer} />
                </Form.Field>
                <Form.Field>
                  <Field
                    component="Checkbox"
                    {...form.fields.glencoe_product}
                  />
                </Form.Field>
                <If condition={!!price.glencoe_product}>
                  <IsSystemAdmin>
                    <Form.Field>
                      <Field
                        component="Input"
                        {...form.fields.glencoe_product_id}
                      />
                    </Form.Field>
                  </IsSystemAdmin>
                </If>
              </>
            )}
            {!!form.fields.product_tags.props.options.length && (
              <Form.Field>
                <Field component="Select" {...form.fields.product_tags} />
              </Form.Field>
            )}
            <FeatureToggleActive featureToggleName="design_lines_admin">
              {!!form.fields.design_line_ids.props.options.length && (
                <Form.Field>
                  <Field component="Select" {...form.fields.design_line_ids} />
                </Form.Field>
              )}
            </FeatureToggleActive>
            <Form.Field>
              <Field
                component="TextArea"
                {...form.fields.internal_hint_overwrite}
              />
            </Form.Field>
            <FeatureToggleActive featureToggleName="show_external_id">
              <Form.Field>
                <Field component="Input" {...form.fields.external_id} />
              </Form.Field>
            </FeatureToggleActive>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={10} verticalAlign="middle" textAlign="left">
              <HasEditRightsForProjectCatalog catalogId={catalogId}>
                {this.renderDeleteButton(price, form)}
                <If condition={productPrice}>
                  <Checkbox
                    toggle
                    checked={!productPrice?.deprecated_at}
                    onClick={this.toggleActive}
                    style={{ marginTop: "7px", marginLeft: "15px" }}
                    label={
                      i18n[
                        `meta.states.${
                          productPrice?.deprecated_at ? "inactive" : "active"
                        }`
                      ]
                    }
                  />
                </If>
              </HasEditRightsForProjectCatalog>
            </Grid.Column>
            <Grid.Column textAlign="right" width={6}>
              <Button
                content={i18n["meta.actions.close"]}
                basic
                onClick={() => this.setState({ open: false })}
              />
              <HasEditRightsForProjectCatalog catalogId={catalogId}>
                <Button
                  id="submit"
                  data-form="product_price"
                  positive
                  content={i18n["meta.actions.save"]}
                  onClick={form.handleSubmit(this.onSave.bind(this))}
                  loading={this.state.isLoading}
                />
              </HasEditRightsForProjectCatalog>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }

  getProductTags() {
    const { account } = this.props;
    const { taxonomies } = account.data.account.config;

    return getTagOptions(taxonomies);
  }

  renderProduct() {
    const { product } = this.props;
    if (product) {
      return (
        <List divided>
          <List.Item key={product.id} data-model="product">
            <Image
              shape="rounded"
              style={{ maxHeight: "64px", maxWidth: "64px" }}
              src={product.thumb_url}
            />
            <List.Content>
              <ProductInfoItem product={product} />
              {(product.description || "").substring(0, 64)}
            </List.Content>
          </List.Item>
        </List>
      );
    }
  }

  renderDeleteButton(price) {
    const deletable = price.id && price.usage_count < 1;

    const button = (
      <Button
        id="remove"
        color="red"
        basic
        content={<FormattedMessage id="meta.actions.remove" />}
        loading={this.state.isDeleting}
        className="left floated element"
      />
    );

    if (deletable) {
      const buttons = [
        {
          id: "delete",
          label: "meta.actions.remove",
          color: "red",
          onClick: this.onRemove.bind(this)
        },
        {
          id: "cancel",
          label: "meta.actions.cancel",
          basic: true
        }
      ];

      return (
        <ConfirmationDialog
          title="product_price.actions.removeDialog.title"
          message="product_price.actions.removeDialog.message"
          buttons={buttons}
          button={button}
        />
      );
    }
    if (price.usage_count > 0) {
      const buttons = [
        {
          id: "ok",
          label: "meta.actions.accept",
          basic: true
        }
      ];
      return (
        <ConfirmationDialog
          title="product_price.actions.undeletableDialog.title"
          message="product_price.actions.undeletableDialog.message"
          buttons={buttons}
          button={button}
        />
      );
    }
  }

  calcTotalCost(price) {
    return price.costs_attributes
      ? price.costs_attributes.reduce((sum, cost) => sum + cost.cost, 0.0)
      : 0.0;
  }

  calcTotalExcessCost(price) {
    return price.costs_attributes
      ? price.costs_attributes.reduce(
          (sum, cost) => sum + (cost.excess_cost || cost.cost),
          0.0
        )
      : 0.0;
  }
}

const LegacyProductPriceShape = PropTypes.shape({
  id: PropTypes.number,
  product_id: PropTypes.number,
  product_group_id: PropTypes.number,
  position: PropTypes.number,
  portal_offer: PropTypes.bool,
  on_request: PropTypes.bool,
  external_id: PropTypes.string,
  product_tags: PropTypes.arrayOf(PropTypes.string),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  is_default: PropTypes.bool,
  product_prices: PropTypes.arrayOf(ProductPriceShape).isRequired,
  product_option_id: PropTypes.number.isRequired,
  price_catalog_id: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  excess_price: PropTypes.number,
  costs_attributes: PropTypes.arrayOf(CostAttributeShape).isRequired,
  glencoe_product_id: PropTypes.string
});

ProductPriceDialog.propTypes = {
  i18n: I18nShape.isRequired,
  account: AccountShape.isRequired,
  resources: ProductGroupResourcesShape.isRequired,
  button: PropTypes.node.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trades: PropTypes.arrayOf(TradeLabelShape).isRequired,
  product: ProductShape.isRequired,
  model: LegacyProductPriceShape.isRequired,
  designLineGroups: PropTypes.arrayOf(DesignLineGroupShape),
  catalogId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  updatePrices: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  productGroupId: PropTypes.number.isRequired
};

ProductPriceDialog.defaultProps = {
  designLineGroups: []
};

const mapStateToProps = () => {
  return state => {
    const userId = getUser(state)?.id;
    return {
      userId
    };
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { productGroupId, catalogId } = props;
  return {
    updatePrices: values =>
      new ProductGroupResource(catalogId, dispatch).updatePrices(
        productGroupId,
        {
          product_group: {
            prices: [values]
          }
        }
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceDialog);
