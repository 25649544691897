import React from "react";
import { Message } from "semantic-ui-react";
import PropTypes from "prop-types";

const SubLineItemInternalHint = ({ lineItem }) => {
  if (lineItem.product_selected?.group?.internal_hint) {
    return (
      <Message color="blue">
        <Message.Content>
          <div className="preWrap">
            {lineItem.product_selected?.group?.internal_hint}
          </div>
        </Message.Content>
      </Message>
    );
  }

  return null;
};

SubLineItemInternalHint.propTypes = {
  lineItem: PropTypes.shape({
    product_selected: PropTypes.shape({
      group: PropTypes.shape({
        internal_hint: PropTypes.string
      })
    })
  }).isRequired
};

export default SubLineItemInternalHint;
