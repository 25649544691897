/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React from "react";
import { arrayOf, func, instanceOf, number, shape, string } from "prop-types";
import {
  Button,
  Card,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Loader,
  Menu,
  Popup,
  Segment
} from "semantic-ui-react";
import { get, isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { Link, locationShape } from "react-router";
import { browserHistory } from "shared/routes/browserHistory";
import { scrollToTop } from "shared/helpers/scrollToTop";
import { connect } from "react-redux";
import { getSuppliers } from "shared/selectors/supplier";
import { getMeta } from "shared/selectors/meta";
import { getProducts, getProduct } from "shared/selectors/product";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { getAccount, getFormattedTrades } from "shared/selectors";
import { ProductShape } from "shared/shapes/product.shape";
import { SupplierShape } from "shared/shapes/supplier.shape";
import { I18nShape } from "shared/shapes/i18n.shape";
import { Account } from "shared/models/account";
import { TradeLabelShape } from "shared/shapes/tradeLabel.shape";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import {
  ProductImageResource,
  ProductResource,
  ProductSpecificationResource,
  SupplierResource
} from "../../actions/productActions";
import { getTagNames } from "../../helpers/getTagNames";
import ImageDropzone from "../dropzone/ImageDropzone";
import Pager from "../pager/Pager";
import ProductDialog from "./ProductDialog";
import ProductImportDialog from "./ProductImportDialog";
import ProductSynchronisation from "./ProductSynchronisation";
import ProductReferences from "./ProductReferences";
import TradesList from "./TradesList";
import ProductSpecifications from "./ProductSpecifications";
import ProductInfoItem from "../product/ProductInfoItem";
import ProductAttachments from "../product/ProductAttachments";
import "./productList.scss";
import { getI18N } from "../../../shared/selectors";
import FeatureToggleActive from "../../../shared/components/elements/FeatureToggleActive";
import HasEditProductsRight from "../../../shared/components/authorization/HasEditProductsRight";
import { IsSystemAdmin } from "../../../shared/components/authorization/IsSystemAdmin";
import HasAccessProjectCatalogRight from "../../../shared/components/authorization/HasAccessProjectCatalogRight";
import ProductTypeResource from "../../actions/productTypeActions";
import ProductFilters from "./productFilters/ProductFilters";
import ProductFileAttachment from "../product/ProductFileAttachment";
import ProductCatalogDialog from "./ProductCatalogDialog";

class ProductList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: true };
  }

  componentDidMount() {
    const { resources, account, productId } = this.props;
    const accountId = account.data?.account?.id;
    const lsParams = JSON.parse(localStorage.getItem("productFilters"));

    const productTypesPromise = resources.productTypes.fetchAll();
    let productsPromise;
    if (lsParams && lsParams[accountId]) {
      if (
        !isEqual(this.productQueryParams(this.props), { page: "1", limit: 24 })
      ) {
        productsPromise = productId
          ? resources.products.get(productId)
          : resources.products.fetchAll(this.productQueryParams(this.props));
      }
    } else {
      productsPromise = productId
        ? resources.products.get(productId)
        : resources.products.fetchAll(this.productQueryParams(this.props));
    }

    Promise.all([productTypesPromise, productsPromise]).then(() => {
      this.toggleLoading(false);
    });
  }

  componentDidUpdate(prevProps) {
    const { productId } = this.props;

    const prevQuery = this.productQueryParams(prevProps);
    const nextQuery = this.productQueryParams(this.props);

    if (!nextQuery.product_catalog_id && !nextQuery.product_id) return;

    if (!isEqual(prevQuery, nextQuery)) {
      this.toggleLoading(true);
      const { resources } = this.props;
      if (productId) {
        resources.products.get(productId).then(() => {
          scrollToTop();
          this.toggleLoading(false);
        });
      } else {
        resources.products
          .fetchAll(this.productQueryParams(this.props))
          .then(() => {
            scrollToTop();
            this.toggleLoading(false);
          });
      }
    }
  }

  onCreate = product => {
    const { resources } = this.props;
    return Promise.all([
      resources.suppliers.fetchAll()
      // resources.products.fetchAll(this.productQueryParams(this.props)) //do we need this? cause...
    ]).then(() => {
      const supplierId = get(product, "supplier_id") || "none";
      browserHistory.push(`/products/${supplierId}/${product.id}`); // ...this will update query params and componentDidUpdate will be fired
    });
  };

  onImport() {
    const { resources } = this.props;

    return Promise.all([
      resources.suppliers.fetchAll(),
      resources.products.fetchAll(this.productQueryParams(this.props))
    ]);
  }

  getProduct(productId) {
    const { products } = this.props;
    return products && products.find(p => String(p.id) === productId);
  }

  refresh = product => {
    const { resources } = this.props;
    return resources.products.get(product.id);
  };

  onUpdate = product => {
    const { supplierId } = this.props;
    if (product && product?.supplier_id !== supplierId) {
      const productSupplierId = get(product, "supplier_id") || "none";
      browserHistory.push(`/products/${productSupplierId}/${product.id}`);
    }
    return this.refresh(product);
  };

  handlePagerClick = page => {
    const { supplierId, location } = this.props;
    const keys = Object.keys(location.query);

    let params = keys
      .map(key => {
        if (key === "page") return `page=${page}&`;
        return `${key}=${location.query[key]}&`;
      })
      .join("")
      .slice(0, -1);

    if (keys.indexOf("page") === -1) {
      params = `${params}&page=${page}`;
    }

    if (supplierId) {
      browserHistory.push(`/products/${supplierId}?${params}`);
    } else {
      browserHistory.push(`/products?${params}`);
    }
  };

  productQueryParams(props) {
    const { supplierId, productId, location } = props;

    const query = {
      limit: 24
    };
    if (supplierId && supplierId !== "suppliers") {
      query.supplier_id = supplierId;
    }

    if (productId) {
      query.product_id = productId;
    }

    const params = new URLSearchParams(location.search);

    if (params.has("trades")) {
      query.trades = [params.get("trades")];
    }

    if (params.has("product_type_ids")) {
      query.product_type_ids = [params.get("product_type_ids")];
    }

    if (params.has("product_catalog_id")) {
      query.product_catalog_id = params.get("product_catalog_id");
    }

    return { ...location?.query, ...query };
  }

  moveImage(product, image, direction) {
    return () => {
      const { resources } = this.props;
      const resource = resources.createImagesResource(product.id);
      resource
        .save({ id: image.id, position: image.position + direction })
        .then(() => {
          this.onUpdate(product);
        });
    };
  }

  toggleLoading(bool) {
    this.setState({ isLoading: bool });
  }

  handleFilter = filters => {
    const { location } = this.props;

    const currentParams = new URLSearchParams(location.search);

    Object.entries(filters).forEach(([key, value]) => {
      if (
        value == null ||
        value === "" ||
        (Array.isArray(value) && value.length === 0)
      ) {
        currentParams.delete(key);
      } else {
        currentParams.set(key, Array.isArray(value) ? value.join(",") : value);
      }
    });

    // if no supplier_id is set, delete "series" filter
    if (!currentParams.get("supplier_id")) {
      // currentParams.set("supplier_id", "none");
      currentParams.delete("series");
    }

    let newPathname = location.pathname;
    currentParams.forEach((value, key) => {
      if (
        [
          "term",
          "product_catalog_id",
          "supplier_id",
          "series",
          "product_type_ids",
          "trades"
        ].includes(key)
      ) {
        newPathname += `${
          newPathname.includes("?") ? "&" : "?"
        }${key}=${value}`;
      }
    });

    browserHistory.push(newPathname);
  };

  removeProductImageFn(product, image) {
    const { resources } = this.props;
    const imageResource = resources.createImagesResource(product.id);
    return () => {
      imageResource.remove(image.id).then(() => {
        this.onUpdate(product);
      });
    };
  }

  renderFilters = () => {
    const { account, location } = this.props;

    return (
      <>
        <div data-component="suppliers">
          <Header as="h5" attached="top">
            <FormattedMessage
              id="product.filters.title"
              defaultText="Produktsuche"
            />
            <FeatureToggle featureToggleName="multiple_product_catalogs">
              <ProductCatalogDialog />
            </FeatureToggle>
          </Header>
          <Segment attached style={{ minHeight: "150px" }}>
            <ProductFilters
              handleFilter={this.handleFilter}
              paramsValues={location ? { ...location.query } : {}}
              locationSearch={location?.search || ""}
            />
          </Segment>
        </div>
        <div data-component="product-list-exports">
          <Segment attached data-component="price_catalogs">
            <Grid>
              <Grid.Column width={16}>
                <Menu vertical fluid>
                  <Menu.Item
                    link
                    href={`/api/v1/products/report.xlsx?_bearer=${get(account, [
                      "data",
                      "auth_token"
                    ])}`}
                  >
                    <Icon name="file excel outline" />
                    <FormattedMessage
                      id="product_group.actions.download_product_catalog"
                      defaultMessage="Download Produktkatalog"
                    />
                  </Menu.Item>
                </Menu>
              </Grid.Column>
            </Grid>
          </Segment>
        </div>
      </>
    );
  };

  renderProductMeta = () => {
    const { account, i18n, trades, product } = this.props;
    const { isLoadingProduct } = this.state;

    if (!product) return null;

    return (
      <>
        <Header attached="top">
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="8">
              <Icon
                style={{ cursor: "pointer" }}
                name="arrow left"
                onClick={() => window.history.go(-1)}
              />
            </Grid.Column>
            <HasEditProductsRight>
              <Grid.Column width="8" textAlign="right">
                <IsVersionHistoryAccessible>
                  <VersionHistoryLink
                    id={product.id}
                    type="Product"
                    className="right floated element"
                  />
                </IsVersionHistoryAccessible>
                {this.renderProductButton(product)}
                {this.renderDuplicateProductButton(product)}
              </Grid.Column>
            </HasEditProductsRight>
          </Grid>
        </Header>
        <Segment attached loading={isLoadingProduct}>
          <Header sub>
            <FormattedMessage
              id="product.attributes.name.label"
              defaultMessage="product.attributes.name.label"
            />
          </Header>
          <span>
            <span id="productName">{product.name}</span>
            {account.isEnabled("ok20") && product.display_name && (
              <Popup
                content={
                  <FormattedMessage id="product.attributes.display_name.hint" />
                }
                trigger={
                  <span className="display-name">({product.display_name})</span>
                }
              />
            )}
          </span>

          <Header sub>
            <FormattedMessage
              id="product.attributes.sku.label"
              defaultMessage="product.attributes.sku.label"
            />
          </Header>
          <span>{product.sku || i18n["product.attributes.sku.none"]}</span>

          <Header sub>
            <FormattedMessage
              id="product.attributes.supplier.label"
              defaultMessage="product.attributes.supplier.label"
            />
          </Header>
          <span>
            {product.supplier || i18n["product.attributes.supplier.none"]}
          </span>

          <Header sub>
            <FormattedMessage
              id="product.attributes.series.label"
              defaultMessage="product.attributes.series.label"
            />
          </Header>
          <span>
            {product.series || i18n["product.attributes.series.none"]}
          </span>

          <Header sub>
            <FormattedMessage
              id="product.attributes.description.label"
              defaultMessage="product.attributes.description.label"
            />
          </Header>
          <div className="preWrap">
            {product.description || i18n["product.attributes.description.none"]}
          </div>

          {product.internal_hint && (
            <>
              <Header sub>
                <FormattedMessage
                  id="product.attributes.internal_hint.label"
                  defaultMessage="product.attributes.internal_hint.label"
                />
              </Header>
              <div className="preWrap">{product.internal_hint}</div>
            </>
          )}

          <TradesList allTrades={trades} specificTrades={product.trades} />

          {!product.product_tags.length ? null : (
            <Grid>
              <Grid.Column width="16">
                <Header sub>
                  <FormattedMessage
                    id="product.attributes.product_tags.label"
                    defaultMessage="product.attributes.product_tags.label"
                  />
                </Header>
                {this.renderTagNames(product) || ""}
              </Grid.Column>
            </Grid>
          )}
        </Segment>
        <Segment attached loading={isLoadingProduct}>
          {this.renderDeleteProductButton(product)}
        </Segment>
      </>
    );
  };

  renderLeftside() {
    const { productId } = this.props;

    if (productId) {
      return this.renderProductMeta();
    }
    return this.renderFilters();
  }

  renderBody() {
    const { productId } = this.props;

    if (productId) {
      return this.renderProductBody();
    }
    return this.renderProducts();
  }

  renderProductBody() {
    const { product } = this.props;
    const { isLoadingProduct } = this.state;

    if (!product) return null;

    if (product) {
      return (
        <Segment.Group>
          <Segment attached loading={isLoadingProduct}>
            <Grid stackable verticalAlign="middle">
              <Grid.Column width="12">
                <Header as="h4">
                  <FormattedMessage
                    id="product.attributes.images.label"
                    defaultMessage="product.attributes.images.label"
                  />
                </Header>
              </Grid.Column>
              <Grid.Column width="4" textAlign="right" />
            </Grid>
            <div data-component="images">
              {this.renderProductImages(product)}
            </div>
          </Segment>
          <FeatureToggleActive featureToggleName="product_videos">
            <>
              <ProductAttachments
                product={product}
                onChange={() => this.refresh(product)}
              />
              <ProductFileAttachment
                product={product}
                onChange={() => this.refresh(product)}
              />
            </>
          </FeatureToggleActive>
          <ProductSpecifications
            onUpdateFn={updatedProduct => this.onUpdate(updatedProduct)}
            product={product}
          />
          <HasAccessProjectCatalogRight>
            <ProductReferences productId={product.id} />
          </HasAccessProjectCatalogRight>
          <IsSystemAdmin>
            <ProductSynchronisation product={product} />
          </IsSystemAdmin>
        </Segment.Group>
      );
    }
    return <Loader active />;
  }

  renderTagNames(product) {
    const { account } = this.props;
    const tags = getTagNames(
      product.product_tags,
      account.data.account.config.taxonomies
    );

    return tags.map(tag => {
      return <Label key={tag}>{tag}</Label>;
    });
  }

  renderProductImages(product) {
    const { i18n } = this.props;

    const actualImages = product.images?.filter(image => image.id) || [];
    const images = actualImages.map(image => {
      const movable = product.images.length > 1;
      const movableLeft = movable && image.position > 1;
      const movableRight = movable && image.position < product.images.length;

      const moveLeft = (
        <a
          role="button"
          data-tooltip={i18n["meta.actions.move_left"]}
          className="left floated element"
          onClick={this.moveImage(product, image, -1).bind(this)}
        >
          <Icon name="arrow left" size="large" color="grey" />
        </a>
      );

      const moveRight = (
        <a
          role="button"
          data-tooltip={i18n["meta.actions.move_right"]}
          className="right floated element"
          onClick={this.moveImage(product, image, 1).bind(this)}
        >
          <Icon name="arrow right" size="large" color="grey" />
        </a>
      );

      return (
        <Card key={`image-${image.id}`} data-model="image">
          <Card.Content>
            <div className="image-wrapper">
              <Image
                src={image.url}
                shape="rounded"
                size="small"
                verticalAlign="middle"
                className="product-image"
              />
            </div>
          </Card.Content>
          <HasEditProductsRight>
            <Card.Content>
              <Grid>
                <Grid.Column width={5}>
                  {movableLeft ? (
                    moveLeft
                  ) : (
                    <Icon
                      name="arrow left"
                      size="large"
                      color="grey"
                      disabled
                    />
                  )}
                </Grid.Column>
                <Grid.Column width={6} textAlign="center">
                  {this.renderDeleteProductImageButton(product, image)}
                </Grid.Column>
                <Grid.Column width={5}>
                  {movableRight ? (
                    moveRight
                  ) : (
                    <Icon
                      name="arrow right"
                      size="large"
                      color="grey"
                      disabled
                      className="right floated element"
                    />
                  )}
                </Grid.Column>
              </Grid>
            </Card.Content>
          </HasEditProductsRight>
        </Card>
      );
    });

    return (
      <Card.Group itemsPerRow="3" stackable>
        {images}
        <HasEditProductsRight>
          <Card key="new">
            <Card.Content>
              <ImageDropzone
                key={images.length}
                title={i18n["product.actions.uploadImage.label"]}
                className="projectDropZone"
                resourceName="products"
                resourceId={product.id}
                handleSuccess={() => this.onUpdate(product)}
                i18n={i18n}
                options={{ path: "images" }}
              />
            </Card.Content>
          </Card>
        </HasEditProductsRight>
      </Card.Group>
    );
  }

  renderProducts() {
    const { products } = this.props;
    const { isLoading } = this.state;

    return (
      <Segment.Group data-component="products">
        <Header as="h5" attached="top">
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="12">
              <FormattedMessage
                id="product.title.other"
                defaultText="product.title.other"
              />
            </Grid.Column>
            <Grid.Column width="4" textAlign="right">
              <HasEditProductsRight>
                {this.renderCreateProductButton()}
                {this.renderImportProductButton()}
              </HasEditProductsRight>
            </Grid.Column>
          </Grid>
        </Header>

        <Segment attached loading={isLoading} style={{ minHeight: "150px" }}>
          {products && products.length === 0 ? (
            <Segment placeholder>
              <Header icon>
                <Icon name="folder open outline" />
                <FormattedMessage id="product.emptyProducts" />
              </Header>
            </Segment>
          ) : (
            this.renderProductCards(products)
          )}
        </Segment>

        {this.renderPager()}
      </Segment.Group>
    );
  }

  renderProductCards(products) {
    if (products) {
      const items = products.map(product => {
        return this.renderProduct(product);
      });

      return (
        <Card.Group itemsPerRow="3" stackable>
          {items}
        </Card.Group>
      );
    }
    return "Keine Produkte vorhanden. Legen Sie neue Produkte an.";
  }

  renderPager() {
    const { meta } = this.props;

    if (meta && meta.total > meta.limit) {
      return (
        <Segment attached textAlign="center">
          <Pager
            currentPage={meta.page}
            itemsPerPage={meta.limit}
            totalItems={meta.total}
            onClick={this.handlePagerClick}
          />
        </Segment>
      );
    }

    return null;
  }

  renderProduct(product) {
    const supplierId = get(product, "supplier_id") || "none";
    return (
      <Card
        key={`product-${product.id}`}
        data-model="product"
        data-id={product.id}
        data-component="productItem"
      >
        <Card.Content>
          <Link
            onlyActiveOnIndex={false}
            to={`/products/${supplierId}/${product.id}`}
          >
            <ProductInfoItem product={product} />
          </Link>
        </Card.Content>
        <Card.Content>
          <Link
            onlyActiveOnIndex={false}
            to={`/products/${supplierId}/${product.id}`}
            className="link"
          >
            <div
              style={{ backgroundImage: `url(${product.thumb_url})` }}
              className="image"
            />
          </Link>
        </Card.Content>
      </Card>
    );
  }

  renderProductButton(product) {
    const { i18n, resources, suppliers } = this.props;

    const button = (
      <a
        role="button"
        data-tooltip={i18n["meta.actions.edit"]}
        className="right floated element editProduct"
      >
        <Icon name="setting" size="large" color="grey" />
      </a>
    );

    return (
      <ProductDialog
        button={button}
        resources={resources}
        model={product}
        handleUpdate={this.onUpdate}
        suppliers={suppliers}
      />
    );
  }

  renderCreateProductButton() {
    const { i18n, resources, suppliers } = this.props;

    const button = (
      <a
        id="product-new"
        role="button"
        data-tooltip={i18n["meta.actions.add"]}
        className="right floated element"
      >
        <Icon name="plus" size="large" color="grey" />
      </a>
    );

    const product = {
      name: "",
      color_code: "#cccccc"
    };

    return (
      <ProductDialog
        button={button}
        resources={resources}
        model={product}
        handleUpdate={this.onCreate}
        suppliers={suppliers}
      />
    );
  }

  renderImportProductButton() {
    const { i18n, resources, account } = this.props;

    const button = (
      <a
        id="product-import"
        role="button"
        data-tooltip={i18n["product_group.search.title"]}
        className="right floated element"
      >
        <Icon name="world" size="large" color="grey" />
      </a>
    );

    if (account.isSystemAdmin()) {
      return (
        <ProductImportDialog
          button={button}
          resources={resources}
          i18n={i18n}
          handleUpdate={() => this.onImport()}
        />
      );
    }
    return null;
  }

  renderDuplicateProductButton(original) {
    const { i18n, resources, suppliers } = this.props;

    const button = (
      <a
        id="product-clone"
        role="button"
        data-tooltip={i18n["meta.actions.clone"]}
        className="right floated element"
      >
        <Icon name="clone" size="large" color="grey" />
      </a>
    );

    const actualImages = original.images.filter(i => i.id);

    const product = {
      ...original,
      image_url: actualImages.length ? original.thumb_url : null
    };

    return (
      <ProductDialog
        button={button}
        resources={resources}
        model={product}
        handleUpdate={this.onCreate}
        suppliers={suppliers}
        mode="clone"
      />
    );
  }

  renderDeleteProductButton(product) {
    const { i18n, resources } = this.props;
    const button = (
      <Button fluid color="red">
        <FormattedMessage id="meta.actions.remove" default="Delete" />
      </Button>
    );

    if (product.deletable) {
      const buttons = [
        {
          id: "delete",
          label: i18n["meta.actions.remove"],
          color: "red",
          onClick: () => {
            resources.products.remove(product.id).then(() => {
              browserHistory.push("/products");
            });
          }
        },
        {
          id: "cancel",
          label: i18n["meta.actions.cancel"],
          basic: true
        }
      ];

      return (
        <ConfirmationDialog
          title={i18n["product.actions.removeDialog.title"]}
          message={i18n["product.actions.removeDialog.message"]}
          buttons={buttons}
          button={button}
        />
      );
    }
    const buttons = [
      {
        id: "ok",
        label: i18n["meta.actions.accept"],
        basic: true
      }
    ];
    return (
      <ConfirmationDialog
        title={i18n["product.actions.undeletableDialog.title"]}
        message={i18n["product.actions.undeletableDialog.message"]}
        buttons={buttons}
        button={button}
      />
    );
  }

  renderDeleteProductImageButton(product, image) {
    const { i18n } = this.props;
    const button = (
      <a role="button" data-tooltip={i18n["meta.actions.remove"]}>
        <Icon name="remove circle" size="large" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: cb => {
          this.removeProductImageFn(product, image)();
          cb();
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="product.actions.removeImageDialog.title"
        message="product.actions.removeImageDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }

  render() {
    return (
      <Grid data-component="productList">
        <Grid.Column width={5}>{this.renderLeftside()}</Grid.Column>
        <Grid.Column width={11}>{this.renderBody()}</Grid.Column>
      </Grid>
    );
  }
}

ProductList.propTypes = {
  resources: shape({
    products: instanceOf(ProductResource),
    productTypes: instanceOf(ProductTypeResource),
    suppliers: instanceOf(SupplierResource),
    createImagesResource: func, // instanceOf(ProductImageResource)
    specificationResource: func // instanceOf(ProductSpecificationResource)
  }).isRequired,
  i18n: I18nShape.isRequired,
  location: locationShape.isRequired,
  supplierId: string,
  productId: string,
  meta: shape({
    page: number,
    limit: number,
    total: number
  }),
  suppliers: arrayOf(SupplierShape),
  products: arrayOf(ProductShape),
  product: ProductShape,
  account: instanceOf(Account).isRequired,
  trades: arrayOf(TradeLabelShape).isRequired
};

ProductList.defaultProps = {
  meta: null,
  suppliers: [],
  products: [],
  product: undefined,
  supplierId: null,
  productId: null
};

const mapStateToProps = (state, props) => {
  return {
    i18n: getI18N(state),
    suppliers: getSuppliers(state),
    meta: getMeta(state),
    products: getProducts(state),
    product: getProduct(state),
    account: getAccount(state),
    trades: getFormattedTrades(state),
    supplierId: props.params.supplier_id,
    productId: props.params.product_id
  };
};

const mapDispatchToProps = dispatch => ({
  resources: {
    products: new ProductResource(dispatch),
    productTypes: new ProductTypeResource(dispatch),
    suppliers: new SupplierResource(dispatch),
    createImagesResource: productId =>
      new ProductImageResource(dispatch, productId),
    specificationResource: productId =>
      new ProductSpecificationResource(dispatch, productId)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
