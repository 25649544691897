export const translations = {
  "en": {
    "devise.confirmations.confirmed": "Your email address has been successfully confirmed.",
    "devise.confirmations.send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
    "devise.confirmations.send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes.",
    "devise.failure.already_authenticated": "You are already signed in.",
    "devise.failure.inactive": "Your account is not activated yet.",
    "devise.failure.invalid": "Invalid %{authentication_keys} or password.",
    "devise.failure.locked": "Your account is locked.",
    "devise.failure.last_attempt": "You have one more attempt before your account is locked.",
    "devise.failure.not_found_in_database": "Invalid %{authentication_keys} or password.",
    "devise.failure.timeout": "Your session expired. Please sign in again to continue.",
    "devise.failure.unauthenticated": "You need to sign in or sign up before continuing.",
    "devise.failure.unconfirmed": "You have to confirm your email address before continuing.",
    "devise.mailer.confirmation_instructions.subject": "Confirmation instructions",
    "devise.mailer.reset_password_instructions.subject": "Reset password instructions",
    "devise.mailer.unlock_instructions.subject": "Unlock instructions",
    "devise.mailer.password_change.subject": "Password Changed",
    "devise.omniauth_callbacks.failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
    "devise.omniauth_callbacks.success": "Successfully authenticated from %{kind} account.",
    "devise.passwords.no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
    "devise.passwords.send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
    "devise.passwords.send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
    "devise.passwords.updated": "Your password has been changed successfully. You are now signed in.",
    "devise.passwords.updated_not_active": "Your password has been changed successfully.",
    "devise.registrations.destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
    "devise.registrations.signed_up": "Welcome! You have signed up successfully.",
    "devise.registrations.signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
    "devise.registrations.signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
    "devise.registrations.signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
    "devise.registrations.update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
    "devise.registrations.updated": "Your account has been updated successfully.",
    "devise.sessions.signed_in": "Signed in successfully.",
    "devise.sessions.signed_out": "Signed out successfully.",
    "devise.sessions.already_signed_out": "Signed out successfully.",
    "devise.unlocks.send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
    "devise.unlocks.send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
    "devise.unlocks.unlocked": "Your account has been unlocked successfully. Please sign in to continue.",
    "errors.messages.already_confirmed": "was already confirmed, please try signing in",
    "errors.messages.confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
    "errors.messages.expired": "has expired, please request a new one",
    "errors.messages.not_found": "not found",
    "errors.messages.not_locked": "was not locked",
    "errors.messages.not_saved.one": "1 error prohibited this %{resource} from being saved:",
    "errors.messages.not_saved.other": "%{count} errors prohibited this %{resource} from being saved:",
    "hello": "Hello world",
    "title": "PLANO Project Management Software",
    "menu.main.home": "Home",
    "menu.main.projects": "Projects",
    "meta.form_of_address.greeting.default": "Dear",
    "meta.form_of_address.greeting.mr": "Dear",
    "meta.form_of_address.greeting.mrs": "Dear",
    "meta.form_of_address.salutation.label": "Salutation",
    "meta.form_of_address.salutation.nil": "No salutation",
    "meta.form_of_address.salutation.mr": "Mr",
    "meta.form_of_address.salutation.mrs": "Mrs",
    "meta.form_of_address.title.label": "Title",
    "meta.form_of_address.title.nil": "No title",
    "meta.form_of_address.title.dr": "Dr.",
    "meta.form_of_address.title.prof_dr": "Prof. Dr.",
    "meta.form_of_address.title.dipl_ing": "Dipl.-Ing.",
    "login.error.credentials": "Unknown username or password",
    "config_file_not_present": "File does not exist in the system: '% {file}'. Changes cannot be saved."
  },
  "de": {
    "activerecord.errors.messages.record_invalid": "Datensatz ist ungültig",
    "activerecord.errors.messages.restrict_dependent_destroy.has_one": "Datensatz kann nicht gelöscht werden, da ein abhängiger %{record}-Datensatz existiert.",
    "activerecord.errors.messages.restrict_dependent_destroy.has_many": "Datensatz kann nicht gelöscht werden, da abhängige %{record} existieren.",
    "date.abbr_day_names": [
      "So",
      "Mo",
      "Di",
      "Mi",
      "Do",
      "Fr",
      "Sa"
    ],
    "date.abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez"
    ],
    "date.day_names": [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag"
    ],
    "date.formats.default": "%d.%m.%Y",
    "date.formats.long": "%e. %B %Y",
    "date.formats.short": "%e. %b",
    "date.month_names": [
      null,
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
    ],
    "date.order": [
      "day",
      "month",
      "year"
    ],
    "datetime.distance_in_words.about_x_hours.one": "etwa eine Stunde",
    "datetime.distance_in_words.about_x_hours.other": "etwa %{count} Stunden",
    "datetime.distance_in_words.about_x_months.one": "etwa ein Monat",
    "datetime.distance_in_words.about_x_months.other": "etwa %{count} Monate",
    "datetime.distance_in_words.about_x_years.one": "etwa ein Jahr",
    "datetime.distance_in_words.about_x_years.other": "etwa %{count} Jahre",
    "datetime.distance_in_words.almost_x_years.one": "fast ein Jahr",
    "datetime.distance_in_words.almost_x_years.other": "fast %{count} Jahre",
    "datetime.distance_in_words.half_a_minute": "eine halbe Minute",
    "datetime.distance_in_words.less_than_x_minutes.one": "weniger als eine Minute",
    "datetime.distance_in_words.less_than_x_minutes.other": "weniger als %{count} Minuten",
    "datetime.distance_in_words.less_than_x_seconds.one": "weniger als eine Sekunde",
    "datetime.distance_in_words.less_than_x_seconds.other": "weniger als %{count} Sekunden",
    "datetime.distance_in_words.over_x_years.one": "mehr als ein Jahr",
    "datetime.distance_in_words.over_x_years.other": "mehr als %{count} Jahre",
    "datetime.distance_in_words.x_days.one": "ein Tag",
    "datetime.distance_in_words.x_days.other": "%{count} Tage",
    "datetime.distance_in_words.x_minutes.one": "eine Minute",
    "datetime.distance_in_words.x_minutes.other": "%{count} Minuten",
    "datetime.distance_in_words.x_months.one": "ein Monat",
    "datetime.distance_in_words.x_months.other": "%{count} Monate",
    "datetime.distance_in_words.x_years.one": "ein Jahr",
    "datetime.distance_in_words.x_years.other": "%{count} Jahr",
    "datetime.distance_in_words.x_seconds.one": "eine Sekunde",
    "datetime.distance_in_words.x_seconds.other": "%{count} Sekunden",
    "datetime.prompts.day": "Tag",
    "datetime.prompts.hour": "Stunden",
    "datetime.prompts.minute": "Minute",
    "datetime.prompts.month": "Monat",
    "datetime.prompts.second": "Sekunde",
    "datetime.prompts.year": "Jahr",
    "errors.format": "%{attribute} %{message}",
    "errors.messages.accepted": "muss akzeptiert werden",
    "errors.messages.blank": "muss ausgefüllt werden",
    "errors.messages.present": "darf nicht ausgefüllt werden",
    "errors.messages.confirmation": "stimmt nicht mit %{attribute} überein",
    "errors.messages.empty": "muss ausgefüllt werden",
    "errors.messages.equal_to": "muss genau %{count} sein",
    "errors.messages.even": "muss gerade sein",
    "errors.messages.exclusion": "ist nicht verfügbar",
    "errors.messages.greater_than": "muss größer als %{count} sein",
    "errors.messages.greater_than_or_equal_to": "muss größer oder gleich %{count} sein",
    "errors.messages.inclusion": "ist kein gültiger Wert",
    "errors.messages.invalid": "ist nicht gültig",
    "errors.messages.less_than": "muss kleiner als %{count} sein",
    "errors.messages.less_than_or_equal_to": "muss kleiner oder gleich %{count} sein",
    "errors.messages.model_invalid": "Gültigkeitsprüfung ist fehlgeschlagen: %{errors}",
    "errors.messages.not_a_number": "ist keine Zahl",
    "errors.messages.not_an_integer": "muss ganzzahlig sein",
    "errors.messages.odd": "muss ungerade sein",
    "errors.messages.required": "muss ausgefüllt werden",
    "errors.messages.taken": "ist bereits vergeben",
    "errors.messages.too_long.one": "ist zu lang (mehr als 1 Zeichen)",
    "errors.messages.too_long.other": "ist zu lang (mehr als %{count} Zeichen)",
    "errors.messages.too_short.one": "ist zu kurz (weniger als 1 Zeichen)",
    "errors.messages.too_short.other": "ist zu kurz (weniger als %{count} Zeichen)",
    "errors.messages.wrong_length.one": "hat die falsche Länge (muss genau 1 Zeichen haben)",
    "errors.messages.wrong_length.other": "hat die falsche Länge (muss genau %{count} Zeichen haben)",
    "errors.messages.other_than": "darf nicht gleich %{count} sein",
    "errors.template.body": "Bitte überprüfen Sie die folgenden Felder:",
    "errors.template.header.one": "Konnte %{model} nicht speichern: ein Fehler.",
    "errors.template.header.other": "Konnte %{model} nicht speichern: %{count} Fehler.",
    "helpers.select.prompt": "Bitte wählen",
    "helpers.submit.create": "%{model} erstellen",
    "helpers.submit.submit": "%{model} speichern",
    "helpers.submit.update": "%{model} aktualisieren",
    "number.currency.format.delimiter": ".",
    "number.currency.format.format": "%n %u",
    "number.currency.format.precision": 2,
    "number.currency.format.separator": ",",
    "number.currency.format.significant": false,
    "number.currency.format.strip_insignificant_zeros": false,
    "number.currency.format.unit": "€",
    "number.format.delimiter": ".",
    "number.format.precision": 2,
    "number.format.separator": ",",
    "number.format.significant": false,
    "number.format.strip_insignificant_zeros": false,
    "number.human.decimal_units.format": "%n %u",
    "number.human.decimal_units.units.billion.one": "Milliarde",
    "number.human.decimal_units.units.billion.other": "Milliarden",
    "number.human.decimal_units.units.million.one": "Million",
    "number.human.decimal_units.units.million.other": "Millionen",
    "number.human.decimal_units.units.quadrillion.one": "Billiarde",
    "number.human.decimal_units.units.quadrillion.other": "Billiarden",
    "number.human.decimal_units.units.thousand": "Tausend",
    "number.human.decimal_units.units.trillion.one": "Billion",
    "number.human.decimal_units.units.trillion.other": "Billionen",
    "number.human.decimal_units.units.unit": "",
    "number.human.format.delimiter": "",
    "number.human.format.precision": 3,
    "number.human.format.significant": true,
    "number.human.format.strip_insignificant_zeros": true,
    "number.human.storage_units.format": "%n %u",
    "number.human.storage_units.units.byte.one": "Byte",
    "number.human.storage_units.units.byte.other": "Bytes",
    "number.human.storage_units.units.gb": "GB",
    "number.human.storage_units.units.kb": "KB",
    "number.human.storage_units.units.mb": "MB",
    "number.human.storage_units.units.tb": "TB",
    "number.percentage.format.delimiter": "",
    "number.percentage.format.format": "%n %",
    "number.precision.format.delimiter": "",
    "support.array.last_word_connector": " und ",
    "support.array.two_words_connector": " und ",
    "support.array.words_connector": ", ",
    "time.am": "vormittags",
    "time.formats.default": "%A, %d. %B %Y, %H:%M Uhr",
    "time.formats.long": "%A, %d. %B %Y, %H:%M Uhr",
    "time.formats.short": "%d. %B, %H:%M Uhr",
    "time.pm": "nachmittags",
    "bau_digital": "Rubus Bau GmbH",
    "title": "SWM",
    "buyer_portal": "Online Konfigurator",
    "admin.schedule_account_jobs.index.title": "Schedule Jobs",
    "admin.schedule_account_jobs.index.display_name": "Job Name",
    "admin.product_manager_caches.index.title": "Produktmanager Caches",
    "admin.product_manager_caches.index.display_name": "Cache Name",
    "admin.product_manager_caches.index.cache_name": "%{cache_name} Cache",
    "admin.product_manager_caches.index.clear_button": "Cache leeren",
    "meta.form_of_address.greeting.label": "Begrüßung",
    "meta.form_of_address.greeting.default": "Sehr geehrte/r",
    "meta.form_of_address.greeting.defaultGeneric": "Sehr geehrte Damen und Herren",
    "meta.form_of_address.greeting.mr": "Sehr geehrter",
    "meta.form_of_address.greeting.mrs": "Sehr geehrte",
    "meta.form_of_address.greeting.mr+mrs": "Sehr geehrte",
    "meta.form_of_address.greeting.family": "Sehr geehrte",
    "meta.form_of_address.greeting.spouses": "Sehr geehrte",
    "meta.form_of_address.greeting.empty": "",
    "meta.form_of_address.greeting.other": "Sehr geehrt*",
    "meta.form_of_address.salutation.label": "Anrede",
    "meta.form_of_address.salutation.nil": "Keine Anrede",
    "meta.form_of_address.salutation.mr": "Herr",
    "meta.form_of_address.salutation.mrs": "Frau",
    "meta.form_of_address.salutation.mr+mrs": "Herr und Frau",
    "meta.form_of_address.salutation.family": "Familie",
    "meta.form_of_address.salutation.spouses": "Eheleute",
    "meta.form_of_address.salutation.empty": "",
    "meta.form_of_address.salutation.other": "Divers",
    "meta.form_of_address.title.label": "Titel",
    "meta.form_of_address.title.nil": "Kein Titel",
    "meta.form_of_address.title.dr": "Dr.",
    "meta.form_of_address.title.prof_dr": "Prof. Dr.",
    "meta.form_of_address.title.dipl_ing": "Dipl.-Ing.",
    "meta.form_of_address.title.empty": "",
    "meta.app.name": "SWM",
    "meta.title.attention": "Achtung",
    "meta.title.hint": "Hinweis",
    "meta.actions.activate": "Aktivieren",
    "meta.actions.deactivate": "Deaktivieren",
    "meta.actions.add": "Hinzufügen",
    "meta.actions.assign": "Zuordnen",
    "meta.actions.save": "Speichern",
    "meta.actions.complete": "Abschließen",
    "meta.actions.check": "Prüfen",
    "meta.actions.recheck": "Erneut prüfen",
    "meta.actions.copy": "Kopieren",
    "meta.actions.fork": "Ableiten",
    "meta.actions.remove": "Löschen",
    "meta.actions.remove_all": "Alle löschen",
    "meta.actions.reset": "Zurücksetzen",
    "meta.actions.restore": "Wiederherstellen",
    "meta.actions.purge": "Löschen",
    "meta.actions.delete_file": "Datei löschen",
    "meta.actions.archive": "Archivieren",
    "meta.actions.unlink": "Entfernen",
    "meta.actions.send": "Absenden",
    "meta.actions.cancel": "Abbrechen",
    "meta.actions.cancelOrder": "Storno",
    "meta.actions.download": "Herunterladen",
    "meta.actions.upload": "Hochladen",
    "meta.actions.preview": "Vorschau",
    "meta.actions.details": "Details",
    "meta.actions.download_now": "Jetzt Herunterladen",
    "meta.actions.ok": "OK",
    "meta.actions.true": "Ja",
    "meta.actions.yeah": "Ja",
    "meta.actions.false": "Nein",
    "meta.actions.nope": "Nein",
    "meta.actions.edit": "Bearbeiten",
    "meta.actions.next": "Weiter",
    "meta.actions.back": "Zurück",
    "meta.actions.done": "Fertig",
    "meta.actions.close": "Schließen",
    "meta.actions.accept": "Alles klar!",
    "meta.actions.clone": "Duplizieren",
    "meta.actions.apply": "Übernehmen",
    "meta.actions.applyAll": "{count, plural, one {Auswahl übernehmen} other {Alle übernehmen}}",
    "meta.actions.discard": "Verwerfen",
    "meta.actions.ignore": "Ignorieren",
    "meta.actions.start": "Beginnen",
    "meta.actions.select": "Auswählen",
    "meta.actions.select_all": "Alles auswählen",
    "meta.actions.selected": "Ausgewählt",
    "meta.actions.move": "Verschieben",
    "meta.actions.import": "Importieren",
    "meta.actions.replace": "Ersetzen",
    "meta.actions.showAll": "Alle Anzeigen",
    "meta.actions.showMore": "Mehr anzeigen",
    "meta.actions.showLess": "Weniger anzeigen",
    "meta.actions.correct": "Beheben",
    "meta.actions.confirmeCustomCostsPrices": "Die Preise und Kosten sollen abweichend vom Ausstattungskatalog überschrieben werden.",
    "meta.actions.proceed": "Fortfahren",
    "meta.actions.proceedAnyway": "Trotzdem fortfahren",
    "meta.actions.processing": "Ihre Anfrage wird verarbeitet",
    "meta.actions.sync": "Synchronisieren",
    "meta.actions.execute": "Ausführen",
    "meta.actions.omission": "Entfall",
    "meta.actions.clipboard": "URL in die Zwischenablage kopieren",
    "meta.actions.duplicate": "Duplizieren",
    "meta.actions.message.newMessage": "Neue Nachricht / Notiz",
    "meta.actions.message.newEmail": "Neue E-Mail",
    "meta.actions.message.replyEmail": "E-Mail beantworten",
    "meta.actions.message.newCall": "Neue Gesprächsnotiz",
    "meta.actions.message.newNote": "Neuer Kommentar",
    "meta.actions.message.reply": "Beantworten",
    "meta.actions.message.close_window": "Fenster schließen",
    "meta.actions.message.previousMessage": "Vorherige Nachricht",
    "meta.actions.dragAndDropUploadImage": "Bild-Datei hier her ziehen",
    "meta.actions.dragAndDropFile": "Hier klicken oder Datei hierher ziehen",
    "meta.confirmations.proceedAnyway": "Möchten Sie trotzdem fortfahren?",
    "meta.confirmations.changesNotSaved": "Die Änderungen wurden noch nicht übernommen!",
    "meta.confirmations.actionNotAllowed": "Aktion nicht erlaubt!",
    "meta.states.sent": "Nachricht wurde erfolgreich gesendet",
    "meta.states.loading": "Daten werden geladen",
    "meta.states.saving": "Daten werden gespeichert",
    "meta.states.selectedFromTotal": "{selected} von {total} ausgewählt",
    "meta.states.saved": "Die Daten wurden erfolgreich gespeichert",
    "meta.states.error": "Es ist ein Fehler aufgetreten. Die Daten konnten nicht gespeichert werden.",
    "meta.states.hint": "Hinweis",
    "meta.states.deleted": "Die Daten wurden gelöscht",
    "meta.states.active": "Aktiv",
    "meta.states.inactive": "Inaktiv",
    "meta.date.month.january": "Januar",
    "meta.date.month.february": "Februar",
    "meta.date.month.march": "März",
    "meta.date.month.april": "April",
    "meta.date.month.may": "Mai",
    "meta.date.month.june": "Juni",
    "meta.date.month.july": "Juli",
    "meta.date.month.august": "August",
    "meta.date.month.september": "September",
    "meta.date.month.october": "Oktober",
    "meta.date.month.november": "November",
    "meta.date.month.december": "Dezember",
    "meta.date.formatSince.today": "heute",
    "meta.date.formatSince.yesterday": "seit gestern",
    "meta.date.formatSince.days": "letzte {value} Tage",
    "meta.date.formatSince.months": "letzte {value} Monate",
    "meta.message.noResults": "Es wurden keine Einträge gefunden.",
    "meta.validation.required": "Pflichtfeld",
    "meta.validation.minCharacters": "Mindestens {min} Zeichen",
    "meta.validation.email": "Valide E-Mail Adresse erforderlich",
    "meta.last_edited": "zuletzt bearbeitet: {datetime, select, null {nie} other {{datetime}}}",
    "meta.languages.de": "Deutsch",
    "meta.languages.en": "English",
    "menu.main.login": "Anmelden",
    "menu.main.dashboard": "Übersicht",
    "menu.main.process": "Vorgänge",
    "menu.main.project": "Bauprojekte",
    "menu.main.buyer": "Erwerber",
    "menu.main.contractor": "Nachunternehmen",
    "menu.main.roomBook": "Raumbücher",
    "menu.main.notification": "Benachrichtigungen",
    "menu.main.setting": "Einstellungen",
    "menu.main.myProfile": "Mein Profil",
    "menu.main.accountAdmin": "Kontoeinstellungen",
    "menu.main.supportUtils": "Danger Zone",
    "menu.main.jobs": "Jobs",
    "menu.main.logout": "Abmelden",
    "menu.main.products": "Ausstattungskatalog",
    "menu.main.gettingStarted": "Erste Schritte",
    "menu.main.help": "Hilfe",
    "menu.main.news": "Neuigkeiten",
    "menu.main.documentExports": "Dokumentenexporte",
    "menu.main.featureToggles": "Feature Toggles",
    "menu.main.packageManager": "Package Manager",
    "menu.main.accountRoles": "Rollen",
    "menu.main.API": "API Dokumentation",
    "menu.main.webhooks": "API Webhooks",
    "menu.main.dataExport": "Data export",
    "menu.main.projectFiles": "Projektdateien",
    "process.header.title": "Prozesse",
    "process.header.button.add": "Prozess hinzufügen",
    "process.title.state.one": "Arbeitsschritt",
    "process.title.phase.one": "Arbeitsphase",
    "process.attributes.id.label": "ID",
    "process.attributes.process_type.label": "ID",
    "process.attributes.process_role.label": "Prozessrolle",
    "process.attributes.activity_scope.label": "Vorgangsbezug",
    "process.attributes.label.label": "Anzeigename",
    "process.attributes.deprecated.label": "Veraltet",
    "process.attributes.startable_from_message_thread.label": "Kann aus Nachricht gestartet werden",
    "process.attributes.hides_carts.label": "Ohne Leistungsumfang",
    "process.attributes.color.label": "Anzeigefarbe",
    "process.attributes.bp_label.label": "Anzeigename (Erwerber)",
    "process.attributes.bp_color.label": "Anzeigefarbe (Erwerber)",
    "process.attributes.terminal.label": "Endzustand - keine Frist",
    "process.attributes.summary.label": "Beschreibung",
    "process.attributes.startable.label": "Möglicher Vorgangsbeginn",
    "process.attributes.deadline.label": "Frist in Tagen",
    "process.attributes.deadline.placeholder": "Keine Frist",
    "process.attributes.deadline.error": "Bitte geben Sie eine ganze Zahl ein",
    "process.attributes.deadline_on.label": "Zeitpunkt der Fristaktivierung",
    "process.attributes.existing_process_types.label": "Select original process",
    "process.messages.add.success.title": "Saved",
    "process.messages.add.success.body": "New process added.",
    "process.messages.add.error.title": "Not saved",
    "process.messages.add.error.body": "Adding a new process failed.",
    "process.messages.edit.success.title": "Process saved",
    "process.messages.edit.success.body": "Changes are saved.",
    "process.messages.edit.error.title": "Not saved",
    "process.messages.edit.error.body": "Saving changes failed.",
    "process.messages.remove.success.title": "Process removed",
    "process.messages.remove.success.body": "Process is sucessfuly removed.",
    "process.messages.remove.error.title": "Not removed",
    "process.messages.remove.error.body": "Remove process failed.",
    "process.remove_dialog.title": "Prozess löschen",
    "process.remove_dialog.message": "Wollen Sie diesen Prozess löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "trades.title.one": "Gewerk",
    "trades.title.many": "Gewerke",
    "trades.attributes.id.label": "ID",
    "trades.attributes.id.error": "Eindeutiger, 3-5 - Großbuchstaben Kürzel",
    "trades.attributes.defaultMargin.label": "Standard Aufschlag",
    "trades.attributes.defaultMargin.short_label": "Aufschlag (%)",
    "trades.attributes.label.label": "Bezeichnung",
    "trades.actions.removeDialog.title": "Gewerk löschen",
    "trades.actions.removeDialog.message": "Möglicherweise wird das Gewerk bereits verwendet. Das Löschen könnte zu Fehlern führen. Wollen Sie trotzdem fortfahren?",
    "trades.unknown_trade": "Unbekanntes Gewerk %{id}",
    "account.selector.search.placeholder": "Suche...",
    "account.selector.search.resultCount": "Es werden {resultsShown} von insgesamt {resultsTotal} Ergebnissen angezeigt.",
    "account.selector.search.empty": "Keine Einträge gefunden",
    "account.selector.trade.placeholder": "Gewerk...",
    "account.selector.trade.no_trades": "Ohne Gewerk",
    "account.title.settings": "Kontoeinstellungen",
    "account.title.headers.buyer_portal_general": "Online-Konfigurator Allgemein",
    "account.title.headers.upload_section": "Dateien hochladen",
    "account.title.headers.settings_section": "Allgemeine Einstellungen",
    "account.title.headers.upload_login_logo": "Logo, das auf der Login-Seite des Online Konfigurators angezeigt wird.",
    "account.title.headers.upload_terms": "Allgemeine Geschäftsbedingungen",
    "account.title.headers.data_protection_policy_terms": "Datenschutzbestimmungen",
    "account.attributes.name.label": "Name",
    "account.attributes.slug.label": "Slug",
    "account.attributes.logo_url.label": "Logo Url",
    "account.attributes.reply_to_email.label": "Antwort E-Mail Adresse",
    "account.attributes.sender_mode.label": "E-Mail Versand Modus",
    "account.attributes.reply_mode.label": "E-Mail Empfang Modus",
    "account.attributes.unknown_message_mode.label": "E-Mail Empfang von unbekannten Nachrichten",
    "account.attributes.sender_email.label": "Account Absender Adresse",
    "account.attributes.fallback_email.label": "Account Fallback E-Mail Adresse",
    "account.attributes.mailjet_variation.label": "Mailjet variation",
    "account.attributes.domain.label": "Domain",
    "account.attributes.processes.label": "Prozess-Definitionen",
    "account.attributes.trades.label": "Gewerke",
    "account.attributes.features.label": "Feature Toggles",
    "account.attributes.attachment_types.label": "Dokumenttypen",
    "account.attributes.project_roles.label": "Rollen",
    "account.attributes.buyer_portal.admin_area": "Adminbereich",
    "account.attributes.buyer_portal.uploads.logo": "Logo für den Account",
    "account.attributes.buyer_portal.uploads.data_protection_policy": "Datenschutzinformationen für die Erwerber",
    "account.attributes.buyer_portal.uploads.terms_and_conditions": "Allgemeine Geschäftsbedingungen für die Erwerber",
    "account.attributes.buyer_portal.textareas.imprint": "Impressum",
    "account.attributes.buyer_portal.textareas.display_text": "Bei der Anmeldung angezeigter Text, dem die Erwerber zustimmen müssen.",
    "account.attributes.buyer_portal.legal_terms.title": "Rechtsbelehrung",
    "account.attributes.buyer_portal.legal_terms.label": "Laden Sie ihre Dateien hoch und passen Sie den Text an. HTML wird unterstützt",
    "account.attributes.buyer_portal.legal_terms.data_privacy_info": "Datenschutzinformationen für die Erwerber",
    "account.attributes.buyer_portal.legal_terms.terms_and_conditions": "Allgemeine Geschäftsbedingungen für die Erwerber",
    "account.attributes.buyer_portal.legal_terms.display_text": "Bei der Anmeldung angezeigter Text, dem die Erwerber zustimmen müssen.",
    "account.attributes.buyer_portal.login_logo_url.title": "Logo für den Account",
    "account.attributes.buyer_portal.login_logo_url.label": "Laden Sie das Logo Ihres Unternehmens hoch, wird auf der Login Seite angezeigt",
    "account.attributes.buyer_portal.favicon_html.title": "Favicon",
    "account.attributes.buyer_portal.favicon_html.label": "HTML Code für Bookmark- und App- Icon",
    "account.attributes.buyer_portal.styling.title": "CSS Stylesheet (SASS)",
    "account.attributes.buyer_portal.styling.label": "CSS Stylesheet (SASS)",
    "account.attributes.buyer_portal.accept_terms_label.title": "Hinweis auf die Nutzungsbedingungen / Datenschutzerklärung",
    "account.attributes.buyer_portal.accept_terms_label.label": "Bei der Anmeldung angezeigter Text, dem die Erwerber zustimmen müssen.",
    "account.attributes.buyer_portal.buyer_portal_data_privacy_statement_url.label": "Link URI Datenschutzerklärung",
    "account.attributes.buyer_portal.buyer_portal_terms_url.label": "Link URI AGB",
    "account.attributes.buyer_portal.imprint.title": "Impressum für den Online Konfigurator",
    "account.attributes.buyer_portal.imprint.label": "Hier ist Platz für das Impressum. HTML wird Unterstützt",
    "account.attributes.buyer_portal.allowed_origins.title": "Marketingportal",
    "account.attributes.buyer_portal.allowed_origins.label": "Erlaubte Seiten, die das Marketingportal einbinden, Dies muss eine URL sein. (https://baudigital.com/demo.html)",
    "account.project_memberships.title": "Benutzerberechtigungen auf Projektebene",
    "account.project_memberships.description": "Der Zugriff auf Projekte ist nur autorisierten Benutzern erlaubt. Bitte legen Sie hier die autorisierten Benutzer pro Projekt fest.",
    "account.project_memberships.missing": "Diesem Projekt sind keine Benutzer zugeordnet.",
    "account.project_memberships.user_added_to_project": "Benutzer wurde dem Projekt hinzugefügt.",
    "account.project_memberships.user_added_to_project_error": "Beim Hinzufügen des Benutzers zum Projekt ist ein Fehler aufgetreten.",
    "account.project_memberships.user_removed_from_project": "Benutzer wurde aus dem Projekt entfernt.",
    "account.project_memberships.user_removed_from_project_error": "Beim Entfernen des Benutzers aus dem Projekt ist ein Fehler aufgetreten.",
    "account.project_memberships.user_changed": "Benutzer wurde geändert.",
    "account.project_memberships.user_changed_error": "Benutzer konnte nicht geändert werden.",
    "account.project_memberships.user_assigned_to_project": "Benutzer wurde dem Projekt zugewiesen.",
    "account.project_memberships.user_assigned_to_project_error": "Beim Zuweisen des Benutzers zum Projekt ist ein Fehler aufgetreten.",
    "account.project_memberships.dialog.title": "Nachfolger für Benutzer {user} nominieren",
    "account.project_memberships.dialog.messages": "Nachrichten",
    "account.project_memberships.dialog.units": "Wohneinheiten",
    "account.project_memberships.dialog.activities": "Vorgänge",
    "account.project_memberships.dialog.prospects": "Interessenten",
    "account.project_memberships.dialog.edit": "Bearbeiten",
    "account.project_memberships.attributes.name.label": "Name",
    "account.project_memberships.attributes.email_signature.label": "E-Mail Signatur",
    "account.project_memberships.attributes.project_role.label": "Rolle",
    "account.project_memberships.attributes.project_email.label": "Projekt E-Mail",
    "account.project_memberships.attributes.roles.label": "Vorgangsrollen",
    "account.actions.upload_logo": "Datei wählen",
    "account.actions.remove_logo": "Datei entfernen",
    "account.settings.saving_reminder.header": "Ungespeicherte Änderungen",
    "account.settings.saving_reminder.content": "Möchten Sie Ihre Änderungen speichern?",
    "account.settings.image_selector.select_image": "Bild auswählen",
    "account.settings.image_selector.select_project": "Projekt wählen",
    "account.settings.image_selector.add_new_gallery": "Neue Galerie anlegen",
    "account.settings.image_selector.delete_selected_images": "Ausgewählte Bilder löschen",
    "account.settings.image_selector.unable_to_delete_selected_image": "Das Bild ist aktuell der Sektion zugeordnet und kann deshalb nicht gelöscht werden.",
    "account.settings.image_selector.edit_gallery": "Galerie bearbeiten",
    "account.settings.image_selector.finish_edit": "Bearbeiten beenden",
    "account.settings.image_selector.menu.default": "Allgemein",
    "account.settings.buyer_portal.account_tab.account_configuration": "Konfiguration",
    "account.settings.buyer_portal.project_tab.warning_color": "Warnfarbe",
    "account.settings.buyer_portal.project_tab.branding_color": "Unternehmensfarbe",
    "account.settings.buyer_portal.project_tab.branding_contrast_color": "Kontrastfarbe",
    "account.settings.buyer_portal.project_tab.units_menu_item_text.label": "Menüpunkt \"Wohneinheiten\" umbenennen",
    "account.settings.buyer_portal.project_tab.units_menu_item_text.placeholder": "Meine Wohnungen",
    "account.settings.buyer_portal.project_tab.banner_image_url.header": "Bildbanner für den Online Konfigurator",
    "account.settings.buyer_portal.project_tab.banner_image_url.info": "Die ideale Bildgröße liegt um 2000x470 Pixel.\n\nBitte beachten Sie, dass sich die Dateigröße auf die Ladezeit im Online Konfigurator auswirkt.",
    "account.settings.buyer_portal.project_tab.project_logo_url.header": "Logo für das Projekt",
    "account.settings.buyer_portal.project_tab.project_logo_url.info": "Das Projektlogo wird über das Bildbanner im Online Konfigurator gelegt. Es ersetzt nicht das Firmenlogo, das in der Menüleiste oben links angezeigt wird.",
    "account.settings.buyer_portal.project_tab.configurator_sections": "Sektionen im Online Konfigurator bearbeiten",
    "account.settings.buyer_portal.project_tab.css": "CSS bearbeiten",
    "account.settings.buyer_portal.project_tab.project_configuration": "Projektkonfiguration",
    "account.settings.buyer_portal.project_tab.project_rooms": "Räume",
    "account.settings.buyer_portal.project_tab.configurator_backends": "Konfiguratoren (Glencoe)",
    "account.settings.buyer_portal.project_tab.flat_finder": "FlatFinder",
    "macro.title": "Makro: {name}",
    "macro.toggle.label": "Toggle HTML view",
    "macro.attributes.macro_id.label": "ID",
    "macro.attributes.name.label": "Name",
    "macro.attributes.call_to_action.label": "Call to action",
    "macro.attributes.deadline.label": "Frist",
    "macro.attributes.icon.label": "Icon",
    "macro.attributes.body.label": "Nachricht",
    "macro.attributes.body_template.label": "Vorlage Nachricht",
    "macro.attributes.subject_template.label": "Vorlage Betreff",
    "macro.attributes.config.label": "Konfiguration",
    "macro.attributes.original_macro.label": "Original Makro",
    "macro.attributes.projects.label": "Projekte",
    "macro.attributes.projects.placeholder": "Alle Projekte",
    "macro.message.success.title": "Speichern abgeschlossen",
    "macro.message.success.body": "Die Änderungen wurden erfolgreich übernommen",
    "macro.message.success.body_remove": "Macro is successfully deleted",
    "macro.message.error.title": "Es ist ein Fehler aufgetreten",
    "macro.message.error.body": "Die Änderungen konnten nicht übernommen werden",
    "macro.message.error.body_remove": "Macro couldn't be deleted",
    "roomBook.title.zero": "Kein Raumbuch",
    "roomBook.title.one": "Raumbuch",
    "roomBook.title.other": "Raumbücher",
    "roomBook.title.missing": "Es wurde noch kein Raumbuch angelegt",
    "roomBook.title.creation": "Raumbuch wird angelegt",
    "roomBook.templateTitle.one": "Raumbuchvorlage",
    "roomBook.priceStrategy.length": "m",
    "roomBook.priceStrategy.pieces": "Stk.",
    "roomBook.priceStrategy.area": "m²",
    "roomBook.priceStrategy.flat": "pauschal",
    "roomBook.priceStrategy.hour": "Std.",
    "roomBook.priceStrategyLong.length": "Meter",
    "roomBook.priceStrategyLong.pieces": "Stück",
    "roomBook.priceStrategyLong.area": "m²",
    "roomBook.priceStrategyLong.flat": "pauschal",
    "roomBook.priceStrategyLong.flatLabel": "Pauschal",
    "roomBook.priceStrategyLong.hour": "Stunde",
    "roomBook.priceStrategyShort.length": "m",
    "roomBook.priceStrategyShort.pieces": "x",
    "roomBook.priceStrategyShort.area": "m²",
    "roomBook.priceStrategyShort.hour": "h",
    "roomBook.priceStrategyShort.flat": " ",
    "roomBook.priceStrategyExports.length": "lfm",
    "roomBook.priceStrategyExports.pieces": "stk",
    "roomBook.priceStrategyExports.area": "m²",
    "roomBook.priceStrategyExports.hour": "h",
    "roomBook.priceStrategyExports.flat": "pauschal",
    "roomBook.priceStrategyLongValue.length": "{quantity} Meter",
    "roomBook.priceStrategyLongValue.pieces": "{quantity} Stück",
    "roomBook.priceStrategyLongValue.area": "{quantity} m²",
    "roomBook.priceStrategyLongValue.hour": "{quantity} {quantity, plural, one {Stunde} other {Stunden}}",
    "roomBook.standardSelection": "Standardauswahl",
    "roomBook.approvedSelection": "Bestätigt",
    "roomBook.buyerSelection": "Erwerberwunsch",
    "roomBook.standardCostsAttributes": "Kosten der Standardauswahl (netto)",
    "roomBook.selection": "Preis (Standardmenge)",
    "roomBook.selectionExcess": "Preis (Mehrmenge)",
    "roomBook.budget": "Standardbudget",
    "roomBook.unitVariableType": "Mengenvariable",
    "roomBook.subsubtotal.gross": "Zwischensumme (brutto)",
    "roomBook.subsubtotal.net": "Zwischensumme (netto)",
    "roomBook.subtotal.gross": "Zwischensumme (brutto)",
    "roomBook.subtotal.net": "Zwischensumme (netto)",
    "roomBook.roomBookTotal.gross": "Gesamt (brutto)",
    "roomBook.roomBookTotal.net": "Gesamt (netto)",
    "roomBook.genericSubtotal": "Gesamt",
    "roomBook.total": "Mehrpreis (brutto)",
    "roomBook.copyOf": "Kopie von ",
    "roomBook.derivedFrom": "Abgeleitet von:",
    "roomBook.new": "Neues Raumbuch",
    "roomBook.otherTrades": "Andere Gewerke",
    "roomBook.chooseTrade": "Kategorie auswählen",
    "roomBook.noCart": "Entscheidung offen",
    "roomBook.noCarts": "Es wurden noch keine Produkte zu einer Erwerberauswahl hinzugefügt.",
    "roomBook.progress.title.one": "Bemusterungsfortschritt",
    "roomBook.progress.states.none": "Offen",
    "roomBook.progress.states.decided": "Bemusterungs­festlegung",
    "roomBook.progress.states.favourite": "Ausgewählt",
    "roomBook.progress.states.buyer_selection": "Erwerber­auswahl",
    "roomBook.cart.title.one": "Vorgang",
    "roomBook.cart.title.other": "Vorgänge",
    "roomBook.cart.exampleTitle": "Vorgang",
    "roomBook.cart.attributes.process_type.label": "Vorgangstyp",
    "roomBook.cart.attributes.start_status.label": "Vorgangsbeginn",
    "roomBook.cart.attributes.unit.label": "Wohneinheit",
    "roomBook.cart.attributes.section.label": "Bauabschnitt",
    "roomBook.cart.attributes.title.label": "Bezeichnung",
    "roomBook.cart.attributes.title.placeholder": "z.B. \"Bemusterungstermin 13.06.\"",
    "roomBook.cart.attributes.title.error": "Bitte geben Sie eine Bezeichnung für den Vorgang an.",
    "roomBook.cart.actions.export": "Export",
    "roomBook.cart.messages.export": "Dieser Export wurde den Dokumenten des Vorgangs hinzugefügt.",
    "roomBook.customProduct": "Individualwunsch",
    "roomBook.tabs.positions": "Positionen",
    "roomBook.tabs.carts": "Vorgänge",
    "roomBook.attributes.name.label": "Titel",
    "roomBook.attributes.name.placeholder": "Titel des Raumbuchs z.B. \"Gebäude II Wohnungstyp 80qm\"",
    "roomBook.attributes.project_catalog_id.label": "Ausstattungskatalog",
    "roomBook.attributes.project_catalog_id.placeholder": "Wählen Sie den Ausstattungskatalog",
    "roomBook.attributes.price_catalog_id.label": "Preisliste",
    "roomBook.attributes.price_catalog_id.placeholder": "Wählen Sie die Preisliste",
    "roomBook.sections.primary.notFound": "Dieses Raumbuch verfügt noch nicht über Abschnitte. Legen Sie den ersten Abschnitt jetzt an.",
    "roomBook.sections.primary.attributes.title.label": "Bezeichnung",
    "roomBook.sections.primary.attributes.title.placeholder": "z.B. \"Sanitärtechnik\" oder \"Elektroinstallation\"",
    "roomBook.sections.primary.attributes.title.error": "Bitte geben Sie eine Bezeichnung für den Abschnitt an.",
    "roomBook.sections.secondary.attributes.title.label": "Bezeichnung",
    "roomBook.sections.secondary.attributes.title.placeholder": "z.B. \"Badezimmer\" oder \"Küche\"",
    "roomBook.sections.secondary.attributes.title.error": "Bitte geben Sie eine Bezeichnung für die Rubrik an.",
    "roomBook.sections.actions.removeDialog.title": "Abschnitt löschen",
    "roomBook.sections.actions.removeDialog.message": "Wollen Sie den Abschnitt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.sections.attributes.project_room_id.label": "Raum im Online-Konfigurator",
    "roomBook.sections.attributes.project_room_id.placeholder": "Bitte ordnen Sie diesen Abschnitt einem Raum im Online-Konfigurator zu",
    "roomBook.sections.attributes.project_room_ids.label": "Räume im Online-Konfigurator",
    "roomBook.sections.attributes.project_room_ids.placeholder": "Bitte ordnen Sie diesen Abschnitt einem oder mehreren Räumen im Online-Konfigurator zu",
    "roomBook.sections.attributes.external_id.label": "Externe ID",
    "roomBook.sections.attributes.external_id.placeholder": "z.B. 123",
    "roomBook.sections.attributes.unitFeatures.label": "Merkmale",
    "roomBook.lineItems.title.one": "Position",
    "roomBook.lineItems.title.other": "Positionen",
    "roomBook.lineItems.tabs.standards": "Standardauswahl",
    "roomBook.lineItems.tabs.selection": "Kundenauswahl",
    "roomBook.lineItems.tabs.documents": "Dokumente",
    "roomBook.lineItems.tabs.costs": "Kosten",
    "roomBook.lineItems.surcharge": "Preisanpassung (brutto)",
    "roomBook.lineItems.discount": "Aufschlag / Rabatt (brutto)",
    "roomBook.lineItems.total.net": "Summe (netto)",
    "roomBook.lineItems.total.gross": "Summe (brutto)",
    "roomBook.lineItems.net": "netto",
    "roomBook.lineItems.gross": "brutto",
    "roomBook.lineItems.selectedProduct": "Ausgewähltes Produkt",
    "roomBook.lineItems.individualProduct": "Wunschprodukt",
    "roomBook.lineItems.addIndividualProduct": "Wunschprodukt hinzufügen",
    "roomBook.lineItems.individualProductDescription": "Beschreibung Wunschprodukt",
    "roomBook.lineItems.productAlternatives": "Alternativen aus dem Ausstattungskatalog",
    "roomBook.lineItems.buyerSelection.buyerSelectionNotFound": "Erwerber hat noch keine Auswahl getroffen",
    "roomBook.lineItems.buyerSelection.equalsBuyerSelection": "Mit Erwerberauswahl übereinstimmend",
    "roomBook.lineItems.buyerSelection.buyerSelected": "Erwerber wünscht",
    "roomBook.lineItems.buyerSelection.buyerSelectedBatch.title": "Erwerberauswahl übernehmen",
    "roomBook.lineItems.buyerSelection.buyerSelectedBatch.total": "Für {count, plural, one {eine Position} other {# Positionen}} liegt eine Erwerberauswahl vor.",
    "roomBook.lineItems.buyerSelection.customProductBuyerSelections.title": "Wunschprodukte",
    "roomBook.lineItems.buyerSelection.customProductBuyerSelections.total": "Für {count, plural, one {eine Position wird ein Individualprodukt} other {# Positionen werden Individualprodukte}} gewünscht.",
    "roomBook.lineItems.buyerSelection.acceptedAll.title": "Auswahl übernommen",
    "roomBook.lineItems.buyerSelection.acceptedAll.body": "Die Erwerberauswahl wurde erfolgreich übernommen.",
    "roomBook.lineItems.buyerSelection.pending": "Bitte übernehmen oder verwerfen Sie die Erwerberauswahl, bevor Sie mit der Abwicklung fortfahren.",
    "roomBook.lineItems.lightBox.title": "Produktauswahl",
    "roomBook.lineItems.timeline.title": "Statushistorie",
    "roomBook.lineItems.attributes.product_id.label": "Produkt",
    "roomBook.lineItems.attributes.title.label": "Positionstitel",
    "roomBook.lineItems.attributes.title.placeholder": "z.B. \"Waschtisch\"",
    "roomBook.lineItems.attributes.title.error": "Bitte geben Sie hier eine Positionsbezeichnung an.",
    "roomBook.lineItems.attributes.display_mode.label": "Darstellungsmodus",
    "roomBook.lineItems.attributes.display_mode.options.cards": "Produktkarten (Standard)",
    "roomBook.lineItems.attributes.display_mode.options.compact_list": "Kompakte Liste mit Mengenregeler",
    "roomBook.lineItems.attributes.display_mode.options.mandatory": "Einzelne Karte + kompakt im Erwerberangebot (veraltet)",
    "roomBook.lineItems.attributes.display_mode.options.compacted": "Nur Hauptposition im Erwerberangebot, Unterposition verstecken",
    "roomBook.lineItems.attributes.display_mode_buyer.label": "Darstellungsmodus Exporte Erwerber",
    "roomBook.lineItems.attributes.display_mode_exports.options.default": "Standard",
    "roomBook.lineItems.attributes.display_mode_exports.options.compact": "Kompakt",
    "roomBook.lineItems.attributes.upgrade_bundle_id.label": "Upgrade Paket (Standard)",
    "roomBook.lineItems.attributes.upgrade_bundle_id.placeholder": "Wenn diese Position den Inhalt des Standard Upgrade Paketes definiert",
    "roomBook.lineItems.attributes.optional.title": "Optional",
    "roomBook.lineItems.attributes.optional.label": "Position ist optional",
    "roomBook.lineItems.attributes.upgrade_bundle.title": "{name}",
    "roomBook.lineItems.attributes.upgrade_bundle.label": "Upgrade Paket {name}",
    "roomBook.lineItems.attributes.quantity.label": "Menge",
    "roomBook.lineItems.attributes.quantity.placeholder": "z.B. 1",
    "roomBook.lineItems.attributes.quantity.error": "Bitte geben Sie hier die benötigte Anzahl oder Menge des ausgewählten Produkts an.",
    "roomBook.lineItems.attributes.discount.label": "Rabatt",
    "roomBook.lineItems.attributes.discount.placeholder": "z.B. 112,00",
    "roomBook.lineItems.attributes.discount.error": "Bitte geben Sie hier einen Brutto-Rabatt oder 0 an.",
    "roomBook.lineItems.attributes.default_product_id.label": "Standardprodukt",
    "roomBook.lineItems.attributes.default_product_id.error": "Bitte geben Sie hier einen Brutto-Rabatt oder 0 an.",
    "roomBook.lineItems.attributes.default_quantity.label": "Standardmenge",
    "roomBook.lineItems.attributes.default_quantity.placeholder": "z.B. 1",
    "roomBook.lineItems.attributes.default_quantity.error": "Bitte geben Sie hier die benötigte Standard-Anzahl oder -Menge des ausgewählten Produkts an.",
    "roomBook.lineItems.attributes.price.label": "Pauschalpreis",
    "roomBook.lineItems.attributes.price.placeholder": "z.B. 129,99 oder 0,00",
    "roomBook.lineItems.attributes.price.error": "Bitte geben Sie hier den Pauschal- oder Einzelpreis des Produkts an.",
    "roomBook.lineItems.attributes.default_price.label": "Vorgesehenes Pauschalbudget",
    "roomBook.lineItems.attributes.default_price.placeholder": "z.B. 129,99 oder 0,00",
    "roomBook.lineItems.attributes.default_price.error": "Bitte geben Sie hier den vorgesehenen Standard-Pauschal- oder Standard-Einzelpreis des Produkts an.",
    "roomBook.lineItems.attributes.budget.label": "Budget",
    "roomBook.lineItems.attributes.total.label": "Gesamt",
    "roomBook.lineItems.attributes.description.label": "Beschreibung des Kundenwunsches",
    "roomBook.lineItems.attributes.description.placeholder": "z.B. \"Türzarge 180° gedreht\" oder \"Boden ohne Fliesen\"",
    "roomBook.lineItems.attributes.description.error": "Bitte geben Sie hier eine Beschreibung der gewünschten Änderung an.",
    "roomBook.lineItems.attributes.instructions.label": "Allgemeiner Ausführungshinweis",
    "roomBook.lineItems.attributes.instructions.placeholder": " ",
    "roomBook.lineItems.attributes.default_description.label": "Beschreibung der vorgesehenen Standardausführung",
    "roomBook.lineItems.attributes.default_description.placeholder": "z.B. \"Tür 80cm, Standardzarge, weiss\"",
    "roomBook.lineItems.attributes.default_description.error": "Bitte geben Sie hier die Beschreibung der vorgesehenen Standardausführung dieser Position an.",
    "roomBook.lineItems.attributes.activity.missing": "Kein Vorgang",
    "roomBook.lineItems.attributes.newPosition.label": "Neue Position",
    "roomBook.lineItems.attributes.costs.trade.label": "Gewerk",
    "roomBook.lineItems.attributes.costs.default_quantity.label": "Standardmenge",
    "roomBook.lineItems.attributes.costs.excess_quantity.label": "Mehrmenge",
    "roomBook.lineItems.attributes.costs.net_total.label": "Gesamt (netto)",
    "roomBook.lineItems.attributes.costs.selection_costs.label": "Kosten der Kundenauswahl",
    "roomBook.lineItems.attributes.costs.default_costs.label": "Kosten der Standardauswahl",
    "roomBook.lineItems.attributes.costs.net_unit_costs.label": "Einzelpreis (netto)",
    "roomBook.lineItems.attributes.costs.net_sum_of_costs.label": "Gesamt (netto)",
    "roomBook.lineItems.attributes.costs.total_costs.label": "Mehrkosten",
    "roomBook.lineItems.attributes.cancellation_contactor_mode.skip.title": "Ohne Stornoauftrag",
    "roomBook.lineItems.attributes.cancellation_contactor_mode.skip.hint": "Die Leistung muss nicht beim Nachunternehmen storniert werden, da sie noch nicht beauftragt wurde.",
    "roomBook.lineItems.attributes.cancellation_contactor_mode.inform.title": "Stornoauftrag",
    "roomBook.lineItems.attributes.cancellation_contactor_mode.inform.hint": "Die Stornierung muss explizit beim Nachunternehmen beauftragt werden.",
    "roomBook.lineItems.attributes.cancellation_mode.cancellation.title": "Rücktritt",
    "roomBook.lineItems.attributes.cancellation_mode.cancellation.hint": "Erwerber möchte ausschließlich von der Beauftragung zurücktreten und wünscht keine Neuauswahl.",
    "roomBook.lineItems.attributes.cancellation_mode.replacement.title": "Neuauswahl",
    "roomBook.lineItems.attributes.cancellation_mode.replacement.hint": "Erwerber möchte die bereits beauftragte Leistung durch eine Alternative ersetzen.",
    "roomBook.lineItems.attributes.external_id.title.label": "Externe ID",
    "roomBook.lineItems.attributes.external_id.title.placeholder": "z.B. 123",
    "roomBook.lineItems.status.open.label": "Entscheidung offen",
    "roomBook.lineItems.status.open.hint": "Ordnen Sie dieser Position ein Vorgang zu, um mit der Abwicklung zu beginnen.",
    "roomBook.lineItems.status.inProgress.hint": "Dieser Vorgang befindet sich im Status:",
    "roomBook.lineItems.status.pending.hint": "Dieser Vorgang wurde noch nicht gestartet. Klicken Sie den Vorgang an, um diesen zu starten.",
    "roomBook.lineItems.actions.confirm": "Trotzdem fortfahren",
    "roomBook.lineItems.actions.cancel": "Abbrechen",
    "roomBook.lineItems.actions.sort": "Produkt verschieben",
    "roomBook.lineItems.actions.increasePosition": "Nach oben verschieben",
    "roomBook.lineItems.actions.decreasePosition": "Nach unten verschieben",
    "roomBook.lineItems.actions.sortSection": "Abschnitt einordnen",
    "roomBook.lineItems.actions.linkToCatalogProductGroup": "Produktgruppe im Katalog öffnen",
    "roomBook.lineItems.actions.linkToCatalogProduct": "Ausgewähltes Produkt im Katalog öffnen",
    "roomBook.lineItems.actions.paste": "Hier einfügen",
    "roomBook.lineItems.actions.changeProduct": "Auswahl ändern",
    "roomBook.lineItems.actions.copyId": "ID in die Zwischenablage kopieren",
    "roomBook.lineItems.actions.copyIdShort": "ID",
    "roomBook.lineItems.actions.removeDialog.title": "Position löschen",
    "roomBook.lineItems.actions.removeDialog.message": "Wollen Sie die Position wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.lineItems.actions.unlinkDialog.title": "Position aus dem Vorgang entfernen",
    "roomBook.lineItems.actions.unlinkDialog.message": "Wollen Sie die Position wirklich aus dem Vorgang entfernen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.lineItems.actions.unlinkDialog.messageStarted": "Durch das entfernen der Position wird der Leistungsumfang eines laufenden Vorgangs geändert. Ggf. sollten Sie beteiligt Parteien darüber informieren.<br/><br/>Wollen Sie die Position wirklich aus dem Vorgang entfernen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.lineItems.actions.cartSelection.change.label": "Vorgang ändern",
    "roomBook.lineItems.actions.cartSelection.remove.label": "Vorgang entfernen",
    "roomBook.lineItems.actions.cartSelection.suggestion.pending": "Weiter mit:",
    "roomBook.lineItems.actions.cartSelection.suggestion.active": "Offene Vorgänge",
    "roomBook.lineItems.actions.cartSelection.activeToggle.show": "Alle Vorgänge anzeigen",
    "roomBook.lineItems.actions.cartSelection.activeToggle.hide": "Nur offene Vorgänge anzeigen",
    "roomBook.lineItems.actions.cartSelection.furtherOptions.label": "Weitere Optionen",
    "roomBook.lineItems.actions.cartSelection.pendingOptions.label": "Laufende Vorgänge",
    "roomBook.lineItems.actions.cancellationDialog.title": "Beauftragte Position stornieren",
    "roomBook.lineItems.actions.cancellationDialog.message01": "Diese Position wurde bereits beauftragt. Eine Stornierung muss durch den Erwerber beauftragt und ggf. im Anschluss an das Nachunternehmen kommuniziert werden.",
    "roomBook.lineItems.actions.cancellationDialog.message02": "Bitte wählen Sie aus, mit welchem Ziel die Stornierung durchgeführt werden soll:",
    "roomBook.lineItems.actions.cancellationDialog.message03": "Bitte wählen Sie aus, wie das Nachunternehmen über die Stornierung informiert werden soll:",
    "roomBook.lineItems.actions.cancellationDialog.gotoPosition.hint": "Die Stornoposition wurde erfolgreich angelegt",
    "roomBook.lineItems.actions.cancellationDialog.gotoPosition.label": "Zur Stornoposition",
    "roomBook.lineItems.messages.header": "Achtung!",
    "roomBook.lineItems.messages.warningsPresent": "Folgende Warnungen liegen vor",
    "roomBook.lineItems.messages.confirmChanges": "Möchten Sie trotzdem mit den Änderungen fortfahren?",
    "roomBook.lineItems.messages.activityAlreadyStarted": "Der Vorgang zu dem diese Position gehört wurde bereits gestartet.",
    "roomBook.lineItems.messages.warningRemoveFromActiveActivity": "Positionen können nur in einem der folgenden Arbeitsschritten: {states}; entfernt werden. Bitte kehren Sie in einen dieser Arbeitsschritte zurück, entfernen die Positionen und informieren Sie bei Bedarf die Beteiligten.",
    "roomBook.lineItems.messages.warningAssignToActiveActivity": "Der Vorgang wurde bereits gestartet. Sind Sie sicher, dass Sie die Position dem Vorgang hinzufügen wollen?",
    "roomBook.lineItems.messages.changeOfCosts": "Sie haben für diese Produktauswahl bereits Kosten hinterlegt, diese werden beim Wechsel des Produkts überschrieben.",
    "roomBook.lineItems.messages.changeOfDefaultCosts": "Sie haben für das Standardprodukt bereits Kosten hinterlegt, diese werden beim Wechsel des Standardprodukts überschrieben.",
    "roomBook.lineItems.messages.changeOfPrice": "Der Preis der aktuellen Produktauswahl wurde abweichend vom Ausstattungskatalog festgesetzt. Der Preis wird beim Produktwechsel durch den Preis der neuen Produktauswahl überschrieben.",
    "roomBook.lineItems.messages.changeOfDefaultPrice": "Der Preis der aktuellen Standardauswahl wurde abweichend vom Ausstattungskatalog festgesetzt. Der Preis wird beim Produktwechsel durch den Preis der neuen Standardauswahl überschrieben.",
    "roomBook.lineItems.messages.productPriceDiversion": "Der Preis der aktuellen Produktauswahl weicht vom aktuellen Ausstattungskatalog ab.",
    "roomBook.lineItems.messages.defaultProductPriceDiversion": "Der Preis der aktuellen Standardauswahl weicht vom aktuellen Ausstattungskatalog ab.",
    "roomBook.lineItems.messages.defaultExcessPriceDiversion": "Der Preis für Mehrmengen weicht vom aktuellen Ausstattungskatalog ab.",
    "roomBook.lineItems.messages.quantityUnitVariableDiversion": "Die Menge der Kundenauswahl weicht von der hinterlegten Menge für die Wohnung ab.",
    "roomBook.lineItems.messages.defaultQuantityUnitVariableDiversion": "Die Menge der Standardauswahl weicht von der hinterlegten Menge für die Wohnung ab.",
    "roomBook.lineItems.messages.costsDiversion": "Die Kosten der Kundenauswahl weichen vom aktuellen Ausstattungskatalog ab.",
    "roomBook.lineItems.messages.defaultCostsDiversion": "Die Kosten der Standardauswahl weichen vom aktuellen Ausstattungskatalog ab.",
    "roomBook.lineItems.messages.unremovableDueToProgress": "Die Abwicklung der Position wurde bereits mit einem anderen Vorgang fortgesetzt. Daher kann die Position aus diesem Vorgang nicht mehr entfernt werden.",
    "roomBook.lineItems.messages.skipContractorCancellationInfo": "Hinweis: Die Stornopositionen werden im Nachunternehmerauftrag nicht ausgewiesen.",
    "roomBook.lineItems.messages.priseCostDiverged": "Achtung: Kosten oder Preise weichen vom Ausstattungskatalog ab.",
    "roomBook.lineItems.messages.unitVariableValue": "",
    "roomBook.lineItems.messages.priceOnRequest.title": "Preis auf Anfrage",
    "roomBook.lineItems.messages.priceOnRequest.body": "Für diese Produktauswahl liegt aktuell kein Preis vor.",
    "roomBook.lineItems.messages.quantityOnRequest.title": "Mengenermittlung ggf. erforderlich",
    "roomBook.lineItems.messages.quantityOnRequest.body": "Bitte ermitteln Sie die Menge, wenn es sich um ein aufpreispflichtiges Produkt handelt.",
    "roomBook.lineItems.messages.trades.label": "Gewerke",
    "roomBook.lineItems.messages.selectInitialTrades.label": "Wählen Sie das Gewerk für den Individualwunsch aus",
    "roomBook.lineItems.messages.selectInitialTrades.validation": "Bitte wählen Sie mindestens ein Gewerk!",
    "roomBook.lineItems.wizard.header": "Neue Position",
    "roomBook.lineItems.wizard.subheaders.productSelection": "Bitte wählen Sie das Produkt aus, das in der Wohnung verbaut werden soll.",
    "roomBook.lineItems.wizard.subheaders.modeSelection": "Wenn durch diese Position eine Standardleistung der Baubeschreibung ersetzt wird, verrechnet das System die Preise für Sie automatisch. Wenn nicht, wird die Position als zusätzliche Leistung berechnet.",
    "roomBook.lineItems.wizard.subheaders.defaultProductSelection": "Bitte wählen Sie das Produkt aus, das laut Baubeschreibung verbaut werden sollte.",
    "roomBook.lineItems.wizard.subheaders.adhoc.stepTrade": "Bitte wählen Sie das Gewerk für den Individualwunsch",
    "roomBook.lineItems.wizard.subheaders.adhoc.stepProductGroup": "Bitte wählen Sie die Produktgruppe für den Individualwunsch",
    "roomBook.lineItems.wizard.subheaders.adhoc.stepProductGroupHint": "Wenn der Individualwunsch technisch zu einer bestehenden Produktgruppe gehört, wählen Sie diese bitte aus. Andernfalls können Sie diesen Schritt einfach überspringen.",
    "roomBook.lineItems.wizard.subheaders.adhoc.stepAdhoc": "Bitte beschreiben Sie den Individualwunsch",
    "roomBook.lineItems.wizard.subheaders.adhoc.stepAdhocHint": "Bitte geben Sie die Beschreibung und die weiteren Parameter für den Individualwunsch ein",
    "roomBook.lineItems.wizard.labels.modeSelection": "Wird eine Standardleistung ersetzt?",
    "roomBook.lineItems.wizard.labels.quantity": "Menge Kundenwunsch",
    "roomBook.lineItems.wizard.labels.defaultQuantity": "Menge laut Baubeschreibung",
    "roomBook.lineItems.wizard.messages.productSelection.header": "Es wurde kein Produkt ausgewählt!",
    "roomBook.lineItems.wizard.messages.productSelection.body": "Bitte wählen Sie ein Produkt aus, um fortzufahren.",
    "roomBook.lineItems.wizard.messages.modeSelection.header": "Es wurde keine Option ausgewählt!",
    "roomBook.lineItems.wizard.messages.modeSelection.body": "Bitte wählen Sie eine Option aus, um fortzufahren.",
    "roomBook.lineItems.wizard.messages.defaultProductSelection.header": "Es wurde kein Standardprodukt ausgewählt!",
    "roomBook.lineItems.wizard.messages.defaultProductSelection.body": "Bitte wählen Sie ein Standardprodukt aus, um fortzufahren.",
    "roomBook.lineItems.wizard.messages.quantity.header": "Es wurde keine zulässige Menge angegeben!",
    "roomBook.lineItems.wizard.messages.quantity.body": "Bitte geben Sie eine positive Menge an, um fortzufahren.",
    "roomBook.lineItems.wizard.buttons.cancelation": "Abbrechen",
    "roomBook.lineItems.wizard.buttons.next": "Weiter",
    "roomBook.lineItems.wizard.buttons.done": "Fertig",
    "roomBook.lineItems.wizard.buttons.positive": "Ja",
    "roomBook.lineItems.wizard.buttons.negative": "Nein",
    "roomBook.lineItems.wizard.buttons.skipProductGroup": "Überspringen",
    "roomBook.lineItems.filterByTrade": "Nach Gewerk filtern",
    "roomBook.carts.status.pending": "entschieden",
    "roomBook.carts.actions.hideDetails": "Details ausblenden",
    "roomBook.carts.actions.showDetails": "Details anzeigen",
    "roomBook.carts.actions.beginActivity": "Vorgang starten",
    "roomBook.carts.actions.export.label": "Herunterladen als...",
    "roomBook.carts.actions.removeDialog.title": "Freigabe löschen",
    "roomBook.carts.actions.removeDialog.message": "Positionen dieser Freigabe werden auf den Status \"offen\" zurückgesetzt. Wollen Sie die Freigabe wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.carts.actions.undeletableDialog.title": "Vorgang nicht löschbar",
    "roomBook.carts.actions.undeletableDialog.message": "Der Vorgang wurde bereits begonnen und kann nicht mehr gelöscht werden.",
    "roomBook.carts.tableHeaders.costs.net": "Mehrkosten NU (netto)",
    "roomBook.carts.tableHeaders.costs.gross": "Mehrkosten NU (brutto)",
    "roomBook.carts.tableHeaders.price.net": "Mehrkosten EW (netto)",
    "roomBook.carts.tableHeaders.price.gross": "Mehrkosten EW (brutto)",
    "roomBook.actions.goto": "Zum Raumbuch",
    "roomBook.actions.goToActivity": "Zum Vorgang",
    "roomBook.actions.add": "Neues Raumbuch",
    "roomBook.actions.addEmptyBook": "Leeres Raumbuch anlegen",
    "roomBook.actions.newCart": "Vorgang anlegen",
    "roomBook.actions.addCustomLineItem": "Individualwunsch anlegen",
    "roomBook.actions.addCustomProduct": "Wunschprodukt anlegen",
    "roomBook.actions.clearProductStatus": "Entscheidung offen",
    "roomBook.actions.chooseCart": "Produkt zu Vorgang hinzufügen:",
    "roomBook.actions.useTemplate": "Raumbuchvorlage verwenden:",
    "roomBook.actions.addTemplate": "Neue Raumbuchvorlage",
    "roomBook.actions.addPrimarySection": "Neuer Hauptabschnitt",
    "roomBook.actions.editPrimarySection": "Hauptabschnitt bearbeiten",
    "roomBook.actions.addSecondarySection": "Neuer Teilabschnitt",
    "roomBook.actions.editSecondarySection": "Teilabschnitt bearbeiten",
    "roomBook.actions.addLineItem": "Neue Position",
    "roomBook.actions.addCostItem": "Neue Kostenposition",
    "roomBook.actions.editLineItem": "Position bearbeiten",
    "roomBook.actions.deleteLineItem": "Position löschen",
    "roomBook.actions.sortOrder": "Sortierung",
    "roomBook.actions.moveToOtherSection": "\"{title}\" in folgenden Abschnitt verschieben:",
    "roomBook.actions.collapseSection": "Zuklappen",
    "roomBook.actions.collapseAllConfirmedSection": "Alle offenen Positionen anzeigen",
    "roomBook.actions.expandSection": "Aufklappen",
    "roomBook.actions.showDeprecated": "Ausgemusterte Produkte anzeigen",
    "roomBook.actions.hideDeprecated": "Ausgemusterte Produkte ausblenden",
    "roomBook.actions.advancedCreatorDialog.selectSource": "Bitte wählen Sie die Vorlage oder Quell-Raumbücher aus:",
    "roomBook.actions.advancedCreatorDialog.hint": "Wenn Sie mehrere Raumbücher auswählen, werden die Teilabschnitte aus allen Raumbüchern in das neue Raumbuch kopiert. Hauptabschnitte werden zusammengefasst, sofern möglich.",
    "roomBook.actions.purgeDialog.title": "Raumbuch löschen",
    "roomBook.actions.purgeDialog.message": "Wollen Sie das Raumbuch wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.actions.archiveDialog.title": "Raumbuch löschen",
    "roomBook.actions.archiveDialog.message": "Wollen Sie das Raumbuch wirklich löschen? Diese Aktion kann nur durch den Kundensupport rückgängig gemacht werden.",
    "roomBook.actions.resetDialog.title": "Raumbuch zurücksetzen",
    "roomBook.actions.resetDialog.message": "Wollen Sie das Raumbuch wirklich zurücksetzen? Es werden alle Positionen zurück in die Standardauswahl versetzt. Vorgänge werden gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.actions.resetDialog.htmlMessage": "<p>Das Raumbuch wird in seinen Ausgangszustand versetzt:</p> <ul> <li>Zusätzlichen Positionen werden gelöscht</li> <li>Produktauswahl und Mengen werden übernommen</li> <li>Vorgänge werden gelöscht</li> <li>Erwerberauswahlen werden gelöscht</li> </ul> <p><strong>!! Diese Aktion kann nicht rückgängig gemacht werden !!</strong></p>",
    "roomBook.actions.removeContent.title": "Auswahl löschen",
    "roomBook.actions.removeContent.message": "Wollen Sie die ausgewählten Abschnitte / Positionen wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.actions.deleteWithDerived.section.title": "Abschnitt löschen",
    "roomBook.actions.deleteWithDerived.section.summary": "Entfernen Sie diesen Abschnitt aus dem Raumbuch",
    "roomBook.actions.deleteWithDerived.section.derived_info": "{\n  derived_count, plural,\n  one{Beim Löschen wird auch in einem abgeleiteten Raumbuch der entsprechende Abschnitt mit gelöscht:}\n  other{Beim Löschen wird auch in # abgeleiteten Raumbüchern der entsprechende Abschnitt mit gelöscht:}\n}\n",
    "roomBook.actions.deleteWithDerived.item.title": "Position löschen",
    "roomBook.actions.deleteWithDerived.item.summary": "Entfernen Sie diese Position aus dem Raumbuch",
    "roomBook.actions.deleteWithDerived.item.derived_info": "{\n  derived_count, plural,\n  =0{Es sind keine Positionen in abgeleiteten Raumbüchern vorhanden.}\n  one{Beim Löschen wird auch in einem abgeleiteten Raumbuch die entsprechende Position mit gelöscht:}\n  other{Beim Löschen wird auch in # abgeleiteten Raumbüchern die entsprechende Position mit gelöscht:}\n}\n",
    "roomBook.actions.undeletableDialog.title": "Raumbuch nicht löschbar",
    "roomBook.actions.undeletableDialog.message": "Vorgänge wurden für dieses Raumbuch bereits begonnen. Das Raumbuch kann nicht mehr gelöscht werden.",
    "roomBook.actions.undeletableTemplateDialog.title": "Raumbuchvorlage nicht löschbar",
    "roomBook.actions.undeletableTemplateDialog.message": "Es wurden bereits Raumbücher aus dieser Vorlage erstellt. Diese Vorlage kann nicht mehr gelöscht werden.",
    "roomBook.actions.export.label": "Herunterladen als...",
    "roomBook.actions.discardBuyerSelectionDialog.title": "Erwerberauswahl verwerfen",
    "roomBook.actions.discardBuyerSelectionDialog.message": "Wollen Sie die Erwerberauswahl wirklich verwerfen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.actions.assignmentDialog.title": "Vorgangszuordnung",
    "roomBook.actions.assignmentDialog.attributes.cart_id.label": "Vorgang",
    "roomBook.actions.assignmentDialog.attributes.trades.label": "Gewerke",
    "roomBook.actions.addCostItemWarning.title": "Fehler",
    "roomBook.actions.addCostItemWarning.body": "Bitte erstellen Sie eine neue Kostenposition.",
    "roomBook.attachments.title": "Dokumente",
    "roomBook.projectQuestions.title": "Projekt FAQ",
    "roomBook.projectQuestions.description": "Hier können Sie häufig gestellte Fragen zu Ihrem Projekt hinterlegen. Den Erwerbern stehen diese im Erwerberportal zur Verfügung.",
    "roomBook.projectQuestions.add": "Frage hinzufügen",
    "roomBook.projectQuestions.dialog.createTitle": "Frage anlegen",
    "roomBook.projectQuestions.dialog.editTitle": "Frage bearbeiten",
    "roomBook.projectQuestions.dialog.question": "Frage",
    "roomBook.projectQuestions.dialog.answer": "Antwort",
    "roomBook.projectQuestions.dialog.projectQuestionGroupId": "Fragengruppe",
    "roomBook.projectQuestions.dialog.publishedBp": "Veröffentlicht im Erwerberportal",
    "roomBook.projectQuestions.dialog.publishedMp": "Veröffentlicht im Marketingportal",
    "roomBook.projectQuestions.dialog.position": "Position",
    "roomBook.projectQuestions.removeConfirmation.title": "Frage löschen",
    "roomBook.projectQuestions.removeConfirmation.message": "Wollen Sie die Frage wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.projectQuestionGroups.dialog.createTitle": "Fragegruppe anlegen",
    "roomBook.projectQuestionGroups.dialog.editTitle": "Fragengruppe bearbeiten",
    "roomBook.projectQuestionGroups.dialog.name": "Name",
    "roomBook.projectQuestionGroups.dialog.title": "Anzeigename (Konfigurator)",
    "roomBook.projectQuestionGroups.dialog.position": "Position",
    "roomBook.projectQuestionGroups.dialog.summary": "Zusammenfassung",
    "roomBook.projectQuestionGroups.removeConfirmation.title": "Fragegruppe löschen",
    "roomBook.projectQuestionGroups.removeConfirmation.message": "Wollen Sie die Fragegruppe wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.upgradeBundles.title": "Upgrade Pakete",
    "roomBook.upgradeBundles.description": "Upgrade Pakete stellen größere Erweiterungen/Umbauten an der Wohnung dar, umfassen mehrere Position und können Wohnungsabhängig zusammengestellt werden.",
    "roomBook.upgradeBundles.notFound": "Es liegen keine Upgrade Pakete zu diesem Projekt vor",
    "roomBook.upgradeBundles.notFoundInUnit": "Es liegen keine Upgrade Pakete zu dieser Wohneinheit vor",
    "roomBook.upgradeBundles.notFoundInGroup": "Es liegen keine Upgrade Pakete zu dieser Gruppe vor",
    "roomBook.upgradeBundles.noImage": "Kein Bild",
    "roomBook.upgradeBundles.add": "Upgrade Paket anlegen",
    "roomBook.upgradeBundles.dialog.createTitle": "Upgrade Paket anlegen",
    "roomBook.upgradeBundles.dialog.editTitle": "Upgrade Paket bearbeiten",
    "roomBook.upgradeBundles.dialog.name": "Name",
    "roomBook.upgradeBundles.dialog.titleField": "Anzeigename (Konfigurator)",
    "roomBook.upgradeBundles.dialog.description": "Beschreibung",
    "roomBook.upgradeBundles.dialog.image": "Bild",
    "roomBook.upgradeBundles.dialog.upgrade_bundle_group_id": "Gruppe (z.B. Dachgeschoss, Keller)",
    "roomBook.upgradeBundles.dialog.defaultDummy": "Standardplatzhalter",
    "roomBook.upgradeBundles.dialog.summary": "Zusammenfassung",
    "roomBook.upgradeBundles.dialog.portalOffer": "Verfügbar im Online Konfigurator",
    "roomBook.upgradeBundles.dialog.configurationLink": "Konfigurations Verlinkung",
    "roomBook.upgradeBundles.dialog.upgradeCode": "Upgrade Code",
    "roomBook.upgradeBundles.dialog.externalId": "Externe ID",
    "roomBook.upgradeBundles.removeConfirmation.title": "Upgrade Paket löschen",
    "roomBook.upgradeBundles.removeConfirmation.message": "Wollen Sie das Upgrade Paket wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.upgradeBundles.guard.conflict": "Achtung, der Erwerber hat ein anderes Upgradepaket gewählt!",
    "roomBook.upgradeBundles.guard.preference": "Der Erwerber wünscht dieses Upgradepaket!",
    "roomBook.upgreadeBundleGroups.title": "Die Liste der Upgradesgruppe",
    "roomBook.upgreadeBundleGroups.notFound": "Es liegen keine Upgrade Pakete Gruppe zu diesem Projekt vor",
    "roomBook.upgreadeBundleGroups.add": "Gruppe anlegen",
    "roomBook.upgreadeBundleGroups.error.title": "Error",
    "roomBook.upgreadeBundleGroups.error.body": "Upgrade Pakete Gruppe nicht angelegt",
    "roomBook.upgreadeBundleGroups.dialog.createTitle": "Upgrade Paket Gruppe anlegen",
    "roomBook.upgreadeBundleGroups.dialog.editTitle": "Upgrade Paket Gruppe bearbeiten",
    "roomBook.upgreadeBundleGroups.dialog.name": "Name",
    "roomBook.upgreadeBundleGroups.dialog.titleField": "Anzeigename (Konfigurator)",
    "roomBook.upgreadeBundleGroups.dialog.mode": "Modus",
    "roomBook.upgreadeBundleGroups.dialog.singleSelect": "Einfachauswahl",
    "roomBook.upgreadeBundleGroups.dialog.multiSelect": "Mehrfachauswahl",
    "roomBook.upgreadeBundleGroups.dialog.actsAsProductGroup": "Verhalten wie Produktauswahl",
    "roomBook.upgreadeBundleGroups.dialog.actsAsPreselection": "Upgrade Pakete dienen der Vorauswahl von Produkttypen im Konfigurator",
    "roomBook.upgreadeBundleGroups.dialog.dynamicCalculation": "Dynamische Berechnung (Beta)",
    "roomBook.upgreadeBundleGroups.dialog.configurationLink": "Konfigurations Verlinkung",
    "roomBook.upgreadeBundleGroups.dialog.upgradeCode": "Upgrade Code",
    "roomBook.upgreadeBundleGroups.dialog.externalId": "Externe ID",
    "roomBook.roomBookUpgradeBundles.dialog.title": "Upgrades",
    "roomBook.roomBookUpgradeBundles.dialog.adjustPriceTitle": "Basispreis anpassen",
    "roomBook.roomBookUpgradeBundles.dialog.subtitle": "Die Liste der dem Raumbuch zugeordneten Upgrades",
    "roomBook.roomBookUpgradeBundles.dialog.summary": "Die Basispreise der Upgrades werden aus den zugewiesenen Positionen und der dort ausgewählten Standardauswahl errechnet. Der Gesamtpreis kann aber durch zusätzliche Auswahl der Erwerbers höher liegen. Der Basispreis kann angepasst werden. Der Aufschlag wird proportional auf die Teilleistungen verteilt.",
    "roomBook.roomBookUpgradeBundles.dialog.table.name": "Name",
    "roomBook.roomBookUpgradeBundles.dialog.table.basePriceCalculated": "Kalkulierter Basispreis",
    "roomBook.roomBookUpgradeBundles.dialog.table.basePriceSurcharge": "Aufpreis Basispreis",
    "roomBook.roomBookUpgradeBundles.dialog.table.basePriceFinal": "Finaler Basispreis",
    "roomBook.roomBookUpgradeBundles.dialog.table.total": "Aktueller Gesamtpreis",
    "roomBook.roomBookUpgradeBundles.dialog.table.status": "Status",
    "roomBook.roomBookUpgradeBundles.dialog.status.activated": "aktiviert",
    "roomBook.roomBookUpgradeBundles.dialog.status.deactivated": "deaktiviert",
    "roomBook.unitFeatureGroups.title": "Merkmale",
    "roomBook.unitFeatureGroups.notFound": "Es liegen keine Merkmale zu diesem Projekt vor",
    "roomBook.unitFeatureGroups.tab": "Merkmale",
    "roomBook.unitFeatureGroups.filter": "Wohneinheit",
    "roomBook.unitFeatureGroups.empty": "Es gibt keine Merkmale",
    "roomBook.unitFeatureGroups.enableMessage": "Filter entfernen, um die Erstellung zu ermöglichen",
    "roomBook.unitFeatureGroups.removeConfirmation.title": "Merkmalgruppe löschen",
    "roomBook.unitFeatureGroups.removeConfirmation.message": "Wollen Sie die Merkmalgruppe wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.unitFeatureGroups.tableHeader.unit": "Wohneinheit",
    "roomBook.unitFeatureGroups.tableHeader.title": "Titel",
    "roomBook.unitFeatureGroups.tableHeader.name": "Name",
    "roomBook.unitFeatureGroups.tableHeader.action": "Aktion",
    "roomBook.unitFeatureGroups.dialog.title": "Merkmalegruppe",
    "roomBook.unitFeatureGroups.dialog.unit": "Wohneinheit",
    "roomBook.unitFeatureGroups.dialog.type": "Merkmalegruppen",
    "roomBook.unitFeatureGroups.success.title": "Gelöscht",
    "roomBook.unitFeatureGroups.success.message": "Merkmalgruppe erfolgreich gelöscht",
    "roomBook.unitFeatureGroups.types.title": "Merkmale",
    "roomBook.unitFeatureGroups.types.tab": "Merkmale",
    "roomBook.unitFeatureGroups.types.filter": "Merkmale",
    "roomBook.unitFeatureGroups.types.importDefaults.tooltip": "Merkmale importieren",
    "roomBook.unitFeatureGroups.types.importDefaults.confirmationDialog.title": "Merkmale importieren",
    "roomBook.unitFeatureGroups.types.importDefaults.confirmationDialog.message": "Wollen Sie die Merkmale wirklich importieren?",
    "roomBook.unitFeatureGroups.types.importDefaults.success.title": "Importiert",
    "roomBook.unitFeatureGroups.types.importDefaults.success.message": "Merkmale erfolgreich importiert",
    "roomBook.unitFeatureGroups.types.removeConfirmation.title": "Merkmale löschen",
    "roomBook.unitFeatureGroups.types.removeConfirmation.message": "Wollen Sie die Merkmale wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.unitFeatureGroups.types.dialog.createTitle": "Merkmalgruppe anlegen",
    "roomBook.unitFeatureGroups.types.dialog.editTitle": "Merkmalgruppe bearbeiten",
    "roomBook.unitFeatureGroups.types.dialog.titleField": "Anzeigename (Konfigurator)",
    "roomBook.unitFeatureGroups.types.dialog.name": "Name",
    "roomBook.unitFeatureGroups.types.dialog.description": "Beschreibung",
    "roomBook.unitFeatureGroups.types.dialog.action": "Aktion",
    "roomBook.unitFeatures.add": "Hinzufügen",
    "roomBook.unitFeatures.notFoundInGroup": "Es liegen keine Merkmale zu dieser Gruppe vor",
    "roomBook.unitFeatures.dialog.createTitle": "Merkmal anlegen",
    "roomBook.unitFeatures.dialog.editTitle": "Merkmal bearbeiten",
    "roomBook.unitFeatures.dialog.titleField": "Anzeigename (Konfigurator)",
    "roomBook.unitFeatures.dialog.name": "Name",
    "roomBook.unitFeatures.dialog.description": "Beschreibung",
    "roomBook.unitFeatures.dialog.icon": "Icon",
    "roomBook.unitFeatures.dialog.marketingFeature": "Vertriebsmerkmal",
    "roomBook.unitFeatures.dialog.marketingLabel": "Anzeigename (Vertriebskonfigurator)",
    "roomBook.unitFeatures.dialog.marketingDescription": "Beschreibung (Vertriebskonfigurator)",
    "roomBook.unitFeatures.dialog.unitFeatureGroup": "Gruppe",
    "roomBook.unitFeatures.dialog.action": "Aktion",
    "roomBook.unitFeatures.tab.title": "Merkmale",
    "roomBook.unitFeatures.tab.uploadTooltip": "Merkmale hochladen",
    "roomBook.unitVariables.title": "Mengen",
    "roomBook.unitVariables.notFound": "Es liegen keine Unit variable types zu diesem Projekt vor",
    "roomBook.unitVariables.tab": "Mengen",
    "roomBook.unitVariables.filter": "Wohneinheit",
    "roomBook.unitVariables.empty": "Es gibt keine Mengen",
    "roomBook.unitVariables.enableMessage": "Filter entfernen, um die Erstellung zu ermöglichen",
    "roomBook.unitVariables.removeConfirmation.title": "Menge löschen",
    "roomBook.unitVariables.removeConfirmation.message": "Wollen Sie die Menge wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.unitVariables.tableHeader.unit": "Wohneinheit",
    "roomBook.unitVariables.tableHeader.identifier": "Mengenvariable",
    "roomBook.unitVariables.tableHeader.name": "Name",
    "roomBook.unitVariables.tableHeader.value": "Wert",
    "roomBook.unitVariables.tableHeader.action": "Aktion",
    "roomBook.unitVariables.tableHeader.active": "Aktiv",
    "roomBook.unitVariables.dialog.title": "Menge",
    "roomBook.unitVariables.dialog.unit": "Wohneinheit",
    "roomBook.unitVariables.dialog.type": "Mengevariable",
    "roomBook.unitVariables.dialog.value": "Wert",
    "roomBook.unitVariables.dialog.default": "Default",
    "roomBook.unitVariables.dialog.global": "Global",
    "roomBook.unitVariables.success.title": "Gelöscht",
    "roomBook.unitVariables.success.message": "Menge erfolgreich gelöscht",
    "roomBook.unitVariables.types.title": "Mengenvariablen",
    "roomBook.unitVariables.types.tab": "Mengenvariablen",
    "roomBook.unitVariables.types.filter": "Mengenvariable",
    "roomBook.unitVariables.types.importDefaults.tooltip": "Standardwerte importieren",
    "roomBook.unitVariables.types.importDefaults.confirmationDialog.title": "Mengenvariablen importieren",
    "roomBook.unitVariables.types.importDefaults.confirmationDialog.message": "Wollen Sie die standard Mengenvariablen wirklich import. Die Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.unitVariables.types.importDefaults.success.title": "Importiert",
    "roomBook.unitVariables.types.importDefaults.success.message": "Mengenvariablen erfolgreich importiert",
    "roomBook.unitVariables.types.removeConfirmation.title": "Mengenvariable löschen",
    "roomBook.unitVariables.types.removeConfirmation.message": "Wollen Sie die Mengenvariable wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.unitVariables.types.dialog.title": "Mengenvariable",
    "roomBook.unitVariables.types.dialog.identifier": "Mengenvariable",
    "roomBook.unitVariables.types.dialog.name": "Name",
    "roomBook.unitVariables.types.dialog.description": "Beschreibung",
    "roomBook.unitVariables.types.dialog.action": "Aktion",
    "roomBook.configurationGroups.success.title": "Gelöscht",
    "roomBook.configurationGroups.success.message": "Positionsgruppe erfolgreich gelöscht",
    "roomBook.configurationGroups.title": "Positionsgruppen",
    "roomBook.configurationGroups.tab": "Positionsgruppen",
    "roomBook.configurationGroups.filter": "Positionsgruppen",
    "roomBook.configurationGroups.empty": "Es gibt keine Positionsgruppen",
    "roomBook.configurationGroups.enableMessage": "Filter entfernen, um die Erstellung zu ermöglichen",
    "roomBook.configurationGroups.notFound": "Es liegen keine Positionsgruppen zu diesem Projekt vor",
    "roomBook.configurationGroups.removeConfirmation.title": "Positionsgruppe löschen",
    "roomBook.configurationGroups.removeConfirmation.message": "Wollen Sie die Positionsgruppe wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "roomBook.configurationGroups.tableHeader.identifier": "Positionsgruppe",
    "roomBook.configurationGroups.tableHeader.name": "Name",
    "roomBook.configurationGroups.tableHeader.action": "Aktion",
    "roomBook.configurationGroups.dialog.title": "Anzeigename (Konfigurator)",
    "roomBook.configurationGroups.dialog.summary": "Zusammenfassung",
    "roomBook.configurationGroups.dialog.name": "Name",
    "roomBook.configurationGroups.dialog.description": "Beschreibung",
    "roomBook.configurationGroups.dialog.action": "Aktion",
    "roomBook.configurationGroups.types.title": "Anzeigename (Konfigurator)",
    "roomBook.configurationGroups.types.summary": "Zusammenfassung",
    "roomBook.configurationGroups.types.name": "Name",
    "roomBook.configurationGroups.types.description": "Beschreibung",
    "roomBook.configurationGroups.types.action": "Aktion",
    "roomBook.configurationGroups.types.tab": "Konfigurationsgruppen",
    "roomBook.unitProspect.new": "Neu",
    "roomBook.unitProspect.active": "Aktiv",
    "roomBook.unitProspect.lost": "Verloren",
    "roomBook.unitProspect.won": "Gewonnen",
    "roomBook.unitProspect.prospect": "Interessenten",
    "roomBook.unitProspect.noProspectsFound": "Keine Interessenten",
    "roomBook.unitProspect.actions.download.tooltip": "Als Excel herunterladen",
    "roomBook.unitProspect.attributes.redirect_to_messages": "Nachricht",
    "roomBook.unitProspect.attributes.redirect_to_configuration_url": "Ausstattung",
    "roomBook.unitProspect.attributes.first_contact": "Erstkontakt",
    "roomBook.unitProspect.dialog.title": "Mengenvariable",
    "roomBook.unitProspect.dialog.prospectEdit": "Interesenten bearbeiten",
    "roomBook.unitProspect.dialog.email": "Email",
    "roomBook.unitProspect.dialog.editProspect": "Interesenten bearbeiten",
    "roomBook.unitProspect.dialog.notes": "Notizen",
    "roomBook.unitProspect.dialog.personalData": "Personliche Daten",
    "roomBook.unitProspect.dialog.firstName": "Vorname",
    "roomBook.unitProspect.dialog.lastName": "Nachname",
    "roomBook.unitProspect.dialog.salutation": "Anrede",
    "roomBook.unitProspect.dialog.company": "Firma",
    "roomBook.unitProspect.dialog.phone": "Telefon",
    "roomBook.unitProspect.dialog.mobileNumber": "Mobilnummer",
    "roomBook.unitProspect.dialog.street": "Straße",
    "roomBook.unitProspect.dialog.houseNumber": "Hausnummer",
    "roomBook.unitProspect.dialog.postalCode": "Postleitzahl",
    "roomBook.unitProspect.dialog.location": "Ort",
    "roomBook.unitProspect.dialog.new_status": "Neuer Status",
    "roomBook.unitProspect.dialog.comment": "Notizen",
    "roomBook.unitProspect.dialog.add_comment": "Notiz hinzufügen",
    "roomBook.unitProspect.dialog.update_status_title": "Status ändern",
    "roomBook.unitProspect.dialog.change_status": "Ändern",
    "roomBook.unitProspect.dialog.requests": "Anfragen",
    "roomBook.unitProspect.dialog.emptyRequests": "Es gibt noch keine Anfragen.",
    "roomBook.unitProspect.moveDialog.won.title": "Wohneinheit verkauft",
    "roomBook.unitProspect.moveDialog.won.label": "Notizen",
    "roomBook.unitProspect.moveDialog.lost.title": "Prozess abgebrochen",
    "roomBook.unitProspect.moveDialog.lost.label": "Notizen",
    "roomBook.unitProspect.filter.accountManager": "Account Manager",
    "roomBook.unitProspect.filter.section": "Bauabschnitt",
    "roomBook.unitProspect.filter.unit": "Wohneinheit",
    "roomBook.unitProspect.filter.byText": "Schnellsuche...",
    "roomBook.derivedRoombooks.noRoombookTemplates": "Es gibt keine abgeleiteten Raumbuchvorlagen",
    "roomBook.derivedRoombooks.noRoombookUnits": "Diese Vorlage wurde noch in keinen Wohneinheiten genutzt",
    "roomBook.derivedRoombooks.tooltip": "Abgeleitete Raumbücher",
    "roomBook.derivedRoombooks.dialog.title": "Abgeleitete Raumbücher",
    "roomBook.derivedRoombooks.dialog.templates": "Raumbuchvorlagen",
    "roomBook.derivedRoombooks.dialog.units": "Wohneinheiten",
    "roomBook.copyTemplates.dialog.title": "Raumbuchvorlage importieren",
    "roomBook.copyTemplates.dialog.sourceProject": "Quellprojekt",
    "roomBook.copyTemplates.dialog.templates": "Raumbuchvorlagen",
    "buyer.title.zero": "Kein Erwerber",
    "buyer.title.one": "Erwerber",
    "buyer.title.other": "Erwerber",
    "buyer.attributes.firstName.label": "Vorname",
    "buyer.attributes.lastName.label": "Nachname",
    "buyer.attributes.displayName.label": "Anzeigename",
    "buyer.attributes.company.label": "Firma",
    "buyer.attributes.email.label": "E-Mail",
    "buyer.attributes.email.error": "Bitte geben Sie eine valide Email-Adresse an.",
    "buyer.attributes.phone.label": "Telefon",
    "buyer.attributes.mobile.label": "Mobilnummer",
    "buyer.attributes.mobile.error": "Bitte Nummer mit Ländervorwahl eingeben, z.B. +491560000000",
    "buyer.attributes.address_street.label": "Straße",
    "buyer.attributes.address_number.label": "Hausnummer",
    "buyer.attributes.address_postcode.label": "Postleitzahl",
    "buyer.attributes.address_town.label": "Ort",
    "buyer.attributes.salutation.label": "Anrede",
    "buyer.attributes.title.label": "Titel",
    "buyer.options.all": "Alle Erwerber",
    "buyer.options.not_invited": "Nicht eingeladen",
    "buyer.options.pending_invitation": "Einladung noch nicht angenommen",
    "buyer.options.accepted_invitation": "Einladung angenommen",
    "buyer.section_options.all": "Alle Bauabschnitte",
    "buyer.message.noneAssigned": "Es liegt noch kein Erwerber vor.",
    "buyer.upload.title": "Erwerber hochladen",
    "buyer.upload.upload_error": "Erwerber konnten nicht hochgeladen werden",
    "buyer.upload.select_file": "Wählen Sie bitte eine Datei aus",
    "buyer.actions.new": "Neuer Erwerber",
    "buyer.actions.edit": "Erwerber bearbeiten",
    "buyer.actions.download": "Erwerber herunterladen",
    "buyer.actions.upload": "Erwerber hochladen",
    "buyer.actions.removeDialog.title": "Erwerber löschen",
    "buyer.actions.removeDialog.message": "Wollen Sie den Erwerber wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "buyer.actions.undeletableDialog.title": "Erwerber nicht löschbar",
    "buyer.actions.undeletableDialog.message": "Der Erwerber ist bereits einer Wohneinheit zugeordnet und kann nicht gelöscht werden.",
    "buyer.actions.invitationDialog.title": "Einladung Online-Konfigurator",
    "buyer.actions.invitationDialog.message": "Laden Sie die Erwerber zum Online-Konfigurator ein. Die Einladung wird an die angegebene E-Mail Adresse gesendet.",
    "buyer.actions.invitationDialog.send": "Einladung senden",
    "buyer.actions.invitationDialog.resend": "Erneut senden",
    "buyer.actions.invitationDialog.not_found": "Noch keine Einladung gesendet.",
    "buyer.actions.invitationDialog.invitation_date_info.invited_days_ago": "Wurde am {invited_days_ago} eingeladen.",
    "buyer.actions.invitationDialog.invitation_date_info.created_at": "Eingeladen am {created_at}, erinnert am {reminded_at}.",
    "buyer.actions.invitationDialog.reminder.title": "Angaben im Raumbuch überprüfen",
    "buyer.actions.invitationDialog.reminder.body": "Vergewissern Sie sich bitte vor dem Absenden der Einladung, dass u.a. die folgenden Angaben im Raumbuch korrekt sind:",
    "buyer.actions.invitationDialog.reminder.list.quantities": "Mengen- und Flächenangaben",
    "buyer.actions.invitationDialog.reminder.list.sorting": "Abschnitte und Positionen",
    "buyer.actions.invitationDialog.reminder.list.default": "hinterlegte Standardprodukte",
    "buyer.actions.invitationDialog.error.title": "Es konnte keine Einladung gesendet werden",
    "buyer.actions.invitationDialog.error.message": "Möglicherweise ist diesem Erwerber keine Wohneinheit zugewiesen oder Sie verwenden eine E-Mail Adresse, die bereits einem Systembenutzer zugewiesen ist.",
    "projectStoreys.dialog.title": "Stockwerk",
    "projectStoreys.dialog.titlePlural": "Stockwerke",
    "projectStoreys.dialog.name": "Name",
    "projectStoreys.dialog.shortName": "Kurzname",
    "projectStoreys.actions.new": "Neues Stockwerk",
    "projectStoreys.actions.edit": "Stockwerk bearbeiten",
    "projectStoreys.confirmation.title": "Stockwerk löschen",
    "projectStoreys.confirmation.body": "Wollen Sie den Stockwerk wirklich löschen. Diese Aktion kann nicht rückgängig gemacht werden.",
    "projectStoreys.emptyList": "Noch keine Stockwerke vorhanden",
    "contractor.title.one": "Nachunternehmen",
    "contractor.title.other": "Nachunternehmen",
    "contractor.title.description": "Hier können Sie die Nachunternehmer für dieses Projekt nominieren. Diese werden bei Vorgängen automatisch vorselektiert.",
    "contractor.assignmentRule.removeConfirmation.title": "Removing Assignment Rule",
    "contractor.assignmentRule.removeConfirmation.message": "Do you really want to remove Assignment Rule?",
    "contractor.actions.new": "Neues Nachunternehmen",
    "contractor.actions.assign": "Nachunternehmen nominieren",
    "contractor.actions.edit": "Nachunternehmen bearbeiten",
    "contractor.actions.remove": "Nachunternehmen  entfernen",
    "contractor.actions.suggestion": "Vorschlag erhalten",
    "contractor.actions.removeDialog.title": "Nachunternehmen löschen",
    "contractor.actions.removeDialog.message": "Wollen dieses Nachunternehmen wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "contractor.actions.undeletableDialog.title": "Nachunternehmen nicht löschbar",
    "contractor.actions.undeletableDialog.message": "Es wurden bereits Vorgänge für dieses Nachunternehmen gestartet. Das Nachunternehmen kann nicht gelöscht werden.",
    "contractor.message.noneAssigned": "Es wurden noch keine Nachunternehmen hinzugefügt.",
    "contractor.message.required": "Ein Nachunternehmen des Gewerks ist erforderlich!",
    "contractor.message.noneAvaiable": "Es wurden noch keine Nachunternehmen für dieses Gewerk hinterlegt!",
    "contractor.message.missing": "Es wurden noch nicht alle erforderlichen Nachunternehmen nominiert!",
    "contractor.message.noContractors": "Es wurden noch keine Nachunternehmen nominiert",
    "contractor.attributes.name.label": "Name",
    "contractor.attributes.trades.label": "Gewerke",
    "contractor.attributes.trades.all": "Alle",
    "contractor.attributes.trades.suggestion": "Erforderliche Gewerke",
    "contractor.attributes.contactPerson.label": "Kontaktperson",
    "contractor.attributes.email.label": "E-Mail",
    "contractor.attributes.email.error": "Bitte geben Sie eine valide Email-Adresse an.",
    "contractor.attributes.phone.label": "Telefon",
    "contractor.attributes.fax.label": "Fax",
    "contractor.attributes.address_street.label": "Straße",
    "contractor.attributes.address_number.label": "Hausnummer",
    "contractor.attributes.address_postcode.label": "Postleitzahl",
    "contractor.attributes.address_town.label": "Ort",
    "contractor.attributes.profit_margin.label": "Aufschlag (%)",
    "contractor.attributes.profit_margin.placeholder": "z.B. 15, überschreibt Aufschlag für die Gewerke",
    "contractor.attributes.profit_margin.labelLong": "Aufschlag Kostenverrechnung",
    "contractor.attributes.cost_accounting_mode.label": "Kostenverrechnung",
    "contractor.attributes.cost_accounting_mode.values.NET_ACCOUNTING.label": "Netto",
    "contractor.attributes.cost_accounting_mode.values.GROSS_ACCOUNTING.label": "Brutto",
    "contractor.attributes.external_id.label": "Externe ID",
    "contractor.attributes.external_id.placeholder": "z.B. 12345",
    "contractor.attributes.notes.label": "Notizen",
    "contractor.dialog.assign.project.header": "Nachunternehmen zum Bauprojekt hinzufügen",
    "contractor.inquiry.greeting": "Guten Tag {name}",
    "contractor.inquiry.title": "{count, plural, one {Preisanfrage} other {Preisanfragen}}",
    "contractor.inquiry.submit": "Angebot für {count} / {total} Preise erstellen",
    "contractor.inquiry.import": "Nachunternehmer Angebot Importieren",
    "contractor.inquiry.all_set_now_quit": " <p>Vielen Dank für Ihre Eingabe.</p> <p>Die von Ihnen eingegebenen Preise für die Preisanfrage {activityDisplayName} wurden erfolgreich an {accountName} übermittelt.</p> <p>Sie können diese Seite nun verlassen.</p>",
    "contractor.inquiry.prices_missing": "Es wurden noch nicht alle Preise ausgefüllt. Die Preise können nach der Einwertung nicht mehr geändert werden. Sind Sie sich sicher {n} von {total} Preise einzuwerten?",
    "contractor.inquiry.continue": "Preise weiter ausfüllen",
    "contractor.inquiry.force_submit": "Preise trotzdem Einwerten",
    "contractor.inquiry.demo_view": "<p>Dies ist eine Vorschau, es können keine Angaben verändert werden.</p>",
    "contractor.inquiry.already_submitted": "<p>Die Preise wurden bereits abgesendet und können nicht mehr geändert werden.</p> <p>Sollten Sie dennoch eine Änderung vornehmen wollen, setzen Sie sich bitte mit {accountName} in Verbindung.</p>",
    "contractor.inquiry.import_inquiry.quantity": "Menge",
    "contractor.inquiry.import_inquiry.position_number": "#",
    "contractor.inquiry.import_inquiry.sub_line_item_title": "Titel",
    "contractor.inquiry.import_inquiry.contractor": "Nachunternehmen",
    "contractor.inquiry.import_inquiry.calculation": "Kalkulation",
    "contractor.inquiry.import_inquiry.margin": "Aufschlag",
    "contractor.inquiry.import_inquiry.margin_overwrite_all": "Alle Aufschläge überschreiben",
    "contractor.inquiry.import_inquiry.net_purchase_price": "Netto EK",
    "contractor.inquiry.import_inquiry.gross_retail_price": "Brutto VK",
    "contractor.inquiry.import_inquiry.net_retail_price": "Netto VK",
    "contractor.inquiry.import_inquiry.gross_total_sum": "Brutto Gesamt",
    "contractor.inquiry.import_inquiry.net_total_sum": "Netto Gesamt",
    "contractor.inquiry.import_inquiry.summary": "Beschreibung",
    "contractor.inquiry.import_inquiry.info_calculation": "Der Brutto Preis errechnet sich so: <p>Netto EK + Aufschlag in % + {tax}% MwSt. = Netto Gesamt * Menge = Brutto VK</p>",
    "projectQuantities.title": "Mengen",
    "projectQuantities.productGroups": "Produktgruppe / Produkt",
    "projectQuantities.quantity": "Menge",
    "projectQuantities.noProductsFound": "Keine Produkte gefunden",
    "projectQuantities.filter.trade.all": "Alle gewerke",
    "projectQuantities.filter.status.offered": "Angeboten",
    "projectQuantities.filter.status.ordered": "Beauftragt (Erwerber)",
    "projectQuantities.filter.status.all": "Alle Zustände",
    "projectQuantities.filter.status.contracted": "Beauftragt (Nachunternehmen)",
    "projectQuantities.filter.section.all": "Alle Bauabschnitt",
    "projectQuantities.filter.unitFeature.all": "Alle Merkmale",
    "projectQuantities.noUnitsFound": "Keine Einheiten gefunden",
    "contractorAssignmentRule.contractData": "Vertragsdaten",
    "contractorAssignmentRule.dialog.title": "Vertragsdaten",
    "contractorAssignmentRule.dialog.attributes.details": "Vertragsdetails",
    "contractorAssignmentRule.dialog.attributes.field_required": "Dieses Feld ist erforderlich",
    "contractorAssignmentRule.dialog.attributes.profitMargin.label": "Aufschlag",
    "contractorAssignmentRule.dialog.attributes.cashDiscountInPercent.label": "Rabatt",
    "contractorAssignmentRule.dialog.attributes.contractDate.label": "Vertragsdatum",
    "contractorAssignmentRule.dialog.attributes.contractDate.placeholder": "Datum des Vertragsschlusses",
    "contractorAssignmentRule.dialog.attributes.contractNegotiationDate.label": "Verhandlungsdatum",
    "contractorAssignmentRule.dialog.attributes.contractNegotiationDate.placeholder": "Datum der Verhandlung",
    "contractorAssignmentRule.dialog.attributes.contractNumber.label": "Vertragsnummer",
    "contractorAssignmentRule.dialog.attributes.contractNumber.placeholder": "Nummer des Vertrages",
    "contractorAssignmentRule.dialog.attributes.externalId.label": "Externe ID",
    "contractorAssignmentRule.dialog.attributes.externalId.placeholder": "Externe ID des Vertrags",
    "contractorAssignmentRule.dialog.attributes.contractorId.label": "Nachunternehmer",
    "contractorAssignmentRule.dialog.attributes.contractorId.placeholder": "Nachunternehmer",
    "contractorAssignmentRule.dialog.attributes.tradeId.label": "Gewerk",
    "contractorAssignmentRule.dialog.attributes.tradeId.placeholder": "Gewerk",
    "contractorAssignmentRule.dialog.attributes.unitId.label": "Wohneinheit",
    "contractorAssignmentRule.dialog.attributes.unitId.placeholder": "Wohneinheit",
    "contractorAssignmentRule.dialog.attributes.sectionId.label": "Bauabschnitt",
    "contractorAssignmentRule.dialog.attributes.sectionId.placeholder": "Bauabschnitt",
    "memo.attributes.recipients.label": "Kontakte",
    "memo.attributes.recipients.placeholder": "Kontakte",
    "email.integration": "E-Mail Integration",
    "email.template.one": "E-Mail Vorlage",
    "email.template.other": "E-Mail Vorlagen",
    "email.macro.one": "Standardemail",
    "email.macro.other": "Standardemails",
    "email.activity_section.title": "Vorgang starten",
    "email.activity_section.button.label": "+ Vorgang: {name}",
    "email.activity_section.dialog.title": "Vorgang starten",
    "email.activity_section.dialog.user": "Verantwortlicher",
    "email.activity_section.dialog.activity_title": "Vorgangsbezeichnung",
    "email.message_labels.add.title": "Label anlegen",
    "email.message_labels.add.success": "Label gespeichert",
    "email.message_labels.edit.title": "Lebel bearbeiten",
    "email.message_labels.edit.success": "Label gespeichert",
    "email.message_labels.delete.success": "Label gelöscht",
    "email.message_labels.name": "Name",
    "email.message_labels.color": "Farbe",
    "email.message_labels.list.title": "Labels",
    "email.message_labels.list.empty": "Noch keine Labels vorhanden",
    "email.message_labels.remove_confirmation.title": "Label löschen",
    "email.message_labels.remove_confirmation.message": "Wollen Sie das Label wirklich löschen?",
    "email.message_labels.select_message.added": "Label wurde hinzugefügt",
    "email.message_labels.select_message.removed": "Label wurde entfernt",
    "email.message_labels.colors.red": "Rot",
    "email.message_labels.colors.orange": "Orange",
    "email.message_labels.colors.yellow": "Gelb",
    "email.message_labels.colors.olive": "Olive",
    "email.message_labels.colors.green": "Grüne",
    "email.message_labels.colors.teal": "Teal",
    "email.message_labels.colors.blue": "Blau",
    "email.message_labels.colors.violet": "Violett",
    "email.message_labels.colors.purple": "Lila",
    "email.message_labels.colors.pink": "Rosa",
    "email.message_labels.colors.brown": "Brown",
    "email.message_labels.colors.grey": "Grau",
    "email.message_labels.colors.black": "Schwarz",
    "email.attributes.boilerplate.list": "Liste möglicher Bausteine",
    "email.attributes.boilerplate.key": "Baustein",
    "email.attributes.boilerplate.value": "Beispielwert",
    "email.attributes.project.label": "Projekt",
    "email.attributes.project.placeholder": "Projekt",
    "email.attributes.project.error": "Bitte ordnen Sie ein Projekt zu.",
    "email.attributes.recipients.label": "Empfänger",
    "email.attributes.recipients.placeholder": "Empfänger",
    "email.attributes.recipients.error": "Bitte geben Sie mindestens einen Empfänger an",
    "email.attributes.noRecipients.label": "Keine Empfänger",
    "email.attributes.from.label": "Von",
    "email.attributes.to.label": "An",
    "email.attributes.cc.label": "Cc",
    "email.attributes.bcc.label": "Bcc",
    "email.attributes.add_contact.label": "Neuer Kontakt",
    "email.attributes.unit.label": "Wohneinheit",
    "email.attributes.unit.placeholder": "Wohneinheit",
    "email.attributes.subject.label": "Betreff",
    "email.attributes.subject.placeholder": "Betreff",
    "email.attributes.subject.error": "Bitte geben Sie einen Betreff an",
    "email.attributes.email.label": "E-mail",
    "email.attributes.status.label": "Status",
    "email.attributes.created_at.label": "Erstellt am",
    "email.attributes.body.label": "Nachricht",
    "email.attributes.body.placeholder": "Nachrichtentext",
    "email.attributes.attachments.label": "Angehängte Dokumente",
    "email.attributes.attachments.placeholder": "Dokumente hinzufügen",
    "email.attributes.render_message_thread.label": "Gesamten Nachrichtenverlauf zitieren",
    "email.attributes.migration.label": "E-Mail Integration 2.0 aktivieren",
    "attachment.title.one": "Dokument",
    "attachment.title.other": "Dokumente",
    "attachment.title.missing": "Es wurden noch keine Dokumente hochgeladen.",
    "attachment.actions.add": "Dokument hinzufügen",
    "attachment.actions.upload": "Dokument hochladen",
    "attachment.actions.assign": "Bestehendes Dokument verknüpfen",
    "attachment.actions.edit": "Dokument bearbeiten",
    "attachment.actions.remove": "Dokument löschen",
    "attachment.actions.dragAndDrop": "Dokument zum Upload hierher ziehen",
    "attachment.actions.selectType": "Bitte wählen Sie den Dokumenttyp aus",
    "attachment.actions.removeDialog.title": "Dokument löschen",
    "attachment.actions.removeDialog.message": "Wollen Sie das Dokument endgültig aus dieser Wohneinheit löschen?",
    "attachment.actions.removeDialog.messageWithReferences": "Das Dokument wird derzeit in {count} Vorgängen / Positionen referenziert.",
    "attachment.actions.unlinkDialog.title": "Dokument entfernen",
    "attachment.actions.unlinkDialog.message.activity": "Wollen Sie das Dokument aus diesem Vorgang entfernen?",
    "attachment.actions.unlinkDialog.message.lineItem": "Wollen Sie das Dokument aus dieser Position entfernen?",
    "attachment.actions.unlinkDialog.hint": "Hinweis: Das Dokument verbleibt in der Wohneinheit, kann dort aber endgültig gelöscht werden.",
    "attachment.actions.assignUser": "Dokument für {user} sichtbar machen",
    "attachment.actions.removeUser": "Dokument für {user} unsichtbar machen",
    "attachment.actions.attach": "Anhang hinzufügen",
    "attachment.attachDialog.header": "Dokumente anhängen",
    "attachment.uploadedAt": "Am {dateTime} hinzugefügt",
    "attachment.editVisibility": "Sichtbarkeit",
    "attachment.message.noneAssigned": "Es wurden noch keine Dokumente hinzugefügt.",
    "attachment.message.biggerFiles": "There are files larger then {warningThreshold}MB.",
    "attachment.message.unsupportedFile": "Dateiformat nicht akzeptiert, bitte ein gültiges Format nutzen.",
    "attachment.message.unsupportedSize": "Dateigröße nicht akzeptiert, bitte maximal {maxFilesize}MB.",
    "attachment.message.unsupportedFileOrSize": "Dateiformat oder Dateigröße nicht akzeptiert, bitte ein gültiges Format nutzen (maximal 5 MB).",
    "attachment.message.supportedOnlyExcel": "Achtung: bitte Excel Format (xlsx) verwenden",
    "attachment.message.supportedOnlyJSON": "Achtung: bitte JSON Format (json) verwenden",
    "attachment.reference.unit": "Dokumente der Wohneinheit",
    "attachment.reference.line_items": "Dokumente aus dem Leistungsumfang",
    "attachment.reference.project": "Dokumente des Projektes",
    "attachment.reference.activity": "Dokumente des Vorgangs",
    "attachment.type.attachment.one": "Sonstige",
    "attachment.type.attachment.singluar": "Das Dokument",
    "attachment.type.attachment.other": "Sonstige",
    "attachment.type.plan.one": "Plan",
    "attachment.type.plan.singluar": "Der Plan",
    "attachment.type.plan.other": "Pläne",
    "attachment.type.estimate_buyer.one": "Erwerberangebot",
    "attachment.type.estimate_buyer.singluar": "Der Erwerberangebot",
    "attachment.type.estimate_buyer.other": "Erwerberangebote",
    "attachment.type.estimate_contractor.one": "Nachunternehmerangebot",
    "attachment.type.estimate_contractor.singluar": "Der Nachunternehmerangebot",
    "attachment.type.estimate_contractor.other": "Nachunternehmerangebote",
    "attachment.type.invoice.one": "Rechnung",
    "attachment.type.invoice.singluar": "Die Rechnung",
    "attachment.type.invoice.other": "Rechnungen",
    "attachment.type.image.one": "Bild",
    "attachment.type.image.singluar": "Das Bild",
    "attachment.type.image.other": "Bilder",
    "attachment.type.order.one": "Leistungsverzeichnis",
    "attachment.type.order.singluar": "Das Leistungsverzeichnis",
    "attachment.type.order.other": "Leistungsverzeichnisse",
    "attachment.type.order_confirmation.one": "Nachunternehmerbeauftragung",
    "attachment.type.order_confirmation.singluar": "Die Nachunternehmerbeauftragung",
    "attachment.type.order_confirmation.other": "Nachunternehmerbeauftragungen",
    "attachment.attributes.export_id.label": "Vorlage",
    "attachment.attributes.display_name.label": "Titel",
    "attachment.attributes.display_name.long": "Dokumententitel",
    "attachment.attributes.display_name.placeholder": "Dateiname oder aussagekräftiger Titel des Dokuments",
    "attachment.attributes.display_name.error": "Pflichtfeld. Bitte verwenden Sie nur Buchstaben, Zahlen, Binde- und Unterstriche.",
    "attachment.attributes.role.label": "Dokumententyp",
    "attachment.attributes.file_name.label": "Dateiname",
    "attachment.hints.version_history": "Datei Versionsverlauf",
    "attachment.hints.attachment_version_history": "Datenbank Attachment Versionsverlauf",
    "attachment.hints.reference_count": "Dieses Dokument ist mit {count} Vorgängen oder Positionen verknüpft.",
    "attachment.hints.message_count": "Dieses Dokument wurde {count} mal als E-Mail versendet.",
    "attachment.hints.featured": "Schnellzugriff",
    "attachment.hints.feature": "Das Dokument ist derzeit nicht dem Schnellzugriff hinzugefügt.",
    "attachment.hints.unfeature": "Das Dokument ist dem Schnellzugriff hinzugefügt.",
    "attachment.hints.visible_extern": "Dieses Dokument ist für Erwerber sichtbar.",
    "attachment.hints.visible_intern": "Dieses Dokument ist nur intern sichtbar.",
    "attachment.hints.visible_public": "Dieses Dokument ist öffentlich sichtbar.",
    "attachment.hints.popup.title": "Verlinkt in",
    "attachment.hints.popup.project": "Projekt",
    "attachment.hints.popup.activity": "Vorgang",
    "attachment.hints.popup.unit": "Wohnung",
    "attachment.hints.popup.roomBookSubLineItem": "Position",
    "attachment.visibility.buyer.title": "Erwerber",
    "attachment.visibility.buyer.description": "Interne Benutzer und Erwerber, z.B. über den Online-Konfigurator können das Dokument öffnen.",
    "attachment.visibility.public.title": "Öffentlich",
    "attachment.visibility.public.description": "Jeder mit dem Link zum Dokument kann das Dokument aufrufen.",
    "attachment.visibility.internal.title": "Intern",
    "attachment.visibility.internal.description": "Nur interne Benutzer können das Dokument aufrufen.",
    "attachment_type.title.one": "Dokumenttyp",
    "attachment_type.title.many": "Dokumenttypen",
    "attachment_type.attributes.id.label": "ID",
    "attachment_type.attributes.id.error": "Bitte geben Sie eine eindeutige ID an.",
    "attachment_type.attributes.label.label": "Name",
    "attachment_type.attributes.count.label": "# Dokumente",
    "attachment_type.actions.removeDialog.title": "Dokumenttyp löschen",
    "attachment_type.actions.removeDialog.message": "Wollen Sie den Dokumenttypen wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "attachment_type.actions.undeletableDialog.title": "Dokumenttyp nicht löschbar",
    "attachment_type.actions.undeletableDialog.message": "Es wurden bereits Dokumente von diesem Typ hochgeladen. Dieser Typ kann nicht mehr gelöscht werden.",
    "accountRoles.title": "Rollen",
    "accountRoles.noRolesFound": "Keine Rollen gefunden",
    "accountRoles.deleteConfirmationDialog.title": "Rolle löschen",
    "accountRoles.deleteConfirmationDialog.message": "Wollen Sie die Rolle wirklich löschen?",
    "accountRoles.deleteConfirmationDialog.success.title": "Rolle gespecihert",
    "accountRoles.deleteConfirmationDialog.success.message": "Die Rolle wurde erfolgreich gelöscht.",
    "accountRoles.deleteConfirmationDialog.error.title": "Rolle nicht löschbar",
    "accountRoles.deleteConfirmationDialog.error.message": "Die Rolle konnte nicht gelöscht werden. Wahrscheinlich wird sie noch verwendet.",
    "accountRoles.actions.add": "Neue Rolle hinzufügen",
    "accountRoles.actions.edit": "Rolle bearbeiten",
    "accountRoles.dialog.name": "Name",
    "accountRoles.dialog.title.edit": "Rolle bearbeiten",
    "accountRoles.dialog.title.add": "Rolle hinzufügen",
    "accountRoles.dialog.description": "Bezeichnung",
    "accountRoles.dialog.roleDescription": "Rollenbeschreibung",
    "accountRoles.dialog.permissions": "Berechtigungen",
    "accountRoles.dialog.specialPermissions": "Spezialrechte",
    "accountRoles.dialog.editAccountSettings": "Kontoeinstellungen bearbeiten",
    "accountRoles.dialog.editSubcontractors": "Nachunternehmen bearbeiten",
    "accountRoles.dialog.editProductsData": "Produktdaten bearbeiten",
    "accountRoles.dialog.editAccessProjectCatalog": "Zugriff Projektkataloge",
    "accountRoles.dialog.editAccessStatisticsCenter": "Zugriff Statistikcenter",
    "accountRoles.dialog.editAccessUnassignedEmails": "Zugriff auf unzugeordnete E-Mails",
    "accountRoles.dialog.systemRoleHint": "Standardrollen können nicht bearbeitet werden. Sollten Sie eine Rolle mit abweichenden Berechtigungen benötigen, legen Sie bitte eine neue Rolle an.",
    "accountRoles.dialog.accessTrashbin": "Zugriff auf Papierkorb",
    "accountRoles.dialog.editConfigurator": "3D Konfigurator bearbeiten",
    "accountRoles.dialog.deleteActivities": "Vorgänge löschen",
    "accountRoles.dialog.deleteRoomBooks": "Raumbücher löschen",
    "accountRoles.dialog.accessVersionHistory": "Zugriff auf Versionsverlauf",
    "accountRoles.dialog.accessAPIDocumentation": "API",
    "apiDocumentation.title": "API Dokumentation",
    "apiDocumentation.description": "Die API Dokumentation ist nur für interne Benutzer sichtbar.",
    "apiDocumentation.dialog.title": "Titel",
    "apiDocumentation.dialog.create": "Neue API Dokumentation",
    "projectRoles.title": "Projektrollen",
    "projectRoles.noRolesFound": "Keine Rollen gefunden",
    "projectRoles.deleteConfirmationDialog.title": "Rolle löschen",
    "projectRoles.deleteConfirmationDialog.message": "Wollen Sie die Rolle wirklich löschen?",
    "projectRoles.deleteConfirmationDialog.success.title": "Rolle gespecihert",
    "projectRoles.deleteConfirmationDialog.success.message": "Die Rolle wurde erfolgreich gelöscht.",
    "projectRoles.deleteConfirmationDialog.error.title": "Rolle nicht löschbar",
    "projectRoles.deleteConfirmationDialog.error.message": "Die Rolle konnte nicht gelöscht werden. Wahrscheinlich wird sie noch verwendet.",
    "projectRoles.actions.add": "Neue Rolle hinzufügen",
    "projectRoles.actions.edit": "Rolle bearbeiten",
    "projectRoles.dialog.title.edit": "Projektrolle bearbeiten",
    "projectRoles.dialog.title.add": "Projektrolle hinzufügen",
    "projectRoles.dialog.name": "Name",
    "projectRoles.dialog.description": "Bezeichnung",
    "projectRoles.dialog.permissions": "Berechtigungen",
    "projectRoles.dialog.descriptionPlaceholder": "Rollenbeschreibung",
    "projectRoles.dialog.projectRolesDialogTitleAdd": "Role hinzufugen",
    "projectRoles.dialog.projectRolesDialogName": "Name",
    "projectRoles.dialog.projectRolesDialogDescription": "Bezeichnung",
    "projectRoles.dialog.projectRolesDialogPermissions": "Berechtigungen",
    "projectRoles.dialog.allowPerspectiveOnboarding": "Zugriff auf die Perspektive \"Einrichtung\"",
    "projectRoles.dialog.allowPerspectiveSales": "Zugriff auf die Perspektive \"Sales\"",
    "projectRoles.dialog.allowAccessAllActivities": "Zugriff auf alle Vorgänge des Projektes",
    "projectRoles.dialog.allowAccessActivities": "Zugriff auf Vorgänge",
    "projectRoles.dialog.allowAccessAllUnits": "Zugriff auf alle Wohneinheiten",
    "projectRoles.dialog.allowAccessDeadlines": "Zugriff auf Fristen",
    "projectRoles.dialog.allowAccessDocuments": "Zugriff auf Dokumente",
    "projectRoles.dialog.allowAccessStatistics": "Zugriff auf Statistiken",
    "projectRoles.dialog.allowAccessEditUnits": "Recht Wohneinheiten zu bearbeiten",
    "projectRoles.dialog.allowAccessAllMessages": "Zugriff auf alle Nachrichten des Projektes",
    "projectRoles.dialog.allowAccessEditProject": "Recht die Projektdaten zu bearbeiten",
    "projectRoles.dialog.allowAccessBuyers": "Zugriff Erwerbermanagement",
    "projectRoles.dialog.allowAccessContractors": "Zugriff Nachunternehmermanagement",
    "customFields.tab.title": "Datenmodel",
    "customFields.title": "Produktfelder",
    "customFields.errorMessage": "Fehlermeldung",
    "customFields.hint": "Hinweis",
    "customFields.label": "Label",
    "customFields.multiple": "Mehrfachauswahl",
    "customFields.name": "Name",
    "customFields.position": "Position",
    "customFields.required": "Erforderlich",
    "customFields.subject": "Bezug",
    "customFields.customDataType": "Datentyp",
    "customFields.noCustomFieldsFound": "Keine Produktfelder gefunden",
    "customFields.confirmations.deleteItem": "Möchten Sie dieses Element wirklich löschen?",
    "customFields.states.saving": "Speichern...",
    "customFields.button.cancel": "Abbrechen",
    "customFields.button.delete": "Löschen",
    "customFields.button.save": "Speichern",
    "customFields.message": null,
    "customFields.error.title": "Fehler",
    "customFields.success.title": "Erfolg",
    "customFields.dialog.deleteTitle": "Produktfeld löschen",
    "customFields.dialog.deleteMessage": "Möchten Sie dieses Produktfeld wirklich löschen?",
    "webhooks.id": "ID",
    "webhooks.url": "URL",
    "webhooks.method": "Method",
    "webhooks.project": "Projekt",
    "webhooks.occurrence": "Ereignis",
    "webhooks.emptyMessage": "Es sind noch keine Webhooks definiert",
    "webhooks.webhooks": "Webhooks",
    "webhooks.add": "Hinzufugen",
    "webhooks.loading": "Loading...",
    "webhooks.dialog.name": "Name",
    "webhooks.dialog.description": "Bezeichnung",
    "webhooks.dialog.createWebhook": "Webhook anlegen",
    "webhooks.dialog.project": "Projekt",
    "webhooks.dialog.entity": "Entity",
    "webhooks.dialog.event": "Event",
    "webhooks.dialog.url": "URL",
    "webhooks.dialog.method": "Method",
    "webhooks.dialog.clear": "Clear",
    "webhooks.dialog.cancel": "Abbrechen",
    "webhooks.dialog.save": "Speicherm",
    "webhooks.dialog.deleteTitle": "Webhook löschen",
    "webhooks.dialog.deleteWebhook": "Webhook löschen?",
    "contacts.title": "Kontakte",
    "contacts.search": "Suche",
    "contacts.add": "Hinzufügen",
    "contacts.no_contacts_found": "Keine Kontakte gefunden",
    "message_filters.title": "Filter",
    "message_filters.no_filters_found": "Keine Filter gefunden.",
    "message_filters.dialog.title.add": "Filter anlegen",
    "message_filters.dialog.title.edit": "Filter bearbeiten",
    "message_filters.dialog.title.if": "Wenn",
    "message_filters.dialog.title.then": "Dann",
    "message_filters.dialog.attributes.name": "Name",
    "message_filters.dialog.attributes.summary": "Kurzbeschreibung",
    "message_filters.dialog.attributes.filter_project": "Projekt",
    "message_filters.dialog.attributes.filter_user": "Benutzer",
    "message_filters.dialog.attributes.filter_sender_email_pattern": "Absender",
    "message_filters.dialog.attributes.filter_subject_pattern": "Betreff enthält",
    "message_filters.dialog.attributes.filter_body_pattern": "Nachricht enthält",
    "message_filters.dialog.attributes.filter_has_attachment": "Anhang vorhanden",
    "message_filters.dialog.attributes.action_assign_label": "Label zuweisen",
    "message_filters.dialog.attributes.action_assign_user": "Benutzer zuweisen",
    "message_filters.dialog.attributes.action_mark_read": "Als gelesen markieren",
    "message_filters.dialog.attributes.action_mute_notification": "Benachrichtigungen unterdrücken",
    "message_filters.confirmation.title": "Filter löschen",
    "message_filters.confirmation.body": "Wollen Sie den Filter wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "message_filters.remove.success.body": "Filter gelöscht wurde.",
    "message_filters.remove.failure.body": "Filter nicht gelöscht wurde.",
    "createContact.title.create": "Kontakt anlegen",
    "createContact.title.edit": "Kontakt bearbeiten",
    "createContact.form.salutation": "Anrede",
    "createContact.form.title": "Titel",
    "createContact.form.first_name": "Vorname",
    "createContact.form.last_name": "Nachname",
    "createContact.form.company": "Firma",
    "createContact.form.email": "E-Mail",
    "createContact.form.phone": "Telefon",
    "createContact.form.mobile": "Mobil",
    "createContact.form.address_postcode": "Postleitzahl",
    "createContact.form.address_number": "Hausnummer",
    "createContact.form.address_street": "Straße",
    "createContact.form.address_town": "Ort",
    "createContact.form.address_country": "Land",
    "createContact.form.notes": "Notizen",
    "createContact.removeConfirmation.title": "Kontakt löschen",
    "createContact.removeConfirmation.body": "Wollen Sie den Kontakt wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "project.title.one": "Bauprojekt",
    "project.title.other": "Bauprojekte",
    "project.items.one": "ein Bauprojekt",
    "project.items.other": "Bauprojekte",
    "project.filter": "Nach Bauphase filtern",
    "project.activity.zero": "Keine Vorgänge",
    "project.activity.one": "Ein Vorgang",
    "project.activity.other": "Vorgänge",
    "project.activity.filter.ownItemsOnly": "Nur meine Vorgänge anzeigen",
    "project.statistics.turnover": "Umsatz nach Phasen",
    "project.statistics.profit": "Mehrerlös nach Phasen",
    "project.statistics.activity": "Vorgänge nach Dringlichkeit",
    "project.statistics.metrics.by_unit.title.label": "Kennzahlen nach Wohneinheiten",
    "project.statistics.metrics.by_trade.title.label": "Kennzahlen nach Gewerken",
    "project.statistics.metrics.progress.label": "Fortschritt",
    "project.statistics.metrics.progress.hint": "Bemusterungsfortschritt",
    "project.statistics.metrics.revenue.gross.label": "Mehrumsatz (brutto)",
    "project.statistics.metrics.revenue.gross.hint": "Gesamtsumme (brutto) des durch Sonderwunschleistung zusätzlich erzielten Umsatzes",
    "project.statistics.metrics.revenue.net.label": "Mehrumsatz (netto)",
    "project.statistics.metrics.revenue.net.hint": "Gesamtsumme (netto) des durch Sonderwunschleistung zusätzlich erzielten Umsatzes",
    "project.statistics.metrics.amount_offered.label": "Angeboten EW",
    "project.statistics.metrics.amount_offered.hint": "Gesamtsumme der angebotenen Mehrleistung",
    "project.statistics.metrics.amount_pending.label": "Offen EW",
    "project.statistics.metrics.amount_pending.hint": "Gesamtsumme der beim Erwerber liegenden Angebote",
    "project.statistics.metrics.amount_ordered.label": "Beauftragt EW",
    "project.statistics.metrics.amount_ordered.hint": "Gesamtsumme der beauftragten Mehrleistung",
    "project.statistics.metrics.amount_cancelled.label": "Storniert EW",
    "project.statistics.metrics.amount_cancelled.hint": "Gesamtsumme der stornierten Mehrleistung",
    "project.statistics.metrics.costs.gross.label": "Mehrkosten (brutto)",
    "project.statistics.metrics.costs.gross.hint": "Gesamtsumme (brutto) der durch Sonderwunschleistung zusätzlich gegenüber den Gewerken erzeugten Kosten",
    "project.statistics.metrics.costs.net.label": "Mehrkosten (netto)",
    "project.statistics.metrics.costs.net.hint": "Gesamtsumme (netto) der durch Sonderwunschleistung zusätzlich gegenüber den Gewerken erzeugten Kosten",
    "project.statistics.metrics.costs_ordered.label": "Beauftragt EW",
    "project.statistics.metrics.costs_ordered.hint": "Gesamtkosten der durch den Erwerber beauftragten Mehrleistung",
    "project.statistics.metrics.costs_contracted.label": "Beauftragt NU",
    "project.statistics.metrics.costs_contracted.hint": "Gesamtkosten der bei den Gewerken beauftragten Mehrleistung",
    "project.unit_variables.section.label": "Export/Import",
    "project.unit_variables.section.download_tooltip": "Mengen herunterladen",
    "project.unit_variables.section.upload_tooltip": "Mengen hochladen",
    "project.unit_variables.section.upload_error": "Mengen konnten nicht hochgeladen werden",
    "project.unit_variables.dialog.title": "Mengen hochladen",
    "project.unit_variables.dialog.force": "Update erzwingen",
    "project.unit_variables.dialog.messages.selectFile": "Wählen Sie bitte eine Datei aus",
    "project.unit_variables.dialog.messages.forceInfo": "Achtung: wenn <strong>Update erzwingen</strong> angekreuzt ist, werden manuell geänderte Mengen falls benötigt überschrieben.",
    "project.roomBook.notFound": "Es liegen keine Raumbuchvorlagen zu diesem Projekt vor",
    "project.roomBook.noPriceCatalog": "Um eine neue Raumbuchvorlage anzulegen, weisen Sie bitte zunächst dem Projekt eine Preisliste zu.",
    "project.actions.edit.label": "Details dieses Bauprojekts bearbeiten",
    "project.actions.removeImage.label": "Bild entfernen",
    "project.actions.uploadImage.label": "Titelbild des Bauprojekts hierher ziehen",
    "project.actions.addSection.label": "Bauabschnitt",
    "project.actions.addUnit.label": "Wohneinheit",
    "project.tabs.overview": "Übersicht",
    "project.tabs.projectSetup": "Discovery",
    "project.tabs.activity": "Vorgänge",
    "project.tabs.sectionAndUnit": "Wohneinheiten & Raumbücher",
    "project.tabs.roomBook": "Raumbuchvorlagen",
    "project.tabs.roomBooks": "Wohneinheiten",
    "project.tabs.roomBookTemplates": "Raumbuchvorlagen",
    "project.tabs.statistics": "Auswertung",
    "project.tabs.deadlines": "Projektfristen",
    "project.tabs.search": "Suche",
    "project.tabs.contractors": "Nachunternehmen",
    "project.tabs.projectQuantities": "Mengenauswertung",
    "project.attributes.name.label": "Bauprojekt",
    "project.attributes.name.error": "Bitte geben Sie einen Bauprojektnamen an.",
    "project.attributes.slug.label": "Slug",
    "project.attributes.status.label": "Bauphase",
    "project.attributes.fullAddress.label": "Adresse",
    "project.attributes.address.label": "Straße & Hausnummer",
    "project.attributes.town.label": "Ort",
    "project.attributes.suburb.label": "Stadtteil",
    "project.attributes.postcode.label": "Postleitzahl",
    "project.attributes.developerName.label": "Organisation",
    "project.attributes.developerContactPerson.label": "Kontaktperson",
    "project.attributes.developerEmail.label": "E-Mail",
    "project.attributes.developerEmail.error": "Bitte geben Sie eine valide Email-Adresse an.",
    "project.attributes.developerPhone.label": "Telefon",
    "project.attributes.developerAddress.label": "Straße & Hausnummer",
    "project.attributes.developerPostcode.label": "Postleitzahl",
    "project.attributes.developerTown.label": "Ort",
    "project.attributes.customerSuccessManager.label": "Account Manager",
    "project.attributes.projectFeature.label": "Merkmale",
    "project.attributes.attachmentTypeFilters.label": "Dokumenttypen (Einschränkung)",
    "project.attributes.processDefinitionIds.label": "Prozesse",
    "project.attributes.processDefinitionIds.placeholder": "Verknüpfen Sie die Prozesse mit dem Projekt",
    "project.attributes.systemConfig.label": "System config",
    "project.attributes.reportingStatus.label": "Projektstatus",
    "project.attributes.reportingStatus.onboarding": "Einrichtung",
    "project.attributes.reportingStatus.active": "In Nutzung",
    "project.attributes.reportingStatus.completed": "Abgeschlossen",
    "project.attributes.reportingStatus.abandoned": "Eingestellt",
    "project.attributes.defaultUnitType.label": "Standard Wohnungstyp",
    "project.attributes.unitQuota.label": "Wohnungskontingent",
    "project.attributes.licenseKey.label": "Lizenzschlüssel",
    "project.attributes.customConfiguratorDomain.label": "Custom Domain",
    "project.attributes.externalId.label": "Externe ID",
    "project.attributes.projectCode.label": "Projektcode",
    "project.attributes.showroomCity.label": "Showroom Stadt",
    "project.attributes.showroomAddress.label": "Showroom Adresse",
    "project.attributes.production.label": "Reales Kundenprojekt",
    "project.attributes.accountManager.label": "Ansprechpartner Kundenbetreuung",
    "project.attributes.salesUser.label": "Ansprechpartner Vertrieb",
    "project.attributes.custom_import_configs.label": "Custom import configs",
    "project.attributes.vendor.label": "bau digital",
    "project.attributes.marketing_portal.label": "Marketing Portal",
    "project.attributes.marketing_portal.marketing_website_url.label": "Marketing Website",
    "project.attributes.marketing_portal.marketing_portal_activated.label": "Projekt veröffentlichen",
    "project.attributes.marketing_portal.marketing_portal_configurator_link.label": "Wohungsexpose veröffentlichen",
    "project.attributes.marketing_portal.marketing_portal_summary.label": "Kurzbeschreibung",
    "project.attributes.marketing_portal.marketing_portal_description.label": "Langbeschreibung",
    "project.attributes.marketing_portal.construction_begins_at.label": "Geplanter Baubeginn",
    "project.attributes.marketing_portal.construction_completes_at.label": "Geplante Fertigstellung",
    "project.attributes.marketing_portal.marketing_unit_price_min.label": "Preis von",
    "project.attributes.marketing_portal.marketing_unit_price_max.label": "Preis bis",
    "project.attributes.marketing_portal.marketing_unit_floor_space_min.label": "Wohnfläche von",
    "project.attributes.marketing_portal.marketing_unit_floor_space_max.label": "Wohnfläche bis",
    "project.attributes.marketing_portal.marketing_unit_rooms_min.label": "Anzahl Zimmer von",
    "project.attributes.marketing_portal.marketing_unit_rooms_max.label": "Anzahl Zimmer bis",
    "project.attributes.marketing_portal.marketing_unit_count_total.label": "Anzahl Wohneinheiten gesamt",
    "project.attributes.external_sales.label": "Externer Vertrieb",
    "project.attributes.external_sales.agent_name.label": "Anzeigename",
    "project.attributes.external_sales.agent_name_full.label": "Firma",
    "project.attributes.external_sales.agent_email.label": "E-Mail",
    "project.attributes.external_sales.agent_phone.label": "Telefon",
    "project.attributes.external_sales.agent_address_street.label": "Straße",
    "project.attributes.external_sales.agent_address_postcode.label": "Postleitzahl",
    "project.attributes.external_sales.agent_address_city.label": "Stadt",
    "project.attributes.external_sales.marketed_by_agent.label": "Als Anbieter auftreten",
    "project.attributes.external_sales.agent_logo_id.label": "Firmenlogo",
    "project.attributes.thumb_id.label": "Projektbild",
    "project.attributes.buyer_portal.heading.configurators": "3D Konfiguratoren",
    "project.attributes.buyer_portal.banner_url.label": "URL Banner",
    "project.attributes.buyer_portal.styles.label": "CSS Stylesheet (SASS)",
    "project.attributes.buyer_portal.units_menu_item.label": "Menupunkt \"Wohneinheiten\" umbennen",
    "project.attributes.buyer_portal.additional_html.title": "Zusätzliches HTML",
    "project.attributes.buyer_portal.additional_html.label": "Hier ist Platz für zusätzliches HTML",
    "project.attributes.buyer_portal.product_lines.label": "Ausstattungslinien",
    "project.attributes.buyer_portal.glencoeURL.label": "Glencoe URL",
    "project.attributes.buyer_portal.configurators.label": "Hauptkonfiguration",
    "project.attributes.buyer_portal.product_mapping.label": "Produkt mapping",
    "project.attributes.buyer_portal.product_mapping.toggle_label": "Produkt mapping JSON field toggle",
    "project.attributes.buyer_portal.auto_product_mapping.label": "Do you want to map {num, plural, one {# product} other {# products}} automatically?",
    "project.attributes.buyer_portal.auto_product_mapping.button_label": "Make it so!",
    "project.attributes.buyer_portal.auto_product_mapping.no_products": "No products for auto mapping",
    "project.attributes.buyer_portal.auto_product_mapping.diverting_tooltip": "Group contains products that are already mapped to different 3d param",
    "project.attributes.buyer_portal.auto_product_mapping.table.label": "{num, plural, one {# value is not mapped because it already exists} other {values are not mapped because they already exist }} in product mapping:",
    "project.attributes.buyer_portal.auto_product_mapping.table.header_group": "Gruppe ID",
    "project.attributes.buyer_portal.auto_product_mapping.table.header_product": "Produkt ID",
    "project.attributes.buyer_portal.auto_product_mapping.table.header_existing_value": "Already mapped value",
    "project.attributes.buyer_portal.auto_product_mapping.table.header_new_value": "New product value (not mapped)",
    "project.attributes.buyer_portal.sections.group.label": "Bemusterungsgruppe",
    "project.attributes.buyer_portal.sections.description.label": "Einleitungstext",
    "project.attributes.buyer_portal.sections.thumb_url.label": "URL Abbildung",
    "project.attributes.buyer_portal.sections.visible.label": "Verfügbar im Online-Konfigurator",
    "project.attributes.buyer_portal.sections.configurator.label": "3D Konfigurator",
    "project.attributes.membership.title": "Abwesenheitsvertretung",
    "project.attributes.membership.active_from.label": "Aktiv von",
    "project.attributes.membership.active_to.label": "Aktiv bis",
    "project.attributes.membership.user.label": "Benutzer",
    "project.attributes.membership.empty.label": "Keine Abwesenheitsvertretungen gefunden",
    "project.attributes.membership.add.label": "Neue Abwesenheitsvertretung hinzufügen",
    "project.attributes.membership.all_projects.label": "Alle Projekte",
    "project.attributes.membership.confirmation.title": "Abwesenheitsvertretung löschen",
    "project.attributes.membership.confirmation.body": "Wollen Sie die Abwesenheitsvertretung wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "project.attributes.membership.remove.success.body": "Abwesenheitsvertretung gelöscht",
    "project.attributes.membership.remove.failure.body": "Abwesenheitsvertretung nicht gelöscht",
    "project.message.noResults": "Es wurden noch keine Bauprojekte angelegt.",
    "project.project_setup.title": "Projekt anlegen",
    "project.project_setup.tabs.basic_data": "Basisdaten",
    "project.project_setup.tabs.project": "Projektorganisation",
    "project.project_setup.tabs.project_structure": "Projektstruktur",
    "project.project_setup.tabs.project_catalog": "Ausstattungskatalog",
    "project.project_setup.tabs.room_books": "Raumbücher",
    "project.project_setup.tabs.process": "Prozesse",
    "project.project_setup.tabs.email_integration": "E-Mail Integration",
    "project.project_setup.tabs.data_providing": "Datenimport",
    "project.project_setup.items.project_catalog": "Projektkatalog",
    "project.project_setup.items.units": "Wohnungen",
    "project.project_setup.items.roombooks_templates": "Raumbuchvorlagen",
    "project.project_setup.items.unit_features": "Wohnungsmekmale",
    "project.project_setup.items.unit_variables": "Wohnungsmengen",
    "project.project_setup.toggle.label": "Projektsetup",
    "project.project_setup.wizard.title": "Projektwizard",
    "project.project_setup.dialog.project_name.label": "Projekt Name",
    "project.project_setup.dialog.customer_success_manager.label": "Projektmanager",
    "project.project_setup.dialog.add_comment.label": "Notiz hinzufügen",
    "project.project_setup.dialog.add_file.label": "Datei hinzufügen",
    "project.project_setup.dialog.add_file_title.label": "Datei hinzufügen",
    "project.project_setup.dialog.complete_topic.label": "Thema ist abschliessend geklärt",
    "project.project_setup.dialog.select_catalog.label": "Katalog auswählen",
    "project.project_setup.dialog.upload_catalog.label": "Katalog hochladen",
    "project.project_setup.dialog.project_catalog.label": "Ausstattungskatalog",
    "project.project_setup.dialog.create_project_catalog.label": "Katalog anlegen",
    "project.project_setup.dialog.price_list.label": "Preisliste",
    "project.project_setup.dialog.name.label": "Name",
    "project.project_setup.dialog.generate_roombook.label": "Raumbuch generieren",
    "project.project_setup.dialog.generate_roombook_again.label": "Nochmal generieren",
    "project.project_setup.dialog.generate_roombook_new.label": "Neu generieren",
    "project.project_setup.dialog.open_roombook.label": "Raumbuch öffnen",
    "project.project_setup.dialog.catalog_changed.label": "Achtung: Der Projektkatalog wurde nachträglich geändert.",
    "project.project_setup.dialog.no_topics.label": "Es liegen noch keine Fragestellungen vor",
    "project.project_setup.dialog.filter_message.label": "Abhängigkeit: Nur sichtbar wenn {reference} == {value}",
    "project.project_setup.analysisTopics.new.label": "Fragestellung hinzufügen",
    "project.project_setup.analysisTopics.dialog.title": "Project Analysis Topics Bearbeiten",
    "project.project_setup.analysisTopics.dialog.name": "Bezeichnung",
    "project.project_setup.analysisTopics.dialog.summary": "Fragestellung",
    "project.project_setup.analysisTopics.dialog.technicalReference": "Technical Reference",
    "project.project_setup.analysisTopics.dialog.topic": "Project Analysis Topic",
    "project.project_setup.analysisTopics.dialog.options": "Optionen",
    "project.project_setup.analysisTopics.dialog.option": "Option",
    "project.project_setup.analysisTopics.dialog.newOption": "Neue Option",
    "project.project_setup.analysisTopics.dialog.editingNote": "Bearbeitungshinweis",
    "project.project_setup.analysisTopics.dialog.label": "Anzeigename",
    "project.project_setup.analysisTopics.dialog.value": "Wert",
    "project.project_setup.analysisTopics.dialog.description": "Beschreibung",
    "project.project_setup.analysisTopics.dialog.requirements": "Abhängigkeiten",
    "project.project_setup.analysisTopics.dialog.errors.optionExists": "Diese Option existiert bereits",
    "project.project_setup.analysisTopics.dialog.errors.requirementExists": "Diese Abhängigkeit existiert bereits",
    "project.project_setup.data.changedBy.label": "Wohnungsdaten wurden am {date} um {time} von {user} hochgeladen.",
    "project.project_setup.data.reset.label": "Ändern",
    "project.perspectives.onboarding": "Einrichtung",
    "project.perspectives.customer_management": "Kundenbetreuung",
    "project.perspectives.sales": "Vertrieb",
    "configuratorBackend.actions.new": "Konfigurator anlegen",
    "configuratorBackend.actions.edit": "Konfigurator bearbeiten",
    "configuratorBackend.attributes.name.label": "Name",
    "configuratorBackend.attributes.url.label": "Glencoe Url",
    "configuratorBackend.attributes.config_sealed.label": "Konfiguration weicht bewusst von Glencoe ab",
    "configuratorBackend.attributes.editing_note.label": "Bearbeitungshinweis",
    "configuratorBackend.messages.diverged": "Achtung: es liegen Änderungen im Konfigurator-Setup vor.",
    "configuratorBackend.messages.sealed": "Diese Konfiguration weicht bewusst von Glencoe ab.",
    "projectRoom.actions.new": "Raum anlegen",
    "projectRoom.actions.edit": "Raum bearbeiten",
    "projectRoom.attributes.title.label": "Anzeigename",
    "projectRoom.attributes.name.label": "Name",
    "projectRoom.attributes.mode.label": "Verhalten",
    "projectRoom.attributes.description.label": "Beschreibung",
    "projectRoom.attributes.configurator_backend_id.label": "Konfigurator (Glencoe)",
    "projectRoom.attributes.render_id.label": "Konfigurator Raum",
    "projectRoom.attributes.configurator_alignment.label": "Bildausrichtung",
    "projectRoom.attributes.configurator_behaviour.label": "Bildverhalten",
    "projectRoom.attributes.room_type.label": "Raumtyp",
    "projectRoom.attributes.background_image.label": "Hintergrundbild",
    "projectRoom.attributes.section_selectors.label": "Welche Abschnitte sollen dargestellt werden?",
    "projectRoom.attributes.show_empty.label": "Raum auch ohne aktive Inhalte anzeigen",
    "projectRoom.attributes.hint_empty_state.label": "Hinweis wenn keine aktiven Inhalte vorhanden sind",
    "projectRoom.attributes.hint_upgrade_bundles.label": "Hinweis zu den Upgrade-Paketen im Raum",
    "projectRoom.attributes.label_upgrade_bundles.label": "Bezeichnung Upgrade-Pakete im Raum",
    "projectRoom.attributes.placeholder_upgrade_bundles.label": "Hinweis wenn kein Upgrade Paket ausgewählt wurde",
    "projectRoom.attributes.placeholder_upgrade_bundles.placeholder": "Hinweis wenn kein Upgrade Paket ausgewählt wurde",
    "projectRoom.attributes.hint_design_lines.label": "Hinweis zu den Design Linien im Raum",
    "projectRoom.attributes.label_design_lines.label": "Bezeichnung Design Linien im Raum",
    "projectRoom.attributes.position_upgrade_bundles.label": "Position Upgrade-Pakete",
    "projectRoom.attributes.disclaimer_text.label": "Rechtshinweis",
    "projectRoom.attributes.disclaimer_text.placeholder": "Rechtshinweise zu Abbildungen, Farben, Raum",
    "developer.title.one": "Bauträger",
    "developer.title.ohter": "Bauträger",
    "developer.title.zero": "Kein Bauträger",
    "developer.message.noneAssigned": "Es liegt noch kein Bauträger vor.",
    "unit.singular": "Wohneinheit",
    "unit.one": "Wohneinheit",
    "unit.other": "Wohneinheiten",
    "unit.preview.buyer": "Erwerberansicht",
    "unit.preview.marketing": "Musterwohnungsansicht",
    "unit.title.singular": "Wohneinheit",
    "unit.title.one": "Wohneinheit",
    "unit.title.other": "Wohneinheiten",
    "unit.title.missing": "Es wurden noch keine Wohneinheiten angelegt",
    "unit.title.activities_missing": "Es liegen keine Vorgänge für diese Wohneinheit vor",
    "unit.tabs.statistics": "Auswertung",
    "unit.tabs.roomBook": "Raumbuch",
    "unit.tabs.activities": "Vorgänge",
    "unit.tabs.attachments": "Dokumente",
    "unit.tabs.deadlines": "Fristen",
    "unit.tabs.messages": "Nachrichten",
    "unit.tabs.nachunternehmen": "Nachunternehmen",
    "unit.statistics.progress.title": "Bemusterungsfortschritt",
    "unit.statistics.profit.net.title": "Nettoergebnis",
    "unit.statistics.profit.gross.title": "Bruttoergebnis",
    "unit.statistics.netTotalCostsByTrade.title": "Mehrkosten (netto) je Gewerk",
    "unit.statistics.netTotalRevenueByTrade.title": "Mehrumsatz (netto) je Gewerk",
    "unit.statistics.netTotalProfitByTrade.title": "Mehrertrag (netto) je Gewerk",
    "unit.statistics.netMetricsByTradeAndPhase.title": "Kennzahlen Gewerke / Phase",
    "unit.statistics.netSelectedCostsByTrade.title": "Gesamtkosten (netto) je Gewerk",
    "unit.statistics.netSelectedRevenueByTrade.title": "Gesamtumsatz (netto) je Gewerk",
    "unit.statistics.grossTotalCostsByTrade.title": "Mehrkosten (brutto) je Gewerk",
    "unit.statistics.grossTotalRevenueByTrade.title": "Mehrumsatz (brutto) je Gewerk",
    "unit.statistics.grossTotalProfitByTrade.title": "Mehrertrag (brutto) je Gewerk",
    "unit.statistics.grossSelectedCostsByTrade.title": "Gesamtkosten (brutto) je Gewerk",
    "unit.statistics.grossSelectedRevenueByTrade.title": "Gesamtumsatz (brutto) je Gewerk",
    "unit.statistics.progressByTrade.title": "Fortschritt je Gewerk",
    "unit.statistics.relativeComparison.title": "Mehrkosten Erwerber / Nachunternehmen (netto)",
    "unit.statistics.relativeComparison.turnoverBar": "Mehrkosten Erwerber (netto)",
    "unit.statistics.relativeComparison.costsBar": "Mehrkosten Nachunternehmen (netto)",
    "unit.statistics.pseudoStatus.pending": "offen",
    "unit.statistics.pseudoStatus.buyerSelection": "Erwerber hat Wunsch geäußert",
    "unit.statistics.pseudoStatus.hasCart": "bemustert",
    "unit.statistics.noValues": "Es liegen noch keine statistischen Werte vor.",
    "unit.reportDownloads.sub_line_items.title": "Gesamtexport",
    "unit.reportDownloads.sub_line_items.description": "Enthält alle Positionen des Projekts",
    "unit.reportDownloads.ordered_products.title": "Produktstatistiken",
    "unit.reportDownloads.ordered_products.description": "Produktstatistiken über alle beauftragten Positionen im Projekt",
    "unit.reportDownloads.ordered_amounts.title": "Auftragssummen",
    "unit.reportDownloads.ordered_amounts.description": "Auftragssummen je Gewerk und Wohneinheit",
    "unit.reportDownloads.offered_amounts.title": "Angebotssummen",
    "unit.reportDownloads.offered_amounts.description": "Angebotssummen je Wohneinheit",
    "unit.reportDownloads.vista.title": "VISTA Paketauswahl",
    "unit.reportDownloads.vista.description": "Paketauswahl pro Haus / Gewerk",
    "unit.room.one": "Raum",
    "unit.room.other": "Räume",
    "unit.attributes.buyers.label": "Erwerber",
    "unit.attributes.buyers.placeholder": "Wählen Sie Erwerber aus",
    "unit.attributes.signees.label": "Erwerber",
    "unit.attributes.signees.placeholder": "Wählen Sie Erwerber aus",
    "unit.attributes.contacts.label": "Ansprechpartner",
    "unit.attributes.contacts.placeholder": "Wählen Sie weitere Ansprechpartner der Erwerber aus",
    "unit.attributes.salesManager.label": "Ansprechpartner Vertrieb",
    "unit.attributes.salesManager.placeholder": "Wählen Sie den Ansprechpartner aus",
    "unit.attributes.unitNumber.label": "Wohneinheit",
    "unit.attributes.unitNumber.error": "Bitte geben Sie eine Nummer für die Wohneinheit an. Die Nummer wird für die Erstellung der Vorgangsummer benötigt. ",
    "unit.attributes.cellarNumber.label": "Keller",
    "unit.attributes.parkingSpaceNumber.label": "Stellplatz",
    "unit.attributes.unitNumbers.label": "Wohnungsnummern",
    "unit.attributes.unitNumbers.placeholder": "Beispiel: 110a\n110b\n111\n112a\n112b\n113\n210\n211\n212",
    "unit.attributes.unitNumbers.explanation": "Pro Zeile kann eine Wohnungsnummer angegeben werden. Sollte eine Wohnungsnummer bereits im ausgewählten Bauabschnitt verwendet worden sein, so wird für diese Nummer keine neue Wohneinheit angelegt.",
    "unit.attributes.sample.label": "Musterwohnung",
    "unit.attributes.description.label": "Beschreibung",
    "unit.attributes.unitType.label": "Typ",
    "unit.attributes.price.label": "Kaufpreis",
    "unit.attributes.floorSpace.label": "Wohnungsgröße",
    "unit.attributes.bauDigitalDemo.label": "Online Konfigurator Demo Modus",
    "unit.attributes.buyerPortalEnabled.label": "Erwerberportal aktiviert",
    "unit.attributes.marketingPortalEnabled.label": "Vertriebsportal aktiviert",
    "unit.attributes.flatFinderEnabled.label": "Flatfinder aktiviert",
    "unit.attributes.floor.label": "Stockwerk",
    "unit.attributes.roomCount.label": "Anzahl Zimmer",
    "unit.attributes.contractNumber.label": "Vertragsnummer",
    "unit.attributes.contractDate.label": "Datum Kaufvertrag",
    "unit.attributes.transferDate.label": "Datum Übergabe (geplant)",
    "unit.attributes.displayName.label": "Anzeigename",
    "unit.attributes.reportable.label": "In Berichten ausweisen",
    "unit.attributes.featured.label": "Ausgewählte Wohneinheit im Neubauportal",
    "unit.attributes.sampleLink.label": "Öffentlicher Link zum Teilen",
    "unit.attributes.buyerPortalLink.label": "Link zum Online-Konfigurator",
    "unit.attributes.marketingPortalLink.label": "Link zum Vertriebskonfigurator",
    "unit.attributes.meterNumberWaterCold.label": "Zählernummer Wasser (kalt)",
    "unit.attributes.meterNumberWaterWarm.label": "Zählernummer Wasser (warm)",
    "unit.attributes.meterNumberElectricity.label": "Zählernummer Strom",
    "unit.attributes.meterNumberGas.label": "Zählernummer Gas",
    "unit.attributes.unitFeatures.label": "Merkmale",
    "unit.attributes.externalId.label": "Externe ID",
    "unit.attributes.externalId.placeholder": "z.B. 123",
    "unit.attributes.configuratorDesign.label": "Konfigurator Design",
    "unit.attributes.project_storey_ids.label": "Stockwerke",
    "unit.attributes.salesStatus.label": "Verkaufsstatus",
    "unit.attributes.salesStatus.options.all": "Alle",
    "unit.attributes.salesStatus.options.vacant": "Frei",
    "unit.attributes.salesStatus.options.reserved": "Reserviert",
    "unit.attributes.salesStatus.options.notary": "Notartermin",
    "unit.attributes.salesStatus.options.sold": "Verkauft",
    "unit.attributes.salesStatus.error": "Bitte den Status auf \"Verkauft\" oder \"Notartermin\" setzen, wenn Erwerber vorhanden sind.",
    "unit.attributes.socialMediaTitleOverride.label": "Soziale Medien Titel",
    "unit.attributes.socialMediaDescriptionOverride.label": "Soziale Medien Beschreibung",
    "unit.attributes.accountManager.label": "Ansprechpartner Kundenbetreuung",
    "unit.attributes.accountManager.placeholder": "Wählen Sie den Ansprechpartner aus",
    "unit.attributes.accountManager.options.all": "Alle Ansprechpartner",
    "unit.attributes.accountManager.options.none": "Ohne Ansprechpartner",
    "unit.attributes.accountManager.filter.label": "Ansprechpartner",
    "unit.attributes.section.label": "Bauabschnitt",
    "unit.attributes.section.options.all": "Alle Bauabschnitte",
    "unit.actions.nominateContractor.title": "Nachunternehmen für Wohneinheit {display_name} nominieren",
    "unit.actions.edit": "Wohneinheit bearbeiten",
    "unit.actions.batchCreate": "Wohneinheiten anlegen",
    "unit.actions.unitLoading": "Wohneinheit wird geladen",
    "unit.actions.roomBookLoading": "Raumbuch wird geladen",
    "unit.actions.removeDialog.title": "Wohneinheit löschen",
    "unit.actions.removeDialog.message": "Wollen Sie die Wohneinheit wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "unit.actions.undeletableDialog.title": "Wohneinheit nicht löschbar",
    "unit.actions.undeletableDialog.message": "Für diese Wohneinheit sind aktive Vorgänge vorhanden und kann daher nicht gelöscht werden.",
    "unit.actions.addBuyer.label": "Erwerber hinzufügen",
    "unit.actions.upload.title": "Wohneinheiten hochladen",
    "unit.actions.upload.tooltip": "Wohneinheiten hochladen",
    "unit.actions.upload.error": "Fehler beim Upload",
    "unit.actions.upload.customDialogError": "Select all values",
    "unit.actions.customUpload.link.label": "Download example file",
    "unit.actions.download.tooltip": "Wohneinheiten herunterladen",
    "unit.actions.download.error": "Fehler beim Download",
    "unit.message.noRecords": "Entweder liegen noch keine Wohneinheiten für diesen Bauabschnitt vor oder diese werden durch den aktuellen Filter ausgeblendet.",
    "section.title.singular": "Bauabschnitt",
    "section.title.one": "Ein Bauabschnitt",
    "section.title.other": "Bauabschnitte",
    "section.title.all": "Alle",
    "section.actions.new": "Neuer Bauabschnitt",
    "section.actions.edit": "Bauabschnitt bearbeiten",
    "section.actions.removeDialog.title": "Bauabschnitt löschen",
    "section.actions.removeDialog.message": "Wollen Sie den Bauabschnitt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "section.actions.undeletableDialog.title": "Bauabschnitt nicht löschbar",
    "section.actions.undeletableDialog.message": "Es wurden bereits Vorgänge für Wohneinheiten des Bauabschnitts gestartet. Bitte löschen Sie die nicht benötigten Wohneinheiten individuell.",
    "section.attributes.code.label": "Kürzel",
    "section.attributes.code.placeholder": "z.B. \"G4\"",
    "section.attributes.code.error": "Bitte geben Sie ein Kürzel für den Bauabschnitt an, das Kürzel wird für die Erstellung der Vorgangsummer benötigt.",
    "section.attributes.name.label": "Bezeichnung",
    "section.attributes.name.placeholder": "z.B. \"Gebäude 4\" oder \"2. Etage\"",
    "section.attributes.name.error": "Bitte geben Sie eine Bezeichnung an",
    "section.attributes.external_id.label": "Externe ID",
    "section.attributes.external_id.placeholder": "z.B. 123",
    "section.message.noRecords": "Es liegen noch keine Bauabschnitte vor.",
    "activity.label.one": "Prozess",
    "activity.label.other": "Prozesse",
    "activity.item.displayName": "Vorgangsnummer",
    "activity.item.nextStep": "Aktueller Arbeitsschritt",
    "activity.item.processTypeName": "Vorgangstyp",
    "activity.item.assignee.label": "Verantwortlicher",
    "activity.item.createdAt": "Begonnen am",
    "activity.scope.buyers": "Erwerber",
    "activity.scope.across": "Wohnungsübergreifend",
    "activity.title.one": "Vorgang",
    "activity.title.other": "Vorgänge",
    "activity.done": "Erledigt",
    "activity.close": "Bald fällig",
    "activity.overdue": "Fällig",
    "activity.amountOverdue": "{amount} überfällig",
    "activity.amountClose": "{amount} bald fällig",
    "activity.overdueDays": "{amount} Tage überfällig",
    "activity.dueDays": "Noch {amount} Tage",
    "activity.dueToday": "Heute fällig",
    "activity.dueTomorrow": "Morgen fällig",
    "activity.dueYesterday": "Seit gestern überfällig",
    "activity.tabs.timeline": "Timeline",
    "activity.tabs.cart": "Leistungsumfang",
    "activity.tabs.attachments": "Dokumente",
    "activity.tabs.costs": "Kosten",
    "activity.search.universal.placeholder": "Suche nach Erwerber, Wohneinheit oder Nachunternehmen...",
    "activity.sort.dueDateDesc": "Sortiert nach Frist",
    "activity.sort.turnoverDesc": "Sortiert nach Umsatz",
    "activity.sort.turnoverAsc": "Sortiert nach Umsatz",
    "activity.deadline.one": "Frist",
    "activity.deadline.other": "Fristen",
    "activity.deadline.noDeadlines": "Es gibt keine offenen Fristen für diesen Vorgang.",
    "activity.deadline.showMore": "{amount} weitere Fristen anzeigen ››",
    "activity.transitions.title": "Statuswechsel",
    "activity.assignments.suggestion.title": "Wechsel der Verantwortlichkeit",
    "activity.assignments.suggestion.message": "Der Arbeitsschritt \"{state}\" sollte von einem Benutzer der Rolle \"{role}\" übernommen werden. Bitte wählen Sie einen passenden Benutzer aus, um die Verantwortlichkeit zu wechseln.",
    "activity.contractor.title": "Fachplaner, Gewerke & Nachunternehmen",
    "activity.contractor.contractorNotConfigured": "Es wurden noch keine {trade}-Nachunternehmen im System konfiguriert.",
    "activity.contractor.contractorNotSet": "Nachunternehmen jetzt nominieren",
    "activity.contractor.removeDialog.title": "Nachunternehmen entfernen",
    "activity.contractor.removeDialog.message": "Wollen Sie das Nachunternehmen aus diesem Vorgang wirklich entfernen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "activity.timeline.event.create": "Der Vorgang wurde angelegt.",
    "activity.timeline.event.assignContractor": "\"{name}\" wurde hinzugefügt.",
    "activity.timeline.event.unassignContractor": "\"{name}\" wurde entfernt.",
    "activity.timeline.event.assignTrade": "Das Gewerk \"{name}\" wurde hinzugefügt.",
    "activity.timeline.event.uploadAttachment": "{type} \"{name}\" wurde hinzugefügt.",
    "activity.timeline.event.removeAttachment": "{type} \"{name}\" wurde entfernt.",
    "activity.timeline.event.unassignTrade": "Das Gewerk \"{name}\" wurde entfernt.",
    "activity.timeline.event.changeStatus": "Der Status wurde von \"{oldValue}\" auf \"{newValue}\" geändert.",
    "activity.timeline.event.changeDeadline": "Frist für Status \"{status}\" von {oldDeadline} auf {newDeadline} geändert.",
    "activity.timeline.event.changeSummary": "Die Beschreibung wurde von \"{oldValue}\" auf \"{newValue}\" geändert.",
    "activity.timeline.event.changeTitle": "Der Titel wurde von \"{oldValue}\" auf \"{newValue}\" geändert.",
    "activity.timeline.event.changeAssignee": "Der verantwortliche Benutzer wurde von \"{oldValue}\" auf \"{newValue}\" geändert.",
    "activity.timeline.event.taskCompleted": "Die Aufgabe \"{title} {delegatee}\" wurde erledigt.",
    "activity.timeline.event.priceInquirySubmitted": "Die Preisanfrage \"{title}\" wurde von {contractor} beantwortet.",
    "activity.timeline.event.note": "Kommentar von {user}",
    "activity.timeline.event.call": "Gesprächsnotiz von {user}",
    "activity.timeline.event.email": "Diese Email wurde von {from} gesendet.",
    "activity.timeline.event.deadline": "Frist",
    "activity.timeline.filter.label": "Filter",
    "activity.timeline.filter.exclusive": "nur",
    "activity.timeline.filter.reset": "Filter zurücksetzen",
    "activity.timeline.filter.showFutureEvents": "Künftige Ereignisse anzeigen",
    "activity.timeline.filter.showDeadlines": "Fristen anzeigen",
    "activity.timeline.filter.showStatusChanges": "Statusänderungen anzeigen",
    "activity.timeline.filter.showAuditEvents": "Änderungen am Vorgang anzeigen",
    "activity.timeline.filter.showMessages": "Emails anzeigen",
    "activity.timeline.filter.showMemos": "Kommentare und Gesprächsnotizen anzeigen",
    "activity.timeline.filter.showStakeholder": "Anzeige Email-Korrespondenz begrenzen auf ...",
    "activity.costs.trade": "Gewerk",
    "activity.costs.totalCosts": "Gesamtkosten",
    "activity.costs.numberOfLineItems": "Anzahl Positionen",
    "activity.costs.total.label": "Gesamt",
    "activity.costs.total.amount": "{amount} €",
    "activity.costs.noCosts": "Es liegen noch keine Kosten vor.",
    "activity.costs.summary": "Zusammenfassung",
    "activity.costs.position": "Positionen",
    "activity.costs.additionalCosts": "Mehrkosten",
    "activity.costs.additionalSales": "Mehrumsatz",
    "activity.costs.return": "Ertrag",
    "activity.costs.cart": "Cart",
    "activity.costs.net": "Netto",
    "activity.costs.gross": "Brutto",
    "activity.actions.new": "Vorgang",
    "activity.actions.add": "Vorgang erstellen",
    "activity.actions.add_with_name": "Vorgang \"{name}\" erstellen",
    "activity.actions.start": "Vorgang beginnen",
    "activity.actions.edit": "Vorgang bearbeiten",
    "activity.actions.showMore": "Mehr anzeigen ››",
    "activity.actions.showLess": "Weniger anzeigen ››",
    "activity.actions.changeStatus": "Status ändern",
    "activity.actions.proceedWith": "Weiter mit",
    "activity.actions.configureTransitions.label": "Status individuell festlegen",
    "activity.actions.delete.header": "Vorgang löschen",
    "activity.actions.delete.confirmationPrompt": "Bitte geben Sie Vorgangsnummer \"{display_name}\" zur Bestätigung ein:",
    "activity.actions.delete.confirmationMessagesPrompt": "Achtung! Mit dem Vorgang {messages_count, plural, one {wird eine Nachricht} other {werden # Nachrichten}} gelöscht! Bitte geben Sie zur Bestätigung die Anzahl Nachrichten ein:",
    "activity.actions.delete.body": "{count, plural, =0 {Keine Position wurde} one {Eine Position wurde} other {# Positionen wurden}} gelöscht.",
    "activity.export.title": "Vorgänge exportieren",
    "activity.export.byUnit": "Geordnet nach Wohneinheiten",
    "activity.export.byContractor": "Geordnet nach Nachunternehmen",
    "activity.attributes.title.label": "Bezeichnung",
    "activity.attributes.title.long": "Vorgangsbezeichnung",
    "activity.attributes.title.placeholder": "z.B. Türzarge um 180° drehen",
    "activity.attributes.title.error": "Bitte geben Sie eine Bezeichnung für den Vorgang an",
    "activity.attributes.summary.label": "Beschreibung der umzusetzenden Änderungen",
    "activity.attributes.summary.placeholder": "Hier können die Anforderungen detaillierter beschrieben werden.",
    "activity.attributes.room.label": "Raum",
    "activity.attributes.room.placeholder": "z.B. Küche",
    "activity.attributes.unit.label": "Wohneinheit",
    "activity.attributes.unit.placeholder": "Bitte auswählen",
    "activity.attributes.unit.noResultsMessage": "Keine Wohneinheit gefunden",
    "activity.attributes.price.gross.label": "Mehrkosten EW (brutto)",
    "activity.attributes.price.net.label": "Mehrkosten EW (netto)",
    "activity.attributes.price.error": "Bitte geben Sie einen Preis im Format \"0000,00\" an.",
    "activity.attributes.costs.net.label": "Mehrkosten NU (netto)",
    "activity.attributes.costs.gross.label": "Mehrkosten NU (brutto)",
    "activity.attributes.assignee.label": "Verantwortlicher Benutzer",
    "activity.attributes.startState.label": "Vorgang beginnen mit Schritt...",
    "activity.attributes.observer.label": "Beobachter",
    "activity.attributes.creation.filter.buyerSelection": "Nur Erwerberauswahl",
    "activity.attributes.creation.filter.selectable": "Alle Auswählbaren",
    "activity.attributes.creation.filter.all": "Alle Positionen",
    "activity.message.noResults": "Es wurde kein Vorgang gefunden.",
    "activity.message.transitions.ok": "Statuswechsel erfolgreich",
    "activity.message.transitions.illegal": "Der Statuswechsel kann nicht durchgeführt werden.",
    "activity.message.transitions.forbidden": "Der Statuswechsel kann nicht durchgeführt werden, da die Abwicklung mindestens einer Position bereits in einem anderen Vorgang fortgesetzt worden ist.",
    "activity.message.transitions.bulkUpdate": "Sollen alle Positionen in den Status: \"{state}\" überführt werden?",
    "activity.message.successors.forbidden": "Einige Positionen sind bereits einem anderen aktiven Vorgang zugeordnet worden. Daher konnte die Aktion nicht durchgeführt werden.",
    "activity.message.noLineItems.title": "Leerer Leistungsumfang",
    "activity.message.noLineItems.body": "Diesem Vorgang sind derzeit keine Positionen zugeordnet.",
    "activity.message.noLineItemsForThisFilter.title": "Keine Positionen für diesen Filter",
    "activity.message.noLineItemsForThisFilter.body": "Dieser Abschnitt enthält keine Positionen für das ausgewählte Gewerk",
    "activity.message.priceOnRequest.body": "Es gibt Produkte mit Preis auf Anfrage. Bitte prüfen Sie die angegebenen Preise.",
    "activity.message.priceOnRequest.tooltip": "Preis auf Anfrage",
    "activity.preview.generate": "Vorschau erstellen",
    "activity.preview.open": "Vorschau öffnen",
    "activity.preview.selectAndOpen": "Vorschau öffnen",
    "cartAssignment.dialog.unit.label": "Wohneinheit",
    "cartAssignment.dialog.progress.label": "Fortschritt",
    "cartAssignment.dialog.pending.label": "Ausstehend",
    "cartAssignment.dialog.pending.hint": "Anzahl der Positionen, die in der Abwicklung zurückliegen.",
    "cartAssignment.dialog.assignable.label": "Zuweisbar",
    "cartAssignment.dialog.assignable.hint": "Anzahl der Positionen, die diesem Vorgang zugewiesen werden können.",
    "cartAssignment.dialog.assigned.label": "Zugewiesen",
    "cartAssignment.dialog.assigned.hint": "Anzahl der Positionen, die diesem Vorgang zugewiesen sind",
    "cartAssignment.dialog.completed.label": "Erledigt",
    "cartAssignment.dialog.completed.hint": "Anzahl der Positionen, die bereits in der Abwicklung weiter vorangeschritten sind.",
    "cartAssignment.dialog.assignedDifferently.hint": "Positionen des Gewerkes aus dieser Wohneinheit sind einem anderen aktiven Vorgang zugewiesen. Ggf. sollte diese Wohneinheit nicht über diesen Vorgang abgewickelt werden.",
    "cartAssignment.actions.optionality.activate": "Optionale Position aktivieren",
    "cartAssignment.actions.optionality.deactivate": "Optionale Position deaktivieren",
    "cartAssignment.actions.optionality.activateTitle": "Aktivieren Sie diese optionale Position",
    "cartAssignment.actions.optionality.deactivateTitle": "Deaktivieren Sie diese optionale Position",
    "cartAssignment.actions.upgradeBundle.activate": "{upgradeBundleName} aktivieren",
    "cartAssignment.actions.upgradeBundle.deactivate": "{upgradeBundleName} deaktivieren",
    "cartAssignment.actions.upgradeBundle.activateTitle": "Aktivieren Sie {upgradeBundleName} Upgrade Bundle",
    "cartAssignment.actions.upgradeBundle.deactivateTitle": "Dektivieren Sie {upgradeBundleName} Upgrade Bundle",
    "cartAssignment.message.uneditable.title": "Bearbeitung des Leistungsumfangs",
    "cartAssignment.message.uneditable.body": "Der Leistungsumfang kann nicht mehr geändert werden, da der Vorgang bereits seinen Startzustand verlassen hat.",
    "cartAssignment.message.updated.title": "Bearbeitung des Leistungsumfangs",
    "cartAssignment.message.updated.body": "Der Leistungsumfang wurde erfolgreich aktualisiert.",
    "cartAssignment.message.already_assigned": "Die Position ist bereits einem aktivem Vorgang zugeordnet.",
    "cartAssignment.message.invalid_status": "Die Position befindet sich in einem Status, der diesem Vorgang nicht zugeordnet werden kann.",
    "cartAssignment.message.inactive": "Bitte aktivieren Sie diese Position, bevor die Abwicklung fortgesetzt werden kann.",
    "cartAssignment.message.replaced": "Diese Position wurde durch die Alternative {replacement} ersetzt und kann daher nicht beauftragt werden.",
    "cartAssignment.message.replacedShort": "Diese Position wurde durch eine Alternative ersetzt und kann daher nicht beauftragt werden.",
    "cartAssignment.message.unactivatable": "Diese Position kann nicht mehr aktiviert werden, da die Position {replacement} bereits aktiviert wurde.",
    "cartAssignment.message.confirmationAlert": "Achtung: Durch das deaktivieren der Position wird die aktuelle Erwerberauswahl verworfen.",
    "deadline.title.one": "Frist",
    "deadline.title.other": "Fristen",
    "deadline.actions.edit": "Fristen bearbeiten",
    "trade.title.one": "Gewerk",
    "trade.title.other": "Gewerke",
    "trade.actions.remove": "Gewerk entfernen",
    "trade.message.noContractor": "Hier ist noch kein Nachunternehmen hinzugefügt worden.",
    "notification.title.one": "Benachrichtigung",
    "notification.title.other": "Benachrichtigungen",
    "notification.amountMessages.zero": "Keine neue Nachrichten",
    "notification.amountMessages.one": "Eine neue Nachricht von",
    "notification.amountMessages.other": "neue Nachrichten von",
    "notification.assignment.label": "Der Vorgang wurde Ihnen zugewiesen.",
    "notification.memoCreated.label": "Eine Gesprächsnotiz / ein Kommentar wurde erfasst oder geändert",
    "notification.memoCreated.zero": "Keine Gesprächsnotiz / ein Kommentar wurde erfasst oder geändert",
    "notification.memoCreated.one": "Eine Gesprächsnotiz / ein Kommentar wurde erfasst oder geändert",
    "notification.memoCreated.other": "{count} Gesprächsnotizen / Kommentare wurden erfasst oder geändert",
    "notification.newMessages.one": "Es liegt ein neues Ereignis vor.",
    "notification.newMessages.other": "Es liegen {amount} neue Ereignisse vor.",
    "notification.statusChanged.label": "Der Vorgangsstatus wurde geändert",
    "notification.statusChanged.zero": "Der Vorgangsstatus wurde geändert",
    "notification.statusChanged.one": "Der Vorgangsstatus wurde geändert",
    "notification.statusChanged.other": "Der Vorgangsstatus wurde geändert",
    "notification.prospects.one": "Ein neuer Interessent für Wohneinheit",
    "notification.prospects.new": "Ein Interessent hat eine Ausstattungskonfiguration gespeichert",
    "notification.messageOutboundCreated.label": "Neue Nachrichten wurden gesendet",
    "notification.messageOutboundCreated.zero": "Neue Nachrichten wurden gesendet",
    "notification.messageOutboundCreated.one": "Eine neue Nachrichte wurde gesendet",
    "notification.messageOutboundCreated.other": "{count} Nachrichten wurden gesendet",
    "notification.noResults": "Es liegen keine Benachrichtigungen vor.",
    "notification.actions.dismiss": "Alles klar!",
    "notification.confirmation.title": "Clear all notifications",
    "notification.confirmation.message": "Are you sure?",
    "user.actions.add.label": "Benutzer hinzufügen",
    "user.actions.invite.label": "Benutzer einladen",
    "user.actions.editMembership.label": "Benutzer bearbeiten",
    "user.actions.resendInvitation.label": "Einladung nochmals senden",
    "user.actions.sendResetPasswordInstructions.label": "Anleitung senden",
    "user.actions.sendResetPasswordInstructions.title": "Passwort zurücksetzen",
    "user.actions.sendResetPasswordInstructions.body": "Wir senden Ihnen eine Anleitung zum Zurücksetzen Ihres Passwortes. Bitte geben Sie Ihre E-Mail Adresse an.",
    "user.actions.resetPassword.label": "Passwort speichern",
    "user.actions.resetPassword.title": "Neues Passwort vergeben",
    "user.actions.resetPassword.body": "Bitte vergeben Sie nun ein neues Passwort.",
    "user.actions.resetPassword.invalidToken.title": "Link abgelaufen",
    "user.actions.resetPassword.invalidToken.body": "Leider ist dieser Link nicht mehr gültig. Bitte fordern sie den Link zum Ändern ihres Kennwortes erneut an.",
    "user.actions.resetPassword.invalidToken.link": "Neuen Link anfordern",
    "user.actions.invitation.label": "Registrierung abschließen",
    "user.actions.invitation.welcome": "Herzlich Willkommen beim Online-Konfigurator!",
    "user.actions.invitation.body": "Bitte vergeben Sie ein sicheres Passwort und akzeptieren Sie die Nutzungsbedingungen, um den Online-Konfigurator zu nutzen.",
    "user.attributes.email.label": "E-Mail",
    "user.attributes.email.error": "Bitte geben Sie eine valide Email-Adresse an.",
    "user.attributes.name.label": "Name",
    "user.attributes.first_name.label": "Vorname",
    "user.attributes.last_name.label": "Nachname",
    "user.attributes.role.label": "Rolle",
    "user.attributes.incognito.label": "Incognito Modus",
    "user.attributes.masterdata_access.label": "Zugriff auf alle Erwerber- und Nachunternehmerdaten",
    "user.attributes.status.label": "Status",
    "user.attributes.password.label": "Passwort",
    "user.attributes.password.error": "Mindestens 6 Zeichen",
    "user.attributes.password_confirmation.label": "Passwort bestätigen",
    "user.attributes.password_confirmation.error": "Muss dem Passwort entsprechen",
    "user.attributes.phone.label": "Telefon",
    "user.attributes.mobile.label": "Mobil",
    "user.attributes.fax.label": "Fax",
    "user.attributes.position.label": "Position",
    "user.attributes.daily_digest.label": "Tägliche Fristenübersicht",
    "user.attributes.outbound_bcc.label": "Blindkopie ausgehender E-Mails",
    "user.attributes.inbound_fwd.label": "Weiterleitung eingehender E-Mails",
    "user.attributes.emailTemplate.label": "E-Mail Template",
    "user.attributes.image.label": "Profilbild",
    "user.attributes.lastSignIn.label": "Letzte Anmeldung",
    "user.roles.operator.label": "Benutzer",
    "user.roles.admin.label": "Administrator",
    "user.roles.api.label": "API Nutzer",
    "user.roles.contractor.label": "Nachunternehmer",
    "user.roles.Projektadmin.label": "Projekt Admin.",
    "user.status.active.label": "Aktiv",
    "user.status.inactive.label": "Inaktiv",
    "user.filter.showAll.label": "Alle Benutzer anzeigen",
    "user.filter.role.label": "Select a role...",
    "user.filter.text.label": "Enter search text...",
    "user.messages.alreadyInvited": "Ein Benutzer mit der angegebenen E-Mail Adresse ist diesem Konto bereits zugeordnet.",
    "user.messages.alreadyExists": "Ein Benutzer mit der angegebenen E-Mail Adresse ist bereits einem anderen Konto zugeordnet. Sie können diesen Benutzer zu diesem Konto einladen.",
    "user.messages.resetSuccess.title": "Neues Passwort gespeichert",
    "user.messages.resetSuccess.message": "Sie können Sich nun mit E-Mail Adresse und Ihrem neuen Passwort anmelden",
    "user.messages.resendInvitation.title": "Einladung gesendet",
    "user.messages.resendInvitation.message": "Die Einladung wurde erfolgreich an den Benutzer gesendet.",
    "user.messages.registered.title": "Registrierung erfolgreich abgeschlossen",
    "user.messages.registered.message": "Sie können sich nun mit Ihrem Passwort anmelden",
    "user.messages.statusChange.dropdownItem.activate": "Aktivieren",
    "user.messages.statusChange.dropdownItem.deactivate": "Deaktivieren",
    "user.messages.statusChange.title": "Status",
    "user.messages.statusChange.message.activated": "Benutzer aktiviert",
    "user.messages.statusChange.message.deactivated": "Benutzer deaktiviert",
    "product.title.one": "Produkt",
    "product.title.other": "Produkte",
    "product.title.catalog": "Produktkatalog",
    "product.title.suppliers": "Herstellerkataloge",
    "product.image.one": "Produktbild",
    "product.image.many": "Produktbilder",
    "product.attachment.title": "Produktvideos",
    "product.attachment.invalid": "Bitte einen vollständigen YouTube oder mp4 Link angeben",
    "product.attachment.actions.add": "Hinzufügen",
    "product.attachment.actions.remove": "Video entfernen",
    "product.attachment.file.title": "Dateien",
    "product.attachment.file.empty": "Es sind noch keine Dateien vorhanden",
    "product.attachment.file.listDialog.link": "Dateien ({n})",
    "product.attachment.file.listDialog.title": "Dateien",
    "product.attachment.file.confirmation.visibility.title": "Sichtbarkeit ändern",
    "product.attachment.file.confirmation.visibility.messagePublish": "Wollen Sie die Datei wirklich öffentlich aufrufbar machen?",
    "product.attachment.file.confirmation.visibility.messageHide": "Wollen Sie den öffentlichen Zugriff wirklich verweigern?",
    "product.attachment.file.confirmation.delete.title": "Datei löschen",
    "product.attachment.file.confirmation.delete.message": "Wollen Sie das Datei wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "product.attachment.file.dialog.title": "Datei hochladen",
    "product.attachment.file.dialog.visibility": "Öffentlich",
    "product.emptyProducts": "Es wurden keine Produkte gefunden!",
    "product.filters.title": "Produktsuche",
    "product.filters.term": "Suchbegriff",
    "product.filters.name": "Name",
    "product.filters.supplier": "Hersteller",
    "product.filters.series": "Produktlinie",
    "product.filters.productTypes": "Produkttypen",
    "product.filters.trades": "Gewerke",
    "product.filters.search": "Suche",
    "product.filters.productCatalog": "Produktkatalog",
    "product.actions.add.label": "Produkt anlegen",
    "product.actions.edit.label": "Produkt bearbeiten",
    "product.actions.clone.label": "Produkt duplizieren",
    "product.actions.removeImage.label": "Produktbild entfernen",
    "product.actions.uploadImage.label": "Produktbild hochladen",
    "product.actions.addSupplier.label": "Hersteller hinzufügen",
    "product.actions.removeDialog.title": "Produkt löschen",
    "product.actions.removeDialog.message": "Wollen Sie das Produkt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "product.actions.undeletableDialog.title": "Produkt nicht löschbar",
    "product.actions.undeletableDialog.message": "Die Produkt wird bereits in Produktgruppen verwendet und kann nicht mehr gelöscht werden.",
    "product.actions.removeImageDialog.title": "Produktbild löschen",
    "product.actions.removeImageDialog.message": "Wollen Sie das Produktbild wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "product.actions.editSpecificationDialog.title": "Leistungsbeschreibung bearbeiten",
    "product.actions.editSpecificationDialog.removeConfirmation.title": "Leistungsbeschreibung zurücksetzen",
    "product.actions.editSpecificationDialog.removeConfirmation.message": "Wollen Sie die Leistungsbeschreibung für dieses Gewerk auf den Standard zurücksetzen?",
    "product.actions.addCatalog.label": "Produktkatalog hinzufügen",
    "product.catalogDialog.title": "Neuen Produktkatalog anlegen",
    "product.catalogDialog.attributes.name.label": "Name",
    "product.catalogDialog.attributes.description.label": "Beschreibung",
    "product.attributes.customFields.label": "Zusatzdaten",
    "product.attributes.customFields.empty": "Keine Zusatzdaten",
    "product.attributes.name.label": "Name",
    "product.attributes.display_name.label": "Name Online Konfigurator",
    "product.attributes.display_name.hint": "Im Online Konfigurator angezeigter Name",
    "product.attributes.display_hint.label": "Hinweis Online Konfigurator",
    "product.attributes.display_hint.hint": "Im Online Konfigurator angezeigter Hinweis",
    "product.attributes.description.label": "Produktbeschreibung",
    "product.attributes.description.specifications": "Leistungsbeschreibungen",
    "product.attributes.description.none": "Ohne Beschreibung",
    "product.attributes.technical_description.label": "Angebotstext",
    "product.attributes.sku.label": "Produktcode",
    "product.attributes.sku.none": "Ohne Produktcode",
    "product.attributes.supplier.label": "Hersteller",
    "product.attributes.supplier.placeholder": "Hersteller",
    "product.attributes.supplier.none": "Ohne Hersteller",
    "product.attributes.series.label": "Produktlinie",
    "product.attributes.series.none": "Ohne Produktlinie",
    "product.attributes.images.label": "Produktbilder",
    "product.attributes.references.label": "Verwendung",
    "product.attributes.references.missing": "Dieses Produkt wird derzeit in keiner Produktgruppe verwendet.",
    "product.attributes.custom_product_code.label": "Interner Produktcode",
    "product.attributes.supplier_price.label": "Preisempfehlung",
    "product.attributes.unit_price.label": "Einheitspreis",
    "product.attributes.product_type.label": "Produkttyp",
    "product.attributes.product_tags.label": "Tags",
    "product.attributes.product_tags.placeholder": "Tags auswählen",
    "product.attributes.design_line_ids.label": "Designlinien",
    "product.attributes.design_line_ids.placeholder": "Designlinien auswählen",
    "product.attributes.specifications.trade.specification": "Leistungsbeschreibung nach Gewerk",
    "product.attributes.supplier_url.label": "URL Produktwebsite",
    "product.attributes.vr_model_url.label": "URL VR Model (ifc, gltf, fbx)",
    "product.attributes.internal_hint.label": "Interner Hinweis",
    "product.attributes.color_code.label": "Color code",
    "product.attributes.master_product_id.label": "Master product",
    "product.attributes.product_catalog_id.label": "Produktkatalog",
    "product.attributes.external_id.label": "Externe ID",
    "product.attributes.external_id.placeholder": "z.B. 123",
    "price_catalog.title.add": "Preisliste anlegen",
    "price_catalog.title.edit": "Preisliste bearbeiten",
    "price_catalog.attributes.name.label": "Name",
    "price_catalog.attributes.name.placeholder": "Name der Preisliste",
    "price_catalog.attributes.source_id.label": "Datenübernahme",
    "price_catalog.attributes.source_id.placeholder": "Ohne Datenübernahme",
    "price_catalog.attributes.project_ids.label": "Projektzugriff",
    "price_catalog.attributes.project_ids.placeholder": "In welchen Projekten darf der Preiskatalog verwendet werden?",
    "price_catalog.attributes.external_id.label": "Externe ID",
    "price_catalog.attributes.external_id.placeholder": "z.B. 123",
    "project_catalog.title.add": "Ausstattungskatalog anlegen",
    "project_catalog.title.edit": "Ausstattungskatalog bearbeiten",
    "project_catalog.title.upload": "Ausstattungskatalog hochladen",
    "project_catalog.title.as_design_line": "Ausstattungskatalog als Designlinie",
    "project_catalog.attributes.name.label": "Name",
    "project_catalog.attributes.name.placeholder": "Name des Ausstattungskatalogs",
    "project_catalog.attributes.slug.label": "Slug",
    "project_catalog.attributes.slug.placeholder": "Slug des Ausstattungskatalogs",
    "project_catalog.attributes.product_catalog_id.label": "Produktkatalog",
    "project_catalog.attributes.product_catalog_id.placeholder": "Produktkatalog",
    "project_catalog.attributes.source_id.label": "Datenübernahme",
    "project_catalog.attributes.source_id.placeholder": "Ohne Datenübernahme",
    "project_catalog.attributes.project_ids.label": "Projektzugriff",
    "project_catalog.attributes.project_ids.placeholder": "In welchen Projekten darf der Ausstattungskatalog verwendet werden?",
    "project_catalog.attributes.user_ids.label": "Bearbeiter",
    "project_catalog.attributes.user_ids.placeholder": "Alle Benutzer",
    "project_catalog.attributes.no_current_user_id_warning_message": "Achtung: Derzeit sind Sie selbst nicht mehr als Bearbeiter aufgeführt. Nachdem Sie diese Änderung vorgenommen haben, können Sie den Katalog nicht mehr bearbeiten.",
    "project_catalog.actions.edit.label": "Ausstattungskatalog bearbeiten",
    "project_catalog.actions.upload.label": "Ausstattungskatalog hochladen",
    "product_group.title.one": "Produktgruppe",
    "product_group.title.other": "Produktgruppen",
    "product_group.actions.download_project_catalog": "Download Projektkatalog",
    "product_group.actions.download_price_catalog": "Download Preiskatalog",
    "product_group.actions.bulks_price_change": "Kosten und Preisen aktualisieren",
    "product_group.actions.download_product_catalog": "Download Produktkatalog",
    "product_group.actions.download_3d_products": "Download 3D-Produkte",
    "product_group.actions.supplier_integration": "Herstellerkataloge",
    "product_group.actions.add.label": "Produktgruppe anlegen",
    "product_group.actions.edit.label": "Produktgruppe bearbeiten",
    "product_group.actions.removeDialog.title": "Produktgruppe löschen",
    "product_group.actions.removeDialog.message": "Wollen Sie die Produktgruppe wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "product_group.actions.undeletableDialog.title": "Produktgruppe nicht löschbar",
    "product_group.actions.undeletableDialog.message": "Die Produktgruppe wird bereits in Raumbüchern verwendet und kann nicht mehr gelöscht werden.",
    "product_group.search.title": "Produkte importieren",
    "product_group.attributes.name.label": "Bezeichnung",
    "product_group.attributes.desciption.label": "Beschreibung",
    "product_group.attributes.price_strategy.label": "Preisberechnung",
    "product_group.attributes.price_strategy.alternate_label": "Einheit",
    "product_group.attributes.glencoe_product_category.label": "Produktkategorie 3D Mapping (intern)",
    "product_group.attributes.glencoe_product_category.placeholder": "Ordnen Sie eine 3D Produktkategoerie zu wenn Produktgruppe für 3D Relevant ist",
    "product_group.attributes.trades.label": "Gewerke",
    "product_group.attributes.external_id.label": "Externe ID",
    "product_group.attributes.external_id.placeholder": "z.B. 123",
    "product_group.attributes.room_types.label": "Für Räume",
    "product_group.attributes.room_types.placeholder": "Alle",
    "product_group.attributes.portal_offer.label": "Verfügbar im Online-Konfigurator",
    "product_group.attributes.portal_offer.hints.hidden": "Diese Produktgruppe ist im Online Konfigurator nicht sichtbar.",
    "product_group.attributes.portal_offer.hints.shown": "Diese Produktgruppe ist im Online Konfigurator sichtbar.",
    "product_group.attributes.marketing_portal_offer.label": "Verfügbar im Marketing Portal",
    "product_group.attributes.marketing_portal_offer.hints.hidden": "Diese Produktgruppe ist im Marketing Portal nicht sichtbar.",
    "product_group.attributes.marketing_portal_offer.hints.shown": "Diese Produktgruppe ist im Marketing Portal sichtbar.",
    "product_group.attributes.quantity_on_request.label": "Mengenermittlung nur auf Anfrage für den Erwerber",
    "product_group.attributes.quantity_hidden.label": "Mengen im Online Konfigurator ausblenden",
    "product_group.attributes.product_group_tags.label": "Tags",
    "product_group.attributes.product_group_tags.placeholder": "Tags auswählen",
    "product_group.attributes.costs.label": "Kosten",
    "product_group.attributes.price.label": "Preise",
    "product_group.price_strategies.title": "Preisberechnung",
    "product_group.price_strategies.choose_price_strategy": "Wählen Sie die genutzte Preisberechnung für den Individualwunsch",
    "product_group.price_strategies.on_request": "Preis auf Anfrage",
    "product_group.price_strategies.flat.label": "Pauschalpreis",
    "product_group.price_strategies.flat.price": "Preis (pauschal)",
    "product_group.price_strategies.flat.per_unit": " psch",
    "product_group.price_strategies.area.label": "Fläche (qm)",
    "product_group.price_strategies.area.price": "Preis (€) / qm",
    "product_group.price_strategies.area.per_unit": "/qm",
    "product_group.price_strategies.length.label": "Länge (lfm)",
    "product_group.price_strategies.length.price": "Preis (€) / lfm",
    "product_group.price_strategies.length.per_unit": "/lfm",
    "product_group.price_strategies.pieces.label": "Stück",
    "product_group.price_strategies.pieces.price": "Preis (€) / Stück",
    "product_group.price_strategies.pieces.per_unit": "/stk",
    "product_group.price_strategies.hour.label": "Stunde",
    "product_group.price_strategies.hour.price": "Preis (€) / Stunde",
    "product_group.price_strategies.hour.per_unit": "/std",
    "product_group.missing.short": "Keine Produktgruppen vorhanden",
    "product_group.missing.long": "Es wurden für dieses Bauprojekt noch keine Produktgruppen angelegt.",
    "product_group.warnings.productCategories.title": "Error loading categories",
    "product_group.warnings.productCategories.body": "Product categories coulden't be loaded.",
    "product_group.warnings.trades.title": "Für die folgenden Gewerke sind noch Kosten hinterlegt:",
    "product_group.warnings.trades.suggestion": "Setzen Sie diese Kosten zunächst auf 0,00 bevor Sie die Gewerke entfernen.",
    "product_group.warnings.segment.title": "Warnungen",
    "product_group.warnings.segment.message": "An der Produktoption <strong>{name}</strong> existieren Kosten für die Gewerke <strong>{trades}</strong>. Diese Gewerke sind dem Produkt derzeit nicht zugewiesen.",
    "product_group.warnings.segment.deleteCosts": "Kosten löschen",
    "product_group.warnings.segment.changeProduct": "Produkt ändern",
    "product_group.warnings.bulks_price_change.title": "Hinweis",
    "product_group.warnings.bulks_price_change.message": "Wenn Produkte Kosten für mehrere Gewerke haben, wird jeweils der höherer Aufschlag angewandt.",
    "product_group.menu.overview": "Produktübersicht",
    "product_group.menu.localProductSearch": "Aus Produktkatalog",
    "product_group.menu.globalProductSearch": "Neu importieren",
    "product_group.menu.addCustomProduct": "Manuell hinzufügen",
    "product_group.menu.additionalResultsHint": "Es werden {resultsShown} von insgesamt {resultsTotal} Ergebnissen angezeigt.",
    "product_filter_tags.title": "Produktfilter Tags verwalten",
    "design_lines.title": "Designlinien",
    "design_lines.add": "Linie anlegen",
    "design_lines.uploadImage": "Bild hochladen",
    "design_lines.dialog.title": "Neue Designlinie",
    "design_lines.dialog.name.label": "Name",
    "design_lines.dialog.title_field.label": "Anzeigename",
    "design_lines.dialog.title_action.label": "Titel des Auswahldialogs",
    "design_lines.dialog.description.label": "Beschreibung",
    "design_lines.dialog.confirmation_message.label": "Hinweis bei abweichender Produktauswahl",
    "design_lines.dialog.image.label": "Bild",
    "design_lines.dialog.summary.label": "Kurzbeschreibung",
    "design_lines.removeConfirmation.title": "Designlinie löschen",
    "design_lines.removeConfirmation.message": "Wollen Sie die Designlinie wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "design_lines.groups.add": "Gruppe anlegen",
    "design_lines.groups.sort_by.label": "Sortierung im Online Konfigurator",
    "design_lines.groups.sort_by.price": "Preis",
    "design_lines.groups.sort_by.position": "Position",
    "design_lines.groups.dialog.title": "Neue Designliniengruppe",
    "design_lines.groups.dialog.severity.label": "Verhalten",
    "design_lines.groups.dialog.scope.label": "Reichweite",
    "design_lines.groups.removeConfirmation.title": "DesignLinienGruppe löschen",
    "design_lines.groups.removeConfirmation.message": "Wollen Sie die DesignLinienGruppe wirklich löschen. Die Aktion kann nicht rückgängig gemacht werden.",
    "design_lines.severity.recommendation": "Empfehlung",
    "design_lines.severity.constraint": "Einschränkung",
    "design_lines.scope.room": "Raum",
    "design_lines.scope.unit": "Wohnung",
    "product_option.attributes.external_id.label": "Externe ID",
    "product_option.attributes.external_id.placeholder": "z.B. 123",
    "product_price.title.one": "Produkt",
    "product_price.title.other": "Produkte",
    "product_price.filter.active.label": "Nur aktive anzeigen",
    "product_price.filter.inactive.label": "Nur inaktive anzeigen",
    "product_price.filter.all.label": "Alle anzeigen",
    "product_price.actions.add.label": "Produkt hinzufügen",
    "product_price.actions.edit.label": "Produktpreis bearbeiten",
    "product_price.actions.removeDialog.title": "Produktpreis löschen",
    "product_price.actions.removeDialog.message": "Wollen Sie den Produktpreis wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "product_price.actions.undeletableDialog.title": "Produktpreis nicht löschbar",
    "product_price.actions.undeletableDialog.message": "Der Produktpreis wird bereits in Raumbüchern verwendet und kann nicht mehr gelöscht werden.",
    "product_price.actions.toggleExcessPrice.label": "Mehrmengen mit abweichendem Einheitspreis berechnen",
    "product_price.attributes.product.label": "Produkt",
    "product_price.attributes.price.gross": "Preis (brutto)",
    "product_price.attributes.price.net": "Preis (netto)",
    "product_price.attributes.price.buyer.gross": "Erwerberpreise (brutto)",
    "product_price.attributes.price.buyer.net": "Erwerberpreise (netto)",
    "product_price.attributes.price.unit.gross": "Einheitspreis (brutto)",
    "product_price.attributes.price.unit.net": "Einheitspreis (netto)",
    "product_price.attributes.price.unit.cost": "Einheitskosten (netto)",
    "product_price.attributes.excess_price.label": "Preis für Mehrmengen (brutto)",
    "product_price.attributes.trade.label": "Gewerk",
    "product_price.attributes.cost.label": "Kosten (netto)",
    "product_price.attributes.excess_cost.label": "Kosten Mehrmenge (netto)",
    "product_price.attributes.gross_cost.label": "Kosten (brutto)",
    "product_price.attributes.gross_excess_cost.label": "Kosten Mehrmenge (brutto)",
    "product_price.attributes.total_cost.label": "Gesamtkosten (netto)",
    "product_price.attributes.portal_offer.label": "Verfügbar im Online-Konfigurator",
    "product_price.attributes.glencoe_product.label": "Verfügbar im 3D-Konfigurator",
    "product_price.attributes.glencoe_product.hint": "Dieses Produkt soll im 3D Konfigurator konfigurierbar sein.",
    "product_price.attributes.glencoe_product_id.label": "3D Parameter",
    "product_price.attributes.glencoe_product_id.hint": "Lesbarer Parameter für das 3D Mapping.",
    "product_price.attributes.glencoe_product_id.error": "Pflichtfeld - nur Buchstaben, Zahlen und \"-\" sind erlaubt",
    "product_price.attributes.on_request.label": "Preis auf Anfrage",
    "product_price.attributes.on_request.hint": "Preis für den Erwerber nur auf Anfrage",
    "product_price.attributes.excess_quantity.label": "für Mehrmenge",
    "product_price.attributes.default_quantity.label": "für Standardmenge",
    "product_price.search.placeholder": "Name, Hersteller, Produktnummer, Beschreibung, ...",
    "product_price.search.supplier": "Hersteller",
    "product_price.search.productSeries": "Produktserie",
    "product_price.search.productType": "Produkttyp",
    "product_price.search.results.few.title": "Es wurden nur wenige Produkte zu diesem Suchbegriff gefunden.",
    "product_price.search.results.few.content": "Suche im globalen Herstellerkatalog fortsetzen",
    "product_price.search.results.none.title": "Es wurde kein Produkt zu diesem Suchbegriff gefunden.",
    "product_price.search.results.none.content": "Suche im globalen Herstellerkatalog fortsetzen",
    "product_price.undeletable": "Das Produkt wird bereits verwendet und kann nicht gelöscht werden.",
    "product_price.missing": "Es wurde noch keine Produkte hinzugefügt. Eine Produkt Gruppe muss mindestens ein Produkt enthalten, damit diese verwendet werden kann.",
    "product_price.products.empty": "Die Produktliste ist leer.",
    "productRules.headers.when": "Wenn",
    "productRules.headers.then": "Dann",
    "productRules.headers.status": "Aktiviert",
    "productRules.headers.scope": "Geltungsbereich",
    "productRules.headers.title": "Produktregel",
    "productRules.headers.description": "Beschreibung",
    "productRules.headers.hint": "Hinweis Raumbuch/Onlinekonfigurator",
    "productRules.status.active": "Regel ist aktiv",
    "productRules.status.deactivated": "Regel ist deaktiviert",
    "productRules.scopes.line_item": "Position",
    "productRules.scopes.sub_section": "Teilabschnitt",
    "productRules.scopes.section": "Hauptabschnitt",
    "productRules.scopes.room_book": "Raumbuch",
    "productRules.actions.removeDialog.title": "Regelsatz löschen",
    "productRules.actions.removeDialog.message": "Wollen Sie diesen Regelsatz löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "productRules.error.notInProductGroup": "Produkt gehört nicht zu dieser Produktgruppe!",
    "productRules.error.ruleViolation": "Diese Auswahl verletzt eine Produktregel mit der Position <strong>{sub_line_item_title}</strong>.",
    "productRules.warning.notAllAssinged": "Hinweis: Es wurde noch nicht für jedes Produkt eine Regel definiert.",
    "productRules.warning.productRepeating": "Folgende Produkte werden mehrfach auf der linken Regelseite verwendet. Dies wird zu Konflikten in Raumbuch und Online Konfigurator führen.",
    "login.title": "Anmeldung",
    "login.email": "Email",
    "login.password": "Passwort",
    "login.signIn": "Anmelden",
    "login.backToLogin": "Zurück zum Login",
    "login.error.title": "Hoppla!",
    "login.error.credentials": "Ungültige E-Mail oder Passwort",
    "login.error.unknown": "Es ist ein unbekannter Fehler aufgetreten, bitte Kontaktieren Sie Ihren technischen Support",
    "login.success.login": "Sie wurden erfolgreich Angemeldet",
    "login.success.requestPassword": "Wenn die Email in unserem System registriert ist, haben Wir Ihnen eine Mail zugesandt. Sollten sie in den nächsten Minuten kein Neueingang in Ihrem Postfach finden, prüfen Sie bitte die angegebene Email-Adresse.",
    "login.success.newPassword": "Ihr neues Passwort wurde erfolgreich gespeichert. Sie werden nun automatisch eingeloggt!",
    "login.success.generic": "Operation erfolgreich",
    "login.noAccountYet": "Noch kein Account?",
    "login.createAccountHint": "Sollten Sie noch keinen Account haben, prüfen Sie bitte Ihren Posteingang. Sie erhalten bei Einladung eine zusätzliche E-Mail, in der Sie das Passwort erstmalig vergeben können.",
    "login.forgottenPassword": "Passwort vergessen?",
    "login.newPasswordTitle": "Neues Passwort festlegen",
    "login.newPassword": "Neues Passwort",
    "login.newPasswordHint": "Bitte wählen Sie ihr neues Passwort und bestätigen es, um Tippfehler auszuschließen.",
    "login.verifyPassword": "Passwort bestätigen",
    "login.saveNewPassword": "Neues Passwort speichern",
    "login.enterEmail": "Bitte geben Sie Ihre E-Mail Adresse ein",
    "login.enterPassword": "Bitte geben Sie Ihr Passwort ein",
    "login.nextStep": "Weiter",
    "login.loginProcess": "Anmelden",
    "login.loginProcessEmailDescription": "Bitte geben Sie Ihre E-Mail Adresse an, um sich an der bau digital Platform anzumelden.",
    "login.loginProcessPasswordDescription": "Bitte geben Sie Ihr Passwort ein, um sich an der bau digital Platform anzumelden.",
    "login.email2": "E-Mail Adresse",
    "login.signToDifferentAccount": "Bei einem anderen Account anmelden",
    "login.authRedirect.signingIn": "Sie werden angemeldet",
    "login.deleteData.label": "Lokale Daten Löschen",
    "login.deleteData.success.message": "Lokale Daten wurden gelöschen",
    "login.deleteData.error.message": "Lokale Daten konnten nicht gelöscht werden",
    "dashboard.title": "PLANO-Dashboard",
    "dashboard.welcome": "Willkommen",
    "dropzone.defaultMessage": "Bild-Datei in dieses Feld ziehen",
    "dropzone.fallbackMessage": "Ihr Browser unterstützt keine Drag & Drop Datei-Uploads, bitte benutzen Sie eine aktuelle Version von Firefox oder Chrome.",
    "dropzone.invalidFileType": "Dieser Dateityp kann hier nicht verwendet werden.",
    "dropzone.fileTooBig": "Die ausgewählte Datei ist mit {{filesize}} zu gross. Die maximale Dateigröße beträgt {{maxFilesize}}.",
    "dropzone.responseError": "Der Server meldet einen Fehler zurück: {{statusCode}}",
    "dropzone.cancelUpload": "Upload abbrechen",
    "dropzone.cancelUploadConfirmation": "Wirklich abbrechen?",
    "dropzone.removeFile": "Bild entfernen",
    "dropzone.maxFilesExceeded": "Die maximale Anzahl der gleichzeitig hochzuladenden Dateien wurde überschritten.",
    "message.from": "Von",
    "message.to": "An",
    "message.call": "Gespräch mit",
    "message.success.title": "Erfolg",
    "message.success.body": "Der Vorgang wurde erfolgreich abgeschlossen.",
    "message.warning.title": "Achtung",
    "message.warning.body": "Es liegt eine Warnung vor.",
    "message.reply.subjectTemplate": "{prefix} {subject}",
    "message.reply.bodyTemplate": "\\n\\n\\nAm {date} schrieb {sender}:\\n\\n> {body}",
    "message.reply.bodyTemplateDraft": "{body}",
    "message.error.title": "Hoppla!",
    "message.error.body": "Hier ist etwas schiefgelaufen. Ein Fehler ist aufgetreten.",
    "message.error.body_long": "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "message.errorForm.at_least_n_chars": "muss mindestens aus {n} Zeichen bestehen",
    "message.errorForm.max_n_chars": "maximal {n} Zeichen",
    "message.errorForm.required": "Pflichtfeld",
    "message.errorForm.url_format": "Ungültige URL",
    "message.errorForm.identifierFormat": "Nur Kleinbuchstaben, Zahlen und Punkte sind erlaubt.",
    "message.errorForm.email": "E-mail",
    "message.errorForm.mobile": "Bitte Nummer mit Ländervorwahl eingeben, z.B. +491560000000",
    "message.errorLogout.title": "Hoppla!",
    "message.errorLogout.body": "Hier ist etwas schiefgelaufen. Die Abmeldung ist gescheitert.",
    "message.errorLoad.title": "Hoppla!",
    "message.errorLoad.body": "Hier ist etwas schiefgelaufen. Die Daten konnten nicht geladen werden",
    "message.errorBackend.title": "Hoppla!",
    "message.errorBackend.body": "{translatedBody}",
    "message.display_error.title": "Hoppla!",
    "message.display_error.body": " Es ist folgender Fehler aufgetreten: {translatedBody}",
    "message.errorUnauthorized.title": "Ihre Sitzung ist abgelaufen.",
    "message.errorUnauthorized.body": "Sie wurden abgemeldet weil Ihre Sitzung die maximale Zeit überschritten hat. Bitte melden Sie sich erneut an.",
    "message.errorSave.title": "Speichern fehlgeschlagen",
    "message.errorSave.body": "Die Daten konnten nicht gespeichert werden.",
    "message.errorDelete.title": "Löschen fehlgeschlagen",
    "message.errorDelete.body": "Die Daten konnten nicht gelöscht werden.",
    "message.logout.title": "Auf Wiedersehen",
    "message.logout.body": "Sie wurden abgemeldet.",
    "message.loginDenied.title": "Es gab einen Fehler bei der Anmeldung",
    "message.loginDenied.body": "Die Zugangsdaten wurden nicht akzeptiert.",
    "message.redirectBuyerApp.title": "Sie werden in 5 Sekunden zu ihrer Anmeldungsseite weitergeleitet.",
    "message.redirectBuyerApp.body": "Bitte nutzen Sie die Anmeldungsseite, die Ihnen per Email mitgeteilt wurde.",
    "message.authenticationFailed.title": "Hoppla!",
    "message.authenticationFailed.body": "Die Anmeldung ist fehlgeschlagen. Bitte versuchen Sie es erneut.",
    "message.passwordRequestFailed.title": "Ein Fehler ist aufgetreten.",
    "message.passwordRequestFailed.body": "Die Anleitung konnte Ihnen leider nicht zugesendet werden. Wir wurden informiert und werden das Problem schnellstmöglich lösen.",
    "message.passwordResetFailed.title": "Hoppla!",
    "message.passwordResetFailed.body": "Ihr neues Passwort konnte nicht gespeichert werden. Wir wurden informiert und werden das Problem schnellstmöglich lösen.",
    "message.unitNotFound.title": "Hoppla!",
    "message.unitNotFound.body": "Die Wohneinheit konnte nicht gefunden werden.",
    "message.roomBookNotFound.title": "Hoppla!",
    "message.roomBookNotFound.body": "Es liegt kein Ausstattungskatalog für diese Wohneinheit vor.",
    "message.searchFailed.title": "Suche fehlgeschlagen!",
    "message.searchFailed.error": "Es ist ein Fehler beim Suchen aufgetreten: {error}",
    "message.searchFailed.unknown": "Es ist ein unbekannter Fehler beim Suchen aufgetreten",
    "message.on_office.title": "Übertragung an On Office",
    "message.on_office.error": "Es ist ein Fehler bei der Übertragung zu OnOffice aufgetreten: {error}",
    "message.on_office.success": "Die Daten wurden erfolgreich übertragen",
    "message.on_office.unknown": "Es ist ein unbekannter Fehler bei derÜbertragung zu OnOffice aufgetreten",
    "message.file_upload.success.title": "Upload abgeschlossen",
    "message.file_upload.success.body": "Die Datei wurde erfolgreich gespeichert",
    "message.file_upload.error.title": "Upload Fehler",
    "message.file_upload.error.body": "{msg}",
    "message.file_upload.error.time.title": "Es dauert zu lange",
    "message.file_upload.error.time.body": "Die Datenverarbeitung dauert zu lange",
    "message.invalid_mapping.title": "Ungültiges Mapping",
    "message.invalid_mapping.body": "Es gibt kein Mappimg für \"{paramName}\"",
    "loadingIndicator.loading": "Daten werden geladen...",
    "error.pageNotFound.title": "Diese Seite wurde nicht gefunden.",
    "error.accessDenied.title": "Sie haben leider keinen Zugriff auf diese Funktion.",
    "activerecord.errors.messages.record_not_unique": "Datensatz ist nicht eindeutig!",
    "activerecord.errors.models.attachment.attributes.file.spoofed_media_type": "Falscher Medien-Typ erkannt. Bitte überprüfen Sie die Dateiendung.",
    "activerecord.errors.models.buyer.attributes.mobile.invalid": "Bitte nutzen Sie das internationale Format, z.B. +490170111111",
    "activerecord.errors.models.macro.attributes.subject_template.no_whitespace_in_template_vars": "Bitte entfernen Sie die Leerzeichen in den Variablen der Vorlage \"Betreff\"",
    "activerecord.errors.models.macro.attributes.body_template.no_whitespace_in_template_vars": "Bitte entfernen Sie die Leerzeichen in den Variablen der Vorlage \"Nachricht\"",
    "activerecord.errors.models.product_group_rule_set.attributes.product_group_then.cannot_be_same": "darf nicht gleich der \"wenn\" Produktgruppe sein",
    "activerecord.errors.models.product_group_rule_set.attributes.rules.illegal_product_when": "\"wenn\" enthält falsche Produkte",
    "activerecord.errors.models.product_group_rule_set.attributes.rules.illegal_product_then": "\"dann\" enthält falsche Produkte",
    "activerecord.errors.models.product_group_rule_set.attributes.rules.illegal_products_when": "\"wenn\" enthält falsche Produkte",
    "activerecord.errors.models.product_group_rule_set.attributes.rules.illegal_products_then": "\"dann\" enthält falsche Produkte",
    "activerecord.errors.models.product_group_rule_set.attributes.rules.illegal_products": "enthält falsche Produkte",
    "activerecord.errors.models.product_group_rule_set.attributes.rules.format": "hat das falsche Format",
    "activerecord.errors.models.room_book_sub_line_item.not_bulk_updateable": "Position kann nicht bearbeitet werden, da abhängige Vorgänge existieren.",
    "activerecord.errors.models.room_book_sub_line_item.not_valid_project_catalog": "Ausstattungskatalog muss in Produktgruppe und Raumbuch gleich sein.",
    "activerecord.errors.models.room_book_line_item.not_bulk_updateable": "Position kann nicht bearbeitet werden, da abhängige Vorgänge existieren.",
    "activerecord.errors.models.product.attributes.product_manager_id.taken": "Ein anderes Produkt ist bereits mit diesen Daten synchronisiert!",
    "activerecord.models.Contractor": "Nachunternehmer",
    "activerecord.models.ContractorAssignment": "Nominierte Nachunternehmer",
    "activerecord.models.ContractorAssignmentRule": "Nachunternehmerzuweisung für Projekte, Bauabschnitte oder Wohneinheiten",
    "activerecord.models.Cost": "Kostenpositionen in Produkten oder Individualwünschen",
    "activerecord.models.PriceInquiry": "Digitale Preisanfragen",
    "activerecord.models.Product": "Produkte",
    "activerecord.models.ProductGroup": "Produktgruppen",
    "activerecord.models.ProductSpecification": "Leistungsbeschreibungen",
    "activerecord.models.ProjectDeadline": "Projektfristen",
    "activerecord.models.mail_template.one": "E-Mail Vorlage",
    "activerecord.models.mail_template.other": "E-Mail Vorlagen",
    "activerecord.models.cart.one": "Vorgang",
    "activerecord.models.cart.other": "Vorgänge",
    "activerecord.models.buyer_selection.one": "Erwerberauswahl",
    "activerecord.models.buyer_selection.other": "Erwerberauswahlen",
    "activerecord.attributes.mail_template.name": "Name",
    "activerecord.attributes.mail_template.role": "Rolle",
    "activerecord.attributes.mail_template.projects": "Projekte",
    "activerecord.attributes.mail_template.subject": "Betreff",
    "activerecord.attributes.mail_template.text": "Text",
    "activerecord.attributes.mail_template.html": "HTML",
    "activerecord.attributes.mail_template.sender_label": "Sender label",
    "activerecord.attributes.project_config_selection.exports_config": "Exporte",
    "activerecord.attributes.project_config_selection.emails_config": "E-Mails",
    "activerecord.attributes.project_config_selection.hooks_config": "Hooks",
    "activerecord.attributes.product_group_rule_set.product_group_then": "\"Dann\"-Produktgruppe",
    "activerecord.attributes.product_group_rule_set.product_group_when": "\"Wenn\"-Produktgruppe",
    "activerecord.attributes.product_group_rule_set.rules": "Regeln",
    "activerecord.attributes.product_group_rule_set.rule_scope": "Geltungsbereich",
    "activerecord.attributes.room_book_sub_line_item.carts": "Vorgänge",
    "activerecord.attributes.room_book_sub_line_item.derivations": "Ableitungen",
    "activerecord.attributes.room_book_line_item.derivations": "Ableitungen",
    "activerecord.attributes.room_book_section.derivations": "Ableitungen",
    "activerecord.attributes.product_price.buyer_selections": "Erwerberauswahlen",
    "buyerPortal.label.one": "Erwerberportal",
    "buyerPortal.label.other": "Erwerberportale",
    "buyerPortal.menu.units": "Meine Wohnungen",
    "buyerPortal.menu.products": "Ausstattung",
    "buyerPortal.menu.deadlines": "Fristen",
    "buyerPortal.menu.attachments": "Dokumente",
    "buyerPortal.menu.orderStatus": "Übersicht",
    "buyerPortal.menu.login": "Anmeldung",
    "buyerPortal.menu.logout": "Abmelden",
    "buyerPortal.menu.password": "Passwort vergessen?",
    "buyerPortal.mobileMenu.units": "Wohnungen",
    "buyerPortal.mobileMenu.products": "Ausstattung",
    "buyerPortal.mobileMenu.attachments": "Dokumente",
    "buyerPortal.mobileMenu.orderStatus": "Übersicht",
    "buyerPortal.mobileMenu.logout": "Abmelden",
    "buyerPortal.messages.loading": "Ihre Wohnung wird geladen",
    "buyerPortal.messages.somethingWentWrong": "Hoppla, hier ist etwas schiefgelaufen.",
    "buyerPortal.messages.generic_error": "Bitte wenden Sie sich an Ihren Kundenberater.",
    "buyerPortal.messages.missing_configuration": "Dieser Bereich wurde noch nicht konfiguriert, bitte wenden Sie sich an Ihren Kundenberater.",
    "buyerPortal.messages.noItemsInStatus": "Keine Positionen in diesem Status.",
    "buyerPortal.messages.accept_terms": "Hiermit akzeptiere ich die <a role=\"link\" href=\"{terms_url}\" target=\"_terms\">Allgemeinen Geschäftsbedingungen</a>",
    "buyerPortal.messages.accept_data_policy": "Hiermit akzeptiere ich die <a role=\"link\" href=\"{policy_url} target=\"_terms\">Datenschutzbestimmungen</a>.",
    "buyerPortal.messages.accept_terms_and_data_policy": "Hiermit akzeptiere ich die <a role=\"link\" href=\"{terms_url}\" target=\"_terms\">Allgemeinen Geschäftsbedingungen</a> und die <a role=\"link\" href=\"{policy_url}\" target=\"_terms\">Datenschutzbestimmungen</a>.",
    "buyerPortal.dialogs.unit_completion.header": "Konfiguration abschliessen",
    "buyerPortal.dialogs.unit_completion.content.initial": "Ihr Kundenbetreuer wird über den Abschluss der Konfiguration informiert und kommt bzgl. weiterer Schritte in Kürze auf Sie zu.",
    "buyerPortal.dialogs.unit_completion.content.success": "Ihr Kundenbetreuer wurde über den Abschluss der Konfiguration informiert und kommt in Kürze auf Sie zu.",
    "buyerPortal.dialogs.unit_completion.undecided_hint.header": "Offene Entscheidungen",
    "buyerPortal.dialogs.unit_completion.undecided_hint.body": "Hinweis: Es sind noch nicht alle Entscheidungen in Ihrer Wohnung getroffen worden.",
    "buyerPortal.dialogs.unit_completion.checkbox_label": "Ich kläre die offenen Entscheidungen im Gespräch mit meinem Kundenbetreuer.",
    "buyerPortal.dialogs.unit_completion.message_label": "Ihre Nachricht an Ihren Kundenbetreuer",
    "buyerPortal.actions.configure": "Konfigurieren",
    "buyerPortal.actions.configured": "Konfiguriert",
    "buyerPortal.actions.backToOverview": "Zurück",
    "buyerPortal.actions.selectProduct": "Als Favorit kennzeichnen",
    "buyerPortal.actions.selectedProduct": "Mein Favorit",
    "buyerPortal.actions.acceptSelection": "Auswahl übernehmen",
    "buyerPortal.actions.beginConfiguration": "Bemusterung starten",
    "buyerPortal.actions.beginSampling": "Bemusterung beginnen",
    "buyerPortal.actions.continueSampling": "Bemusterung fortsetzten",
    "buyerPortal.actions.reEnterMyFlat": "Meine Wohnung betreten",
    "buyerPortal.actions.getOffer": "Angebot einholen",
    "buyerPortal.actions.viewOffer": "Angebot ansehen",
    "buyerPortal.status.begin": "Los Geht's!",
    "buyerPortal.status.sampling": "Bemusterung",
    "buyerPortal.status.offer": "Angebot einholen",
    "buyerPortal.status.pending": "Entscheidung offen",
    "buyerPortal.status.chosen": "Produkt ausgewählt",
    "buyerPortal.status.selected": "Auswahl wurde getroffen",
    "buyerPortal.status.notSelected": "Verworfen",
    "buyerPortal.status.approved": "Kosten freigegeben",
    "buyerPortal.status.progress": "Umsetzung in Arbeit",
    "buyerPortal.status.done": "Umgesetzt",
    "buyerPortal.orderStatus.total": "Gesamtsumme (brutto)",
    "buyerPortal.attachments.header": "Dokumente",
    "buyerPortal.attachments.noResults": "Es wurden noch keine Dokumente für Sie bereitgestellt.",
    "buyerPortal.sections.total": "Mehrpreis der gesamten Auswahl inkl. MwSt.",
    "buyerPortal.sections.subtotal": "Mehrpreis {sectionTitle} inkl. MwSt.",
    "buyerPortal.sections.subtotalWithoutVat": "Mehrpreis {sectionTitle}",
    "buyerPortal.sections.priceOnRequestHint": "Für mindestens eine Auswahl liegt uns derzeit kein Preis vor. Diese Auswahl ist im Gesamtpreis nicht berücksichtigt.",
    "buyerPortal.sections.caution": "Hinweis",
    "buyerPortal.sections.onlyAdjustableByBuyer": "Die Auswahl kann nur durch den Erwerber geändert werden",
    "buyerPortal.footer.imprint": "Impressum",
    "buyerPortal.footer.dataPrivacyStatement": "Datenschutzerklärung",
    "buyerPortal.footer.terms": "Geschäftsbedingungen",
    "buyerPortal.roomBook.productOptions.messages.incompatible": "Ihr derzeitiger Produktwunsch ist mit der Auswahl <strong>{option}</strong> nicht kompatibel. Bitte ändern Sie Ihre Auswahl.",
    "buyerPortal.roomBook.productOptions.messages.missing": "Aufgrund Ihrer Auswahl <strong>{option}</strong> können wir Ihnen derzeit keine Produkte in dieser Kategorie zur Auswahl anbieten.",
    "buyerPortal.roomBook.productOptions.messages.filterHint": "<strong>Hinweis:</strong> Es werden nur Produkte angezeigt, die zu Ihrer Auswahl <strong>{option}</strong> kompatibel sind.",
    "buyerPortal.roomBook.productOptions.messages.unavailable": "<strong>Hinweis:</strong> Die nachfolgenden Produkte sind mit Ihrer Auswahl <strong>{option}</strong> nicht kompatibel und können derzeit nicht ausgewählt werden.",
    "buyerPortal.roomBook.productLine.messages.notRecommended": "Sie haben Produkte aus unterschiedlichen <strong>{type_plural} ({series})</strong> gewählt. Wir empfehlen Ihnen in einer {type} zu bleiben.",
    "buyerPortal.roomBook.productLine.messages.incompatible": "Produkte aus unterschiedlichen <strong>{type_plural} ({series})</strong> können nicht gemischt werden. Bitte ändern Sie Ihre Auswahl.",
    "buyerPortal.roomBook.productLine.messages.missing": "Aus der auswählten Ausstattungslinie <strong>{option}</strong> können wir Ihnen derzeit keine Produkte in dieser Kategorie anbieten.",
    "buyerPortal.roomBook.productLine.messages.filterHint": "<strong>Hinweis:</strong> Es werden nur Produkte der Ausstattungslinie <strong>{option}</strong> angezeigt.",
    "buyerPortal.roomBook.productLine.messages.unavailable": "<strong>Hinweis:</strong> Die nachfolgenden Produkte sind in der Ausstattungslinie <strong>{option}</strong> nicht verfügbar und können derzeit nicht ausgewählt werden. Wechseln Sie die Ausstattungslinie, wenn Sie eines dieser Produkte auswählen möchten.",
    "buyerPortal.roomBook.productLine.messages.required": "Bitte wählen Sie eine Ausstattungslinie bevor Sie mit der Bemusterung beginnen.",
    "buyerPortal.roomBook.productLine.actions.select": "Wählen Sie jetzt {displayName}",
    "buyerPortal.roomBook.buyerSelection.confirmation.title": "Ihre Auswahl",
    "buyerPortal.roomBook.buyerSelection.confirmation.message.reset": "Durch diese Auswahl müssen aufgrund baulicher Abhängigkeiten bereits getroffene Entscheidungen zurückgesetzt oder Standardleistungen durch alternative Leistungen ersetzt werden.",
    "buyerPortal.roomBook.buyerSelection.confirmation.message.extra": "Die Gesamtkosten Ihrer Auswahl ändert sich zusätzlich um {total}. Mehrkosten werden an den entsprechenden Stellen ausgewiesen und müssen von Ihnen bestätigt werden.",
    "buyerPortal.roomBook.buyerSelection.confirmation.message.extraOnRequest": "Hierdurch könnten zusätzliche Mehrkosten entstehen, die wir gerne individuell für Ihre Wohnung ermitteln. Dies wird an den betroffenen Positionen ausgewiesen.",
    "buyerPortal.roomBook.optionalLineItem.attributes.totalBase": "Basispreis",
    "buyerPortal.roomBook.optionalLineItem.hints.extraCosts": "Durch die weitergehende Konfiguration können weitere Kosten über den Basispreis hinaus entstehen.",
    "buyerPortal.roomBook.optionalLineItem.actions.select.label": "Alternative wählen",
    "buyerPortal.roomBook.optionalLineItem.actions.remove.title": "Sonderwunschpaket {title} entfernen",
    "buyerPortal.roomBook.optionalLineItem.actions.remove.message": "Wollen Sie wirklich das Sonderwunschpaket {title} aus Ihrer Wohnung entfernen?",
    "buyerPortal.roomBook.optionalLineItem.actions.noSelectionWarning.title": "Sonderwunschpaket {title} entfernen",
    "buyerPortal.roomBook.optionalLineItem.actions.noSelectionWarning.message": "Sie haben keine Auswahl getroffen, hierdurch wird das Sonderwunschpaket {title} wieder entfernt. Wollen Sie ohne Auswahl zurück?",
    "buyerPortal.roomBook.optionalLineItem.actions.add.title": "Sonderwunschpaket {title} hinzufügen",
    "buyerPortal.roomBook.optionalLineItem.actions.add.message": "Fügen Sie Ihrer Wohnung das optionale Sonderwunschpaket \"{title}\" hinzu.",
    "buyerPortal.roomBook.optionalLineItem.actions.replace.title": "Sonderwunschpaket {title} hinzufügen",
    "buyerPortal.roomBook.optionalLineItem.actions.replace.message": "Fügen Sie Ihrer Wohnung das optionale Sonderwunschpaket \"{title}\" hinzu.",
    "buyerPortal.roomBook.cardActions.decided": "Konfiguriert",
    "buyerPortal.roomBook.cardActions.undecided": "Konfigurieren",
    "buyerPortal.roomBook.cardActions.warning": "Ungültige Auswahl",
    "buyerPortal.roomBook.cardActions.disabled": "Nicht verfügbar",
    "buyerPortal.roomBook.cardActions.buyable": "Hinzufügen",
    "buyerPortal.roomBook.cardActions.replacement": "Alternative wählen",
    "buyerPortal.roomBook.cardActions.mandatory": "Pflichtposition",
    "buyerPortal.roomBook.price": "Preis",
    "buyerPortal.roomBook.sku": "Produktnr.",
    "buyerPortal.roomBook.filterByTradePlaceholder": "Filtern nach Gewerk",
    "buyerPortal.roomBook.noLineItems": "Dieser Abschnitt enthält keine Positionen für das ausgewählte Gewerk",
    "buyerPortal.productLineUpdate.confirmationDialog.title": "Wechsel der Ausstattungslinie",
    "buyerPortal.productLineUpdate.confirmationDialog.totalDebit": "Gesamt Mehrkosten: +",
    "buyerPortal.productLineUpdate.confirmationDialog.totalCredit": "Gesamt Minderkosten:",
    "buyerPortal.productLineUpdate.confirmationDialog.description": "<p>Sie wählen ein Produkt einer anderen Ausstattungslinie.</p> <p>Sie wechseln von der Ausstattungslinie {prevLabel} zu {nextLabel}</p> <p>Folgende Produkte der Ausstattungslinie {nextLabel} werden automatisch für Sie gewählt.</p>",
    "buyerPortal.productLineUpdate.confirmationDialog.actionHint": "<p>Wenn Sie fortfahren werden die oben gelisteten Produkte automatisch für Sie ausgewählt.</p>",
    "buyerPortal.productLineUpdate.confirmationDialog.actionLabel": "Weiter",
    "statistics.messages.empty.title": "Es liegen noch keine Kennzahlen vor",
    "projectDeadline.title.missing": "Es existieren noch keine Fristen auf dieser Ebene",
    "projectDeadline.actions.new": "Frist anlegen",
    "projectDeadline.actions.edit": "Frist bearbeiten",
    "projectDeadline.actions.override": "Jetzt überschreiben",
    "projectDeadline.actions.removeDialog.title": "Frist löschen",
    "projectDeadline.actions.removeDialog.message": "Wollen Sie diese und alle untergeordneten Fristen löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "projectDeadline.messages.close": "Bald fällig",
    "projectDeadline.messages.overdue": "Fällig",
    "projectDeadline.messages.expires_at": "Fristende: {expires_at}",
    "projectDeadline.attributes.title.label": "Titel",
    "projectDeadline.attributes.expires_at.label": "Fristende",
    "projectDeadline.attributes.expiration_scope.label": "Fristgegenstand",
    "projectDeadline.attributes.scheduled_for.label": "Frist für",
    "projectDeadline.attributes.trades.label": "Gewerkebezug",
    "projectDeadline.attributes.lock_trades.label": "Konfiguration für Gewerke sperren (Online Konfigurator)",
    "projectDeadline.attributes.description.label": "Beschreibung (Online Konfigurator)",
    "projectDeadline.attributes.reminder_active.label": "Automatische Erinnerung aktivieren",
    "projectDeadline.attributes.reminder_schedule.label": "Anzahl Tage vor Fristablauf",
    "projectDeadline.attributes.reminder_subject.label": "Betreff der Erinnerungsemail",
    "projectDeadline.attributes.reminder_text.label": "Nachricht der Erinnerungsemail",
    "projectDeadline.expiration_scopes.project.label": "Projekt",
    "projectDeadline.expiration_scopes.project.tooltip": "Die Frist bezieht sich auf das Projekt",
    "projectDeadline.expiration_scopes.section.label": "Bauabschnitt",
    "projectDeadline.expiration_scopes.section.tooltip": "Die Frist bezieht sich auf Bauabschnitte",
    "projectDeadline.expiration_scopes.unit.label": "Wohneinheit",
    "projectDeadline.expiration_scopes.unit.tooltip": "Die Frist bezieht sich auf Wohneinheiten",
    "emailTemplates.actions.removeDialog.title": "Email-Template löschen",
    "emailTemplates.actions.removeDialog.message": "Wollen Sie das Email-Template wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "resources.error.delete.title": "Die Daten konnten nicht gelöscht werden.",
    "resources.error.delete.body": "{reason}",
    "resources.error.update.title": "Die Daten konnten nicht gespeichert werden.",
    "resources.error.update.body": "{reason}",
    "messaging.title": "Nachricht",
    "messaging.recipient_options.no_email": "[Achtung: Keine E-Mail hinterlegt]",
    "messaging.attachment_picker.title": "Dateien einfügen",
    "messaging.attachment_picker.messages.documentTypeMissing": "Bitte wählen Sie zum Hochladen zunächst einen Dokumententyp aus.",
    "messaging.attachment_picker.messages.documentsMissing": "Keine Dokumente vorhanden. Bitte ändern Sie den Filter oder laden ein neues Dokument hoch.",
    "messaging.messages_container.messageThread.participantCount": "{participantCount, plural, =0 { } one { und ein weiterer} other { und # weitere}}",
    "messaging.messages_container.messageThread.attachmentCount": "{attachmentCount} {attachmentCount, plural, one {} other {}}",
    "messaging.messages_container.messageThread.messageCount": "{messageCount} {messageCount, plural, one {} other {}}",
    "messaging.messages_container.messageThread.confirmation.title": "Nachrichtenverlauf löschen",
    "messaging.messages_container.messageThread.confirmation.body": "Wollen Sie den Nachrichtenverlauf wirklich löschen. Diese Aktion kann nicht rückgängig gemacht werden.",
    "messaging.messages_container.buttons.write": "Schreiben",
    "messaging.messages_container.header.new_message": "Neue Nachricht",
    "messaging.messages_container.menu.inbox": "Posteingang",
    "messaging.messages_container.menu.outbox": "Postausgang",
    "messaging.messages_container.menu.contacts": "Kontakte",
    "messaging.messages_container.menu.filters": "Filter",
    "messaging.messages_container.messages.no_messages_found": "Es wurden keine Nachrichten gefunden.",
    "messaging.messages_container.messages.error_loading_messages": "Es ist ein Fehler aufgetreten und die Nachrichten konnten nicht geladen werden. Bitte versuchen Sie, die Seite zu einem späteren Zeitpunkt erneut zu laden.",
    "messaging.messages_container.messages.message_sent": "Die Nachricht wurde erfolgreich gesendet.",
    "messaging.messages_container.placeholder.search_term": "Suchbegriff",
    "messaging.messages_container.popup.unit_selection_disabled": "Bitte wählen Sie zunächst ein Projekt.",
    "messaging.message_container.buttons.reply": "Antworten",
    "messaging.message_container.buttons.tooltips.mark_read": "Ungelesen - klicken, um Nachricht als gelesen zu markieren",
    "messaging.message_container.buttons.tooltips.mark_unread": "Gelesen - klicken, um Nachricht als ungelesen zu markieren",
    "messaging.message_container.buttons.tooltips.select_project": "Bitte ordnen Sie die Nachricht einem Projekt zu, bevor Sie fortfahren.",
    "messaging.message_container.buttons.showOriginal": "Original E-Mail anzeigen",
    "messaging.message_container.empty_references.no_internal": "Keine internen Teilnehmer",
    "messaging.message_container.empty_references.no_external": "Keine externen Teilnehmer",
    "messaging.message_container.empty_references.no_project": "Kein zugeordnetes Projekt",
    "messaging.message_container.empty_references.no_unit": "Keine zugeordnete WE",
    "messaging.message_container.empty_references.no_activity": "Kein zugeordneter Vorgang",
    "messaging.message_container.empty_references.add_new_contact": "Neuen Kontakt anlegen",
    "messaging.message_container.empty_references.no_results_found": "Keine Ergebnisse gefunden",
    "messaging.message_container.header.reply": "Antwort schreiben",
    "messaging.message_container.header.new_message": "Neue Nachricht",
    "messaging.message_container.menu.back": "Zurück zum Postfach",
    "messaging.message_container.menu.internal": "Intern",
    "messaging.message_container.menu.external": "Extern",
    "messaging.message_container.menu.project": "Projekt",
    "messaging.message_container.menu.unit": "Wohneinheit",
    "messaging.message_container.menu.activity": "Vorgang",
    "messaging.message_container.thread.wrote": "schrieb",
    "messaging.message_container.title": "E-Mail - SWM",
    "messaging.drafts.title": "Meine Entwürfe",
    "messaging.drafts.no_subject": "kein Betreff",
    "messaging.drafts.no_drafts": "Es wurden keine Entwürfe gefunden.",
    "messaging.drafts.menu.title": "Meine Entwürfe",
    "messaging.drafts.delete.title": "Entwurf löschen",
    "messaging.drafts.delete.message": "Wollen Sie den Entwurf wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!",
    "messaging.drafts.cancel.title": "Entwurf verlassen",
    "messaging.drafts.cancel.message": "Achtung! Ungespeicherte Änderungen gehen verloren. Wollen Sie Ihre Änderungen speichern oder verwerfen?",
    "messaging.drafts.messages.saved.title": "Entwurf gespeichert",
    "messaging.drafts.messages.saved.body": "Ihr Entwurf wurde erfolgreich gespeichert",
    "messaging.drafts.messages.deleted.title": "Entwurf verworfen",
    "messaging.drafts.messages.deleted.body": "Ihr Entwurf wurde verworfen",
    "messaging.send_status.title": "Sendestatus",
    "configurator.title": "3D-Konfigurator",
    "configurator.missingMapping": "Produkt ist wählbar, wird aber nicht im 3D-Konfigurator abgebildet.",
    "configurator.disclaimer": "Die Visualisierung der Produkte und Räume zeigt unverbindliche Darstellungen. Farbtöne können je nach Monitor von der Realität abweichen.",
    "configurator.enterRoom": "Raum betreten",
    "configurator.stayHere": "Weiter Umschauen",
    "configurator.samplingProgress": "Bemusterungsfortschritt",
    "configurator.dashboard": "Dashboard",
    "configurator.switchRoom": "Raum wechseln",
    "configurator.preview": "3D-Vorschau",
    "configurator.productDetails": "Info",
    "configurator.menu": "Menü",
    "configurator.welcome.heading": "Hallo und Willkommen!",
    "configurator.welcome.body": "In diesem Bereich können Sie nun Ihre eigene Wohnung individuell gestalten, Viel Spaß!",
    "batchEdit.title": "Suche",
    "batchEdit.searchStatus": "{min, number} - {max, number} von {total, number}",
    "batchEdit.table_header.product_group.name": "Produkt­gruppe",
    "batchEdit.table_header.product": "Produkt­auswahl",
    "batchEdit.table_header.product.sku": "Produkt-SKU",
    "batchEdit.table_header.default_product": "Standard­auswahl",
    "batchEdit.table_header.default_product.sku": "Standard-SKU",
    "batchEdit.table_header.buyer_selection_product": "Konfigurator­auswahl",
    "batchEdit.table_header.buyer_selection_product.sku": "Konfiguratorauswahl-SKU",
    "batchEdit.table_header.quantity": "Menge",
    "batchEdit.table_header.default_quantity": "Standard­menge",
    "batchEdit.table_header.section.title": "Sektion",
    "batchEdit.table_header.unit": "Wohneinheit / Raumbuch",
    "batchEdit.table_header.title": "Titel",
    "batchEdit.table_header.room_book.template": "Vorlage",
    "batchEdit.header.select_operation": "Bearbeitungsvorgang auswählen",
    "batchEdit.header.delete": "Positionen löschen",
    "batchEdit.header.success": "Vorgang erfolgreich",
    "batchEdit.header.edit": "Positionen bearbeiten",
    "batchEdit.header.confirm_edit": "Änderungen bestätigen",
    "batchEdit.buttons.select_operation": "Weiter",
    "batchEdit.buttons.success": "Schließen",
    "batchEdit.buttons.cancel": "Abbrechen",
    "batchEdit.buttons.delete": "Löschen",
    "batchEdit.buttons.search": "Suchen",
    "batchEdit.buttons.edit": "Weiter",
    "batchEdit.buttons.editItems": "Bearbeiten",
    "batchEdit.buttons.confirm_edit": "Übernehmen",
    "batchEdit.messages.noSelection.label": "Keine Auswahl",
    "batchEdit.messages.noResults.header": "Kein Suchergebnis",
    "batchEdit.messages.noResults.content": "Die Suche ergab keine Treffer",
    "batchEdit.messages.disabledEdit.header": "Bearbeiten der Auswahl nicht möglich",
    "batchEdit.messages.disabledEdit.content": "Positionen aus unterschiedlichen Produktgruppen sowie Individualwünsche können nicht bearbeitet werden.",
    "batchEdit.messages.disabledDelete.header": "Löschen der Auswahl nicht möglich",
    "batchEdit.messages.disabledDelete.content": "Positionen, für die ein Vorgang angelegt wurde, können nicht gelöscht werden.",
    "batchEdit.messages.noUndo": "Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "batchEdit.messages.disabledCheckbox": "Die Position kann zur Zeit nicht bearbeitet werden",
    "batchEdit.messages.itemsSelected": "Ausgewählte Positionen: ",
    "batchEdit.messages.select_operation": "Bitte wählen Sie die Aktion aus, die Sie durchführen möchten:",
    "batchEdit.messages.delete": "Möchten Sie die folgenden Positionen wirklich löschen?",
    "batchEdit.messages.edit": "Möchten Sie die folgenden Änderungen übernehmen?",
    "batchEdit.messages.change_default_product": "Standardprodukt ändern",
    "batchEdit.messages.change_default_quantity": "Standardmenge ändern",
    "batchEdit.messages.change_product": "Die Produktauswahl ändern",
    "batchEdit.messages.change_quantity": "Menge ändern",
    "batchEdit.messages.success.delete.title": "Löschen erfolgreich!",
    "batchEdit.messages.success.delete.body": "{count, plural, =0 {Keine Position wurde} one {Eine Position wurde} other {# Positionen wurden}} gelöscht.",
    "batchEdit.messages.success.update.title": "Bearbeiten erfolgreich!",
    "batchEdit.messages.success.update.body": "{count, plural, =0 {Keine Position wurde} one {Eine Position wurde} other {# Positionen wurden}} geändert.",
    "batchEdit.labels.checkbox.newDefaultQuantity": "Standardmenge ändern",
    "batchEdit.labels.checkbox.newDefaultProductId": "Standardprodukt ändern",
    "batchEdit.labels.checkbox.newQuantity": "Menge ändern",
    "batchEdit.labels.checkbox.newProductId": "Produktauswahl ändern",
    "batchEdit.labels.product_group": "Produktgruppe",
    "batchEdit.labels.product": "Produkt",
    "batchEdit.labels.default_product": "Standardprodukt",
    "batchEdit.labels.full_text_search": "Volltextsuche",
    "batchEdit.labels.templates_only": "nur Raumbuchvorlagen",
    "batchEdit.labels.no_templates": "ohne Raumbuchvorlagen",
    "batchEdit.priceStrategy.length": "{quantity} m",
    "batchEdit.priceStrategy.pieces": "{quantity} Stk.",
    "batchEdit.priceStrategy.area": "{quantity} m²",
    "batchEdit.priceStrategy.hour": "{quantity} Std.",
    "batchEdit.priceStrategy.flat": "psch.",
    "batchEdit.priceStrategy.individual": "k.A.",
    "batchEdit.drop_down.product_groups_subheader": "{count, plural, =0 {Keine Produkte} one {# Produkt} other {# Produkte}}",
    "batchEdit.placeholder.newDefaultQuantity": "Standardmenge",
    "batchEdit.placeholder.newDefaultProductId": "Standardprodukt wählen",
    "batchEdit.placeholder.newQuantity": "Menge",
    "batchEdit.placeholder.newProductId": "Produkt wählen",
    "batchEdit.placeholder.select_product_group": "Produktgruppe wählen",
    "batchEdit.placeholder.select_product": "Produkt wählen",
    "batchEdit.placeholder.select_default_product": "Standardprodukt wählen",
    "batchEdit.placeholder.select_new_default": "Neues Standardprodukt",
    "batchEdit.placeholder.select_new_product": "Neue Produktauswahl",
    "batchEdit.radio.delete": "Löschen",
    "batchEdit.radio.edit": "Bearbeiten",
    "supplierIntegration.loading": "Herstellerkatalog wird geladen ...",
    "supplierIntegration.missing": "Es wurden keine Herstellerkataloge gefunden",
    "supplierIntegration.transfer.title": "Produkte übernommen",
    "supplierIntegration.transfer.body": "{count, plural, =0 {Alle Produkte sind bereits im Produktkatalog vorhanden.} one {Ein Produkt wurde erfolgreich in den Produktkatalog übernommen.} other {# Produkte wurden erfolgreich in den Produktkatalog übernommen.}}",
    "replaced_by": "Ersetzt durch:",
    "contractor_portal.footer.imprint": "Impressum",
    "contractor_portal.footer.data_privacy_statement": "Datenschutzerklärung",
    "contractor_portal.footer.terms": "Geschäftsbedingungen",
    "contractor_portal.price_inquiry.error.header": "Hoppla!",
    "contractor_portal.price_inquiry.error.Record not found!": "Dieses Preisformular wurde nicht gefunden!",
    "contractor_portal.price_inquiry.submitted_mail_subject": "sonderwunsch manager | Neues Preisangebot eingegangen",
    "contractor_portal.invitation.subject": "Einladung von %{sender_name} von %{account_name}",
    "edit_product_prices.action.success": "Die Preise wurden erfolgreich für übernommen",
    "edit_product_prices.action.text": "Produktpreise bearbeiten",
    "edit_product_prices.header.title": "Produktpreise für <i>{catalogName}</i> bearbeiten",
    "edit_product_prices.table.header.product_information": "Produktinformation",
    "edit_product_prices.table.header.cost_net": "Nachunternehmen Kosten Netto",
    "edit_product_prices.table.header.price.gross": "Preise Brutto",
    "edit_product_prices.table.header.price.net": "Preise Netto",
    "edit_product_prices.table.header.trade": "Gewerk",
    "edit_product_prices.filter.start_search": "Um die Suche zu starten, fügen Sie Kriterien zum Filter hinzu.",
    "edit_product_prices.filter.no_result_found": "Keine Produkte gefunden.",
    "edit_product_prices.filter.result_count": "gefundene Ergebnisse: {count} {count, plural, one {Produkt} other {Produkte}}",
    "edit_product_prices.filter.placeholder": "Geben Sie Kriterien ein, um die Produkte zu Filtern: Gewerk, Produktgruppe, Textsuche...",
    "edit_product_prices.filter.options.trade": "Gewerk | {tradeName}",
    "edit_product_prices.filter.options.product_group": "Produktgruppe | {pgName}",
    "edit_product_prices.filter.options.fullText": "Text | {searchQuery}",
    "edit_product_prices.filter.options.onlyDefault": "nur Standardprodukte",
    "contractor_portal_link": "Hier gelangen Sie in unser Portal: %{url}",
    "contractor_portal_link_html": "Hier gelangen Sie in unser Portal: <a href=\"%{url}\" target=\"_blank\">%{url}</a>",
    "errors.something_went_wrong": "Etwas ist schief gelaufen",
    "errors.no_contractors_for_activity": "Es wurde kein Nachunternehmer nominiert %{id}!",
    "errors.no_matching_contractors_for_activity": "Es wurden keine Nachunternehmer zu diesen Gewerken gefunden werden! Bitte stellen Sie sicher, dass sie Nachunternehmer für diese Gewerke definiert haben: %{trades}",
    "errors.no_matching_trade_for_recipient": "Es konnte kein Gewerk für den Nachunternehmer gefunden werden! Bitte stellen Sie sicher, dass der Nachunternehmer mit der E-Mail-Adresse %{email} für eines dieser Gewerke nominiert wurde: %{trades}.",
    "errors.contractor_user_already_exists": "Die digitale Preisfrage für '%{email}' kann nicht erstellt werden, da es bereits einen Benutzer mit dieser E-Mail-Adresse gibt.",
    "errors.account.trade_in_use": "Das Gewerk '%{trade_label}' ist in Verwendung und kann daher nicht gelöscht werden. Um es zu löschen, müssen die Verwendungen in %{models} gelöscht werden.",
    "errors.mail_delivery_failed": "E-Mail-Zustellung fehlgeschlagen: %{delivery_status}",
    "errors.unknown": "unbekannt",
    "errors.contractor_not_present": "Bitte nominieren Sie einen Nachunternehmer für diesen Vorgang.",
    "errors.contractor_has_no_price_inquiries": "Es konnte kein Nachunternehmer für diese Preisanfrage gefunden werden.",
    "upload_quantities_dialog.title": "Mengenänderung",
    "upload_quantities_dialog.error.title": "Es sind Fehler beim Verarbeiten der Datei aufgetreten",
    "upload_quantities_dialog.error.unknown_section": "Unbekannte Bauabschnitte {count}",
    "upload_quantities_dialog.error.unknown_units": "Unbekannte Wohneinheiten {count}",
    "upload_quantities_dialog.error.unknown_qunatity": "Unbekannte Standardmengen {count}",
    "upload_quantities_dialog.error.unknown_default_quantity": "Unbekannte Mehrmengen {count}",
    "affiliates.upscale.title": "Jeder verdient ein gutes Interior Design.",
    "affiliates.upscale.header": "Entdecken Sie die Top-Innendesign-Marken des Jahres 2020",
    "affiliates.upscale.content": "Nutzen Sie unser Full-Service-Angebot für die Inneneinrichtung Ihrer neuen Wohnung. Vordefinierte Einrichtungslinien - Individuelle Beratung von Ihrem persönlichen Innenarchitekten - Lieferung und Montage zum vereinbarten Zeitpunkt.",
    "affiliates.upscale.clickbait": "Entdecken Sie Ihren Interior Design - Stil",
    "exports.cancellation_base_total_item_description.replacement": "Entfall der Alternativleistung %{line_item_title}. Basispreis für die Umsetzung der Standardleistung %{replaced_title}, deren Entfall zuvor beauftragt wurde.",
    "exports.cancellation_base_total_item_description.default": "Entfall der Alternativleistung '%{line_item_title}'",
    "exports.base_total_item_description.replacement": "Entfall der Standardleistung '%{title}'",
    "exports.base_total_item_description.default": "Umsetzung der Leistung '${title}'",
    "exports.base_total_item_default_description.replacement": "Umsetzung der Standardleistung '%{title}'",
    "exports.base_total_item_default_description.default": "",
    "exports.status.open": "Offen",
    "exports.status.selected": "Erwerberauswahl",
    "exports.status.ordered": "Beauftragt",
    "exports.status.tendering": "Angebotserstellung",
    "exports.status.offering": "Beauftragung offen",
    "exports.status.cancelled": "Storniert",
    "exports.omitted_trade_hint": "Keine Leistung für %{trade} erforderlich.\n\n(%{default_summary})",
    "config_file_not_present": "Datei nicht im System vorhanden: ' %{file} '. Änderungen können nicht gespeichert werden.",
    "documentsExport.menu.assignments.title": "Projektzuordnung",
    "documentsExport.menu.exports.title": "Definitionen",
    "documentsExport.menu.styles.title": "Designs",
    "documentsExport.menu.contentHeaders.title": "Inhalte (vor LV)",
    "documentsExport.menu.signatures.title": "Inhalte",
    "documentsExport.menu.signatures.assignmentsTitle": "Inhalte (nach LV)",
    "documentsExport.filter.project": "Projekt",
    "documentsExport.filter.role": "Typ",
    "documentsExport.filter.group": "Gruppe",
    "documentsExport.filter.remove.title": "Filter entfernen",
    "documentsExport.dialogs.assignments.title.create": "Projektzuordnung erstellen",
    "documentsExport.dialogs.assignments.title.edit": "Projektzuordnung bearbeiten",
    "documentsExport.dialogs.assignments.placeholders.project": "Projekt",
    "documentsExport.dialogs.assignments.placeholders.export": "Definitionen",
    "documentsExport.dialogs.assignments.placeholders.style": "Designs",
    "documentsExport.dialogs.assignments.placeholders.header": "Inhalt vor den Positionen",
    "documentsExport.dialogs.assignments.placeholders.signature": "Inhalt nach den Positionen",
    "documentsExport.dialogs.exports.title.create": "Exportdefinition hinzufügen",
    "documentsExport.dialogs.exports.title.edit": "Exportdefinition bearbeiten",
    "documentsExport.dialogs.exports.tabs.base": "Basisdaten",
    "documentsExport.dialogs.exports.tabs.texts": "Dokumententexte",
    "documentsExport.dialogs.exports.tabs.dialogueFields": "Dialogfelder",
    "documentsExport.dialogs.exports.tabs.tradeHints": "Gewerkstexte",
    "documentsExport.dialogs.exports.tabs.content": "Produkttabelle",
    "documentsExport.dialogs.exports.tabs.settings": "Einstellungen",
    "documentsExport.dialogs.exports.tabs.json": "JSON",
    "documentsExport.dialogs.exports.placeholders.name": "Exportname",
    "documentsExport.dialogs.exports.placeholders.group": "Ebene",
    "documentsExport.dialogs.exports.placeholders.role": "Rolle",
    "documentsExport.dialogs.exports.tradeHints.title": "Gewerkehinweise",
    "documentsExport.dialogs.exports.tradeHints.add": "Hinzufügen",
    "documentsExport.dialogs.exports.tradeHints.removeConfirmation.title": "Gewerkehinweis enterfernen",
    "documentsExport.dialogs.exports.tradeHints.removeConfirmation.message": "Das Entfernen kann nicht rückgängig gemacht werden. Wollen Sie wirklich fortfahren?",
    "documentsExport.dialogs.exports.dialogueFields.text.header": "Dialogfelder",
    "documentsExport.dialogs.exports.dialogueFields.text.specialFieldsDropdown": "Spezialfelder",
    "documentsExport.dialogs.exports.dialogueFields.columns.title": "Titel",
    "documentsExport.dialogs.exports.dialogueFields.columns.key": "Schlüssel",
    "documentsExport.dialogs.exports.dialogueFields.columns.type": "Typ",
    "documentsExport.dialogs.exports.dialogueFields.columns.isRequired": "Muss ausgefüllt sein?",
    "documentsExport.dialogs.exports.dialogueFields.types.string": "Kurztext",
    "documentsExport.dialogs.exports.dialogueFields.types.text": "Langtext",
    "documentsExport.dialogs.exports.dialogueFields.types.date": "Datum",
    "documentsExport.dialogs.exports.dialogueFields.types.boolean": "Ankreuzfeld",
    "documentsExport.dialogs.exports.dialogueFields.errorMessages.emptyLabel": "leerer titel",
    "documentsExport.dialogs.exports.dialogueFields.errorMessages.emptyKey": "schlüssel darf nicht leer sein",
    "documentsExport.dialogs.exports.dialogueFields.errorMessages.leadingCtx": "muss mit \"ctx_\" anfangen",
    "documentsExport.dialogs.exports.dialogueFields.errorMessages.takenKey": "schlüssel bereits vergeben",
    "documentsExport.dialogs.exports.dialogueFields.errorMessages.whitespace": "leerzeichen nicht erlaubt",
    "documentsExport.dialogs.exports.content.lineItemHeader": "Hauptposition: Kopfzeile",
    "documentsExport.dialogs.exports.content.lineItemBody": "Hauptposition: Inhalte",
    "documentsExport.dialogs.exports.content.subLineItemHeader": "Unterposition: Kopfzeile",
    "documentsExport.dialogs.exports.content.subLineItemBody": "Unterposition: Inhalte",
    "documentsExport.dialogs.exports.content.addCell": "Spalte hinzufügen",
    "documentsExport.dialogs.exports.content.addRow": "Zeile hinzufügen",
    "documentsExport.dialogs.exports.content.deleteRow": "Zeile löschen",
    "documentsExport.dialogs.exports.content.if": "nur wenn",
    "documentsExport.dialogs.exports.content.unless": "nur wenn nicht",
    "documentsExport.dialogs.exports.content.noCells": "In dieser Zeile sind noch keine Spalten vorhanden",
    "documentsExport.dialogs.exports.content.dialog.title": "{editMode, select, true {Edit} other {Add}} Cell",
    "documentsExport.dialogs.exports.content.dialog.titleName": "Titel",
    "documentsExport.dialogs.exports.content.dialog.fieldName": "Feldname",
    "documentsExport.dialogs.exports.content.dialog.expression": "If/Unless",
    "documentsExport.dialogs.exports.content.dialog.condition": "Bedingung",
    "documentsExport.dialogs.exports.content.dialog.style": "Stil",
    "documentsExport.dialogs.exports.content.dialog.width": "Breite",
    "documentsExport.dialogs.exports.content.dialog.deprecated.title": "Veraltet",
    "documentsExport.dialogs.exports.content.dialog.deprecated.message": "Veraltetes Feld wird verwendet",
    "documentsExport.dialogs.exports.content.addCustomKey.add": "Eigener Schlüssel",
    "documentsExport.dialogs.exports.content.addCustomKey.title": "Benutzerdefinierten Schlüssel hinzufügen",
    "documentsExport.dialogs.exports.content.addCustomKey.key": "Schlüssel",
    "documentsExport.dialogs.exports.content.addCustomKey.value": "Wert",
    "documentsExport.dialogs.exports.content.addCustomKey.error": "Dieser Schlüssel Existiert bereits",
    "documentsExport.dialogs.exports.content.addCustomKey.noKey": "Geben Sie den Schlüssel ein",
    "documentsExport.dialogs.exports.content.addCustomKey.errorValue": "Geben Sie den Wert ein",
    "documentsExport.dialogs.styles.title.create": "Create Style",
    "documentsExport.dialogs.styles.title.edit": "Edit Style",
    "documentsExport.dialogs.styles.placeholders.name": "Enter style name",
    "documentsExport.dialogs.styles.labels.name": "Style name",
    "documentsExport.dialogs.styles.labels.accountLogo": "Upload account logo",
    "documentsExport.dialogs.styles.labels.projectLogo": "Upload project logo",
    "documentsExport.dialogs.styles.labels.layoutHeaderLogo": "Upload layout header logo",
    "documentsExport.dialogs.styles.labels.layoutFooterLogo": "Upload layout footer logo",
    "documentsExport.dialogs.styles.labels.position": "Positionierung",
    "documentsExport.dialogs.styles.labels.scale": "Größe",
    "documentsExport.dialogs.signatures.title.create": "Inhalt anlegen",
    "documentsExport.dialogs.signatures.title.edit": "Inhalt bearbeiten",
    "documentsExport.dialogs.signatures.placeholders.name": "Bezeichnung",
    "documentsExport.dialogs.duplicate.title": "Projektzuordnungen duplizieren",
    "documentsExport.dialogs.duplicate.sourceProject": "Quellprojekt",
    "documentsExport.dialogs.duplicate.targetProject": "Zielprojekt",
    "documentsExport.dialogs.preview.title": "Document previews",
    "documentsExport.dialogs.preview.labels.load": "Load",
    "documentsExport.dialogs.preview.labels.activity": "Activity",
    "documentsExport.dialogs.preview.labels.roomBook": "Roombook",
    "documentsExport.dialogs.editSignature.value": "Text",
    "documentsExport.dialogs.editSignature.newLine": "Leerzeilen (nach Text)",
    "documentsExport.dialogs.editSignature.addElements.heading1": "Überschrift",
    "documentsExport.dialogs.editSignature.addElements.heading2": "Text (fett)",
    "documentsExport.dialogs.editSignature.addElements.text": "Text",
    "documentsExport.dialogs.editSignature.addElements.signature": "Unterschrift",
    "documentsExport.dialogs.editSignature.title.create": "Inhalt anlegen",
    "documentsExport.dialogs.editSignature.title.edit": "Inhalt bearbeiten",
    "documentsExport.dialogs.editSignature.name": "Name",
    "documentsExport.dialogs.editSignature.removeConfirmation.title": "Inhalt löschen",
    "documentsExport.dialogs.editSignature.removeConfirmation.message": "Das Entfernen kann nicht rückgängig gemacht werden. Wollen Sie wirklich fortfahren?",
    "documentsExport.dialogs.editSignature.menu.visual": "Visuell",
    "documentsExport.dialogs.editSignature.menu.json": "JSON",
    "documentsExport.dialogs.editSignature.blocks.newPage": "Seite ausfüllen?",
    "documentsExport.dialogs.editSignature.blocks.deleteButton": "Block löschen",
    "documentsExport.dialogs.editSignature.blocks.deleteConfirmationMessage": "Wollen Sie den Block wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "documentsExport.dialogs.editSignature.conditions.title": "Bedingungen",
    "documentsExport.dialogs.editSignature.conditions.options.mustBePresent": "Muss vorhanden sein",
    "documentsExport.dialogs.editSignature.conditions.options.mustNotBePresent": "Darf nicht vorhanden sein",
    "documentsExport.dialogs.editSignature.conditions.add": "Regel hinzufügen",
    "documentsExport.dialogs.editSignature.conditions.modal.enterKey": "Schlüssel eingeben",
    "documentsExport.dialogs.editSignature.conditions.modal.keyTaken": "Schlüssel bereits vergeben",
    "documentsExport.removeConfirmation.assignment.title": "Delete Assignment",
    "documentsExport.removeConfirmation.assignment.message": "Are you sure you want to delete this Document Assignment? This action cannot be undone.",
    "documentsExport.removeConfirmation.export.title": "Delete Export",
    "documentsExport.removeConfirmation.export.message": "Are you sure you want to delete this Document Export? This action cannot be undone.",
    "documentsExport.removeConfirmation.style.title": "Delete Style",
    "documentsExport.removeConfirmation.style.message": "Are you sure you want to delete this Document Export Style? This action cannot be undone.",
    "documentsExport.removeConfirmation.signature.title": "Delete Signature",
    "documentsExport.removeConfirmation.signature.message": "Are you sure you want to delete this Document Export Signature? This action cannot be undone.",
    "documentsExport.messages.notDeletable": "Cannot delete. Used in existing assignment.",
    "documentsExport.messages.noPreviewTitle": "Vorschau derzeit leider nicht möglich",
    "documentsExport.messages.noPreviewActivity": "Bitte legen Sie zunächst einen Vorgang an, um die Vorschau des Dokumentenexportes zu aktivieren.",
    "documentsExport.messages.noPreviewRoomBook": "Bitte legen Sie zunächst ein Raumbuch an, um die Vorschau des Dokumentenexportes zu aktivieren.",
    "featureToggles.title": "Feature Toggles",
    "featureToggles.projectChoose": "Auswählen",
    "projectIssues.title": "Project Issues",
    "projectIssues.filter.label": "Filter",
    "projectIssues.filter.selectAccount.label": "Select an account",
    "projectIssues.filter.selectProject.label": "Select a project",
    "projectIssues.filter.selectUser.label": "Select an user",
    "projectIssues.filter.selectIssueType.label": "Select issue type",
    "projectIssues.filter.showResolved.label": "Erledigte anzeigen",
    "projectIssues.attributes.account.label": "Account",
    "projectIssues.attributes.project.label": "Projekt",
    "projectIssues.attributes.user.label": "Benutzer",
    "projectIssues.attributes.issueType.label": "Issue type",
    "projectIssues.attributes.subject.label": "Betreff",
    "projectIssues.attributes.message.label": "Nachricht",
    "projectIssues.messages.empty": "Keine Probleme gefunden ",
    "trashBin.title": "Papierkorb",
    "trashBin.subtitle": "Im Papierkorb können gelöschte Daten gefunden und wiederhergestellt werden",
    "trashBin.filter": "Zeitraum:",
    "trashBin.messages.success.title": "Erfolg",
    "trashBin.messages.success.body": "Objekt wurde wiederhergestellt: {message}",
    "trashBin.messages.error.title": "Fehler",
    "trashBin.messages.error.body": "Objekt konnte nicht wiederhergestellt werden: {error}",
    "trashBin.messages.empty": "Papierkorb ist leer",
    "trashBin.table.project": "Projekt",
    "trashBin.table.title": "Titel",
    "trashBin.table.versionId": "VID",
    "trashBin.table.deletedBy": "Gelöscht von",
    "trashBin.table.deletedAt": "Gelöscht am",
    "trashBin.table.action": "Aktion",
    "trashBin.tabs.project": "Projekte",
    "trashBin.tabs.section": "Bauabschnitte",
    "trashBin.tabs.unit": "Wohneinheiten",
    "trashBin.tabs.roombook": "Raumbucher",
    "trashBin.tabs.activity": "Vorgänge",
    "trashBin.tabs.messagethread": "Nachrichtenverläufe",
    "trashBin.tabs.message": "Nachrichten",
    "trashBin.tabs.projectdeadline": "Projektfristen",
    "trashBin.tabs.product": "Produkte",
    "trashBin.tabs.productgroup": "Produktgruppen",
    "trashBin.tabs.productoption": "Produktoptionen",
    "trashBin.tabs.projectcatalog": "Projektkataloge",
    "trashBin.tabs.pricecatalog": "Preiskataloge",
    "trashBin.tabs.roombooksection": "Raumbuch Abschnitte",
    "trashBin.tabs.roombooklineitem": "Raumbuch Hauptpositionen",
    "trashBin.tabs.roombooksublineitem": "Raumbuch Unterpositionen",
    "trashBin.tabs.buyer": "Erwerber",
    "trashBin.tabs.contractor": "Nachunternemen",
    "trashBin.tabs.unitvariabletype": "Mengenvariablen",
    "trashBin.tabs.unitvariable": "Mengenvariablen",
    "trashBin.tabs.documentexport": "Document Exporte",
    "trashBin.confirmationDialog.title": "{type} wiederherstellen",
    "trashBin.confirmationDialog.message": "Wollen Sie {type} mit der ID {id} wirklich wieder herstellen?",
    "versions.title": "Versionshistorie",
    "versions.headers.datum": "Datum/Uhrzeit",
    "versions.headers.user": "Benutzer",
    "versions.headers.action": "Aktion",
    "versions.headers.details": "Details",
    "versions.headers.child": "Kindelement",
    "versions.changes.title": "Änderung",
    "versions.changes.headers.field": "Feld",
    "versions.changes.headers.oldValue": "Alter Wert",
    "versions.changes.headers.newValue": "Neuer Wert",
    "versions.showDetails": "anzeigen",
    "versions.paramError": "Required params are missing.",
    "versions.compare.title": "Werte vergleichen",
    "references.title": "Referenzen für {subject_type} {subject_id}",
    "references.tooltip": "Referenzen",
    "references.empty": "Keine Referenzen gefunden",
    "references.link.label": "Öffnen",
    "references.headers.project_name": "Projektname",
    "references.headers.display_name": "Name",
    "references.headers.status": "Status",
    "references.headers.title": "Titel",
    "references.headers.link": "Link",
    "references.errors.noParams": "Required params are missing.",
    "payment_reminder.text": "Bitte begleichen Sie Ihre offene Rechnung, um sicherzustellen, dass Sie und Ihre Kunden weiterhin Zugriff auf die Plattform und den Online-Konfigurator haben. Sollten Sie dazu Fragen haben, wenden Sie sich bitte an unsere Buchhaltung: rechnung@baudigital.com",
    "projectEmail.edit.title": "E-Mail Adresse bearbeiten",
    "projectEmail.edit.fields.email.label": "E-Mail",
    "projectEmail.edit.fields.reply_to_email.label": "Interne Antwortadresse",
    "projectEmail.edit.fields.mailjet_account.label": "Mailjet Account",
    "projectEmail.edit.fields.bcc_emails.label": "BCC Adressen",
    "projectEmail.edit.fields.notes.label": "Notizen",
    "projectEmail.list.title": "Projekt E-Mail Adressen",
    "projectEmail.list.projectTitle": "Projekt {name}",
    "projectEmail.list.noEmails": "Es wurden noch keine E-Mails eingerichtet",
    "projectEmail.list.status.pending": "In Einrichnung",
    "projectEmail.list.status.active": "Aktiviert",
    "projectEmail.list.status.deactivated": "Deaktiviert",
    "projectEmail.list.details.alias.message": "Einrichtung der Alias E-Mail Adresse",
    "projectEmail.list.details.alias.pending": "Die Alias E-Mail Adresse wurde noch nicht von der Rubus Bau GmbH eingerichtet.",
    "projectEmail.list.details.alias.confirmed": "Die Alias E-Mail Adresse wurde erfolgreich von der Rubus Bau GmbH eingerichtet.",
    "projectEmail.list.details.alias.error": "Prüfung fehlgeschlagen",
    "projectEmail.list.details.outbound.message": "Einrichtung ausgehender E-Mails",
    "projectEmail.list.details.outbound.pending": "Die E-Mail Adresse ist für das Versenden ausgehender Nachrichten noch nicht vollständig eingerichtet.",
    "projectEmail.list.details.outbound.confirmed": "Die E-Mail Adresse ist für das Versenden ausgehender Nachrichten vollständig eingerichtet.",
    "projectEmail.list.details.outbound.error": "Prüfung fehlgeschlagen",
    "projectEmail.list.details.inbound.message": "Einrichtung eingehender E-Mails",
    "projectEmail.list.details.inbound.pending": "Die E-Mail Adresse ist für den Empfang eingehender Nachrichten noch nicht vollständig eingerichtet.",
    "projectEmail.list.details.inbound.confirmed": "Die E-Mail Adresse ist für den Empfang eingehender Nachrichten vollständig eingerichtet.",
    "projectEmail.list.details.inbound.messageTitle": "Prüfung: Eingehende E-Mails",
    "projectEmail.list.details.inbound.messageError": "Der Empfang eingehender E-Mails ist noch nicht funktionsfähig. Bitte prüfen Sie Ihre Einstellungen und probieren es erneut.",
    "projectEmail.list.details.inbound.messageSuccess": "Der Empfang eingehender E-Mails wurde erfolgreich geprüft.",
    "projectEmail.list.buttons.add": "E-Mail einrichten",
    "projectEmail.list.buttons.details": "Details",
    "projectEmail.list.buttons.check": "Prüfen",
    "projectEmail.list.buttons.instructions": "Anleitung",
    "projectEmail.wizard.title": "E-Mail Intergration Einrichten",
    "projectEmail.wizard.message.title": "Hinweis",
    "projectEmail.wizard.message.body": "Bitte prüfen Sie vorab mit Ihrer IT, ob die gewünschte E-Mail Adresse im Kontext des sonderwunsch managers genutzt werden kann. Bitte kündigen Sie ihr auch an, dass sie eine Anleitung für die Einrichtung der E-Mail Integration erhalten wird.",
    "projectEmail.wizard.labels.email": "E-Mail Adresse",
    "projectEmail.wizard.labels.name": "Name",
    "projectEmail.wizard.buttons.setup": "Einrichten",
    "projectEmail.wizard.buttons.next": "Weiter",
    "projectEmail.wizard.validation.invalidEmail": "E-Mail-Format ungültig",
    "projectEmail.wizard.validation.emailRequired": "E-Mail ist Pflichtfeld",
    "projectEmail.wizard.validation.contactNameMinLength": "mindestens 5 Zeichen",
    "projectEmail.instructionDialog.title": "Anleitung senden",
    "projectEmail.instructionDialog.body": "Teilen Sie die Anleitung für die Einrichtung der E-Mail Integration an Ihren technischen Ansprechpartner.",
    "projectEmail.instructionDialog.summary": "Unter folgendem Link wird aller erklärt, um die E-Mail {email} für den Sonderwunsch Manager einzurichten. Bitte teilen Sie diesen mit Ihrem technischen Ansprechpartner, um die Einrichtung durchzuführen.",
    "projectEmail.instructionDialog.alternative": "Alternative senden wir den Link auch gerne an Ihren Ansprechpartner per E-Mail direkt an:",
    "projectEmail.messages.delete.title": "Projekt E-Mail löschen",
    "projectEmail.messages.delete.message": "Wollen Sie diesen Projekt E-Mail löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "projectEmail.messages.deactivate.title": "Projekt E-Mail deaktivieren",
    "projectEmail.messages.deactivate.message": "Wollen Sie diesen Projekt E-Mail deaktivieren?",
    "unitSetup.tabs.unitVariables": "Mengen",
    "unitSetup.tabs.unitFeatures": "Merkmale",
    "unitFeatureAssignments.title": "Merkmale",
    "unitFeatureAssignments.filters.unit": "Wohneinheit",
    "unitFeatureAssignments.filters.unitFeature": "Mit Merkmal",
    "unitFeatureAssignments.filters.unitNoFeature": "Ohne Merkmal",
    "unitFeatureAssignments.table.unit": "Wohneinheit",
    "unitFeatureAssignments.table.unitFeatures": "Merkmale",
    "unitFeatureAssignments.table.action": "Aktion",
    "unitFeatureAssignments.actions.download.tooltip": "Merkmale herunterladen",
    "unitFeatureAssignments.actions.upload.tooltip": "Merkmale hochladen",
    "unitFeatureAssignments.dialog.title": "Wohneinheit Merkmale",
    "unitFeatureAssignments.dialog.unitFeatures": "Merkmalegruppen",
    "unitFeatureAssignments.upload.dialog.title": "Merkmale hohladen",
    "configuratorDesign.title": "Konfigurator Designs",
    "configuratorDesign.migration.label": "Konfigurator Design anlegen",
    "configuratorDesign.removeConfirmation.title": "Design löschen",
    "configuratorDesign.removeConfirmation.message": "Wollen Sie das Design wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "configuratorDesign.dialog.newTitle": "Design anlegen",
    "configuratorDesign.dialog.editTitle": "Design Bearbeiten",
    "configuratorDesign.dialog.listNewTitle": "Neues Design",
    "configuratorDesign.dialog.tabs.settings": "Einstellungen",
    "configuratorDesign.dialog.tabs.images": "Bilder",
    "configuratorDesign.dialog.tabs.logos": "Logos",
    "configuratorDesign.dialog.tabs.style": "Farben",
    "configuratorDesign.dialog.tabs.dashboards": "Dashboards",
    "configuratorDesign.dialog.tabs.translations": "Übersetzungen",
    "configuratorDesign.dialog.tabs.faqs": "FAQ",
    "configuratorDesign.dialog.tabs.pages": "Website (beta)",
    "configuratorDesign.dialog.contentPageEditor.url": "URL",
    "configuratorDesign.dialog.contentPageEditor.title": "Titel",
    "configuratorDesign.dialog.contentPageEditor.summary": "Summary",
    "configuratorDesign.dialog.contentPageEditor.role": "Rolle",
    "configuratorDesign.dialog.contentPageEditor.text": "Text",
    "configuratorDesign.dialog.contentPageEditor.variant": "Variant",
    "configuratorDesign.dialog.contentPageEditor.message": "Message",
    "configuratorDesign.dialog.contentPageEditor.action": "Action",
    "configuratorDesign.dialog.contentPageEditor.actionId": "ActionId",
    "configuratorDesign.dialog.contentPageEditor.color": "Color",
    "configuratorDesign.dialog.contentPageEditor.image": "Image",
    "configuratorDesign.dialog.contentPageEditor.noImages": "This project contains no images.",
    "configuratorDesign.dialog.contentPageEditor.direction": "Direction",
    "configuratorDesign.dialog.contentPageEditor.presentation": "Darstellung",
    "configuratorDesign.dialog.contentPageEditor.margin": "Margin",
    "configuratorDesign.dialog.contentPageEditor.html": "HTML content",
    "configuratorDesign.dialog.contentPageEditor.address": "Adresse",
    "configuratorDesign.dialog.contentPageEditor.latitude": "Breitengrad",
    "configuratorDesign.dialog.contentPageEditor.longitude": "Längengrad",
    "configuratorDesign.dialog.contentPageEditor.description": "Beschreibung",
    "configuratorDesign.dialog.contentPageEditor.subtitle": "Untertitel",
    "configuratorDesign.dialog.contentPageEditor.fullWidth": "Volle Breite",
    "configuratorDesign.dialog.contentPageEditor.label": "Label",
    "configuratorDesign.dialog.contentPageEditor.json": "JSON content",
    "configuratorDesign.dialog.contentPageEditor.addImage": "Bild hinzufügen",
    "configuratorDesign.dialog.contentPageEditor.addPage": "Seite hinzufügen",
    "configuratorDesign.dialog.contentPageEditor.editPage": "Seite bearbeiten",
    "configuratorDesign.dialog.contentPageEditor.type": "Typ",
    "configuratorDesign.dialog.contentPageEditor.css": "CSS",
    "configuratorDesign.dialog.name": "Name",
    "configuratorDesign.dialog.defaultButton": "Standarddesign",
    "configuratorDesign.dialog.defaultLabel": "Standard",
    "configuratorDesign.dialog.duplicateName": "Kopie von {originalName}",
    "configuratorDesign.dialog.project_logo": "Projektlogo",
    "configuratorDesign.dialog.project_logo_id": "Projektlogo",
    "configuratorDesign.dialog.project_logo_secondary": "Sekundäres Projektlogo",
    "configuratorDesign.dialog.project_logo_secondary_id": "Sekundäres Projektlogo",
    "configuratorDesign.dialog.project_logo_wide": "Projektlogo (flach)",
    "configuratorDesign.dialog.project_logo_wide_id": "Projektlogo (flach)",
    "configuratorDesign.dialog.project_logo_menu_id": "Projektlogo (Menü)",
    "configuratorDesign.dialog.project_logo_loader_id": "Projektlogo (Ladebildschirm)",
    "configuratorDesign.dialog.flat_finder_logo_id": "Flat Finder Logo",
    "configuratorDesign.dialog.flat_finder_logo_drawer_id": "Flat Finder Logo Drawer",
    "configuratorDesign.dialog.favicon": "Favicon",
    "configuratorDesign.dialog.favicon_id": "Favicon",
    "configuratorDesign.dialog.social_media_image": "Social Media Preview",
    "configuratorDesign.dialog.social_media_image_id": "Social Media Preview",
    "configuratorDesign.dialog.contact_image": "Kontaktdialog",
    "configuratorDesign.dialog.contact_image_id": "Kontaktdialog",
    "configuratorDesign.dialog.company_logo": "Unternehmenslogo",
    "configuratorDesign.dialog.company_logo_id": "Unternehmenslogo",
    "configuratorDesign.dialog.banner": "Banner (tablet + desktop)",
    "configuratorDesign.dialog.banner_id": "Banner (tablet + desktop)",
    "configuratorDesign.dialog.backdrop": "Backdrop (tablet + desktop)",
    "configuratorDesign.dialog.backdrop_id": "Backdrop (tablet + desktop)",
    "configuratorDesign.dialog.mobile_banner": "Banner (mobil)",
    "configuratorDesign.dialog.mobile_banner_id": "Banner (mobil)",
    "configuratorDesign.dialog.mobile_banner_public": "Banner (mobil, öffentliche Seiten)",
    "configuratorDesign.dialog.mobile_banner_public_id": "Banner (mobil, öffentliche Seiten)",
    "configuratorDesign.dialog.project_images": "Projektbilder",
    "configuratorDesign.dialog.project_image_ids": "Projektbilder",
    "configuratorDesign.dialog.marketing_banner": "Banner (Marktplatz)",
    "configuratorDesign.dialog.marketing_banner_id": "Banner (Marktplatz)",
    "configuratorDesign.dialog.marketing_hero": "Hero Image (Marktplatz)",
    "configuratorDesign.dialog.marketing_hero_id": "Hero Image (Marktplatz)",
    "configuratorDesign.dialog.marketing_project_images": "Projektbilder (Marktplatz)",
    "configuratorDesign.dialog.marketing_project_image_ids": "Projektbilder (Marktplatz)",
    "configuratorDesign.dialog.translations": "Übersetzungen",
    "configuratorDesign.dialog.newQuestion": "Neue Frage",
    "configuratorDesign.dialog.answer": "Antwort",
    "configuratorDesign.dialog.htmlHead": "HTML Header",
    "configuratorDesign.dialog.stylingMode.title": "Styling-Modus",
    "configuratorDesign.dialog.stylingMode.simple": "Einfach",
    "configuratorDesign.dialog.stylingMode.advanced": "Fortgeschritten",
    "configuratorDesign.dialog.marketingPortalDashboardVersion.title": "Dashboard (wenn abweichend für Marketingportal)",
    "configuratorDesign.dialog.dashboardVersion.title": "Dashboard",
    "configuratorDesign.dialog.dashboardVersion.values.v1": "Variante 1",
    "configuratorDesign.dialog.dashboardVersion.values.v2": "Variante 2",
    "configuratorDesign.dialog.dashboardVersion.values.v3": "Variante 3",
    "configuratorDesign.dialog.dashboardVersion.values.custom": "Custom",
    "configuratorDesign.dialog.dashboardVersion.custom.buyerPortalDashboard": "Custom Dashboard",
    "configuratorDesign.dialog.dashboardVersion.custom.marketingPortalDashboard": "Custom Dashboard",
    "configuratorDesign.dialog.dashboardMenu.values.left": "Links",
    "configuratorDesign.dialog.dashboardMenu.values.none": "Ohne",
    "configuratorDesign.dialog.buyerPortalDashboardMenu.title": "Menü",
    "configuratorDesign.dialog.marketingPortalDashboardMenu.title": "Menü (wenn abweichend für Marketingportal)",
    "configuratorDesign.dialog.theme.title": "Basisdesign",
    "configuratorDesign.dialog.theme.light": "Hell",
    "configuratorDesign.dialog.theme.dark": "Dunkel",
    "configuratorDesign.dialog.projectVideo.title": "Projektvideo (youtube link)",
    "configuratorDesign.dialog.appId.title": "App ID",
    "configuratorDesign.dialog.marketingPortalAppId.title": "App ID (wenn abweichend für Marketingportal)",
    "configuratorDesign.dialog.sourceLanguage.title": "Hauptsprache",
    "configuratorDesign.dialog.supportedLanguages.title": "Sprachen (Automatische Übersetzung)",
    "configuratorDesign.dialog.dashboardWidgets.title": "Dashboard Kacheln",
    "configuratorDesign.dialog.dashboardWidgets.values.project_video": "Projektvideo",
    "configuratorDesign.dialog.dashboardWidgets.values.project_images": "Bildergallery",
    "configuratorDesign.dialog.dashboardWidgets.values.floor_plan": "Grundriss",
    "configuratorDesign.dialog.dashboardWidgets.values.configurator": "Konfigurator",
    "configuratorDesign.dialog.buyerPortalSocialMediaProjectTitle.title": "Social Media Vorschau: Überschrift Projekt",
    "configuratorDesign.dialog.buyerPortalSocialMediaProjectDescription.title": "Social Media Vorschau: Beschreibung Projekt",
    "configuratorDesign.dialog.marketingPortalSocialMediaProjectTitle.title": "Social Media Vorschau: Überschrift Projekt (wenn abweichend für Marketingportal)",
    "configuratorDesign.dialog.marketingPortalSocialMediaProjectDescription.title": "Social Media Vorschau: Beschreibung Projekt (wenn abweichend für Marketingportal)",
    "configuratorDesign.dialog.buyerPortalConfig.title": "Buyer portal config",
    "configuratorDesign.dialog.marketingPortalConfig.title": "Marketing portal config",
    "configuratorDesign.dialog.pages.label": "Pages",
    "configuratorDesign.dialog.translationSreach.label": "Text suchen",
    "configuratorDesign.dashboard.labels.addElement": "Add element",
    "configuratorDesign.dashboard.labels.addCard": "Add card",
    "configuratorDesign.dashboard.labels.selectImage": "Select an image",
    "configuratorDesign.dashboard.labels.templates": "Starter Dashboard",
    "configuratorDesign.dashboard.labels.importTemplates": "Starter Dashboard importieren",
    "configuratorDesign.dashboard.dashboardBannerV2.type": "DashboardBannerV2.",
    "configuratorDesign.dashboard.dashboardBannerV2.label": "Adds a banner to the dashboard",
    "configuratorDesign.dashboard.marketingUnitInfo.type": "MarketingUnitInfo",
    "configuratorDesign.dashboard.marketingUnitInfo.label": "Adds the standard unit info to the dashboard",
    "configuratorDesign.dashboard.mobileMenu.type": "MobileMenu",
    "configuratorDesign.dashboard.mobileMenu.label": "Adds a mobile menu to the dashboard",
    "configuratorDesign.dashboard.htmlElement.type": "HtmlElement",
    "configuratorDesign.dashboard.htmlElement.label": "Adds a html element to the dashboard",
    "configuratorDesign.dashboard.contentRow.type": "Kacheln",
    "configuratorDesign.dashboard.contentRow.label": "Es können unterschiedliche Elemente als Kacheln angeordnet werden",
    "configuratorDesign.dashboard.feature.type": "Feature",
    "configuratorDesign.dashboard.feature.label": "Adds a Feature Card to the dashboard",
    "configuratorDesign.dashboard.imageGallery.type": "ImageGallery",
    "configuratorDesign.dashboard.imageGallery.label": "Adds a Image Gallery to the dashboard",
    "configuratorDesign.dashboard.projectMap.type": "Projektkarte",
    "configuratorDesign.dashboard.projectMap.label": "Es wird die Projektadresse auf einer Kartenkachel dargestellt",
    "configuratorDesign.dashboard.projectVideo.type": "Projektvideo (deprecated)",
    "configuratorDesign.dashboard.projectVideo.label": "Bitte nicht mehr nutzen! Es wird das oben konfigurierte Video als Kach dargestellt.",
    "configuratorDesign.dashboard.floorPlan.type": "FloorPlan",
    "configuratorDesign.dashboard.floorPlan.label": "Adds a Floor Plan to the dashboard",
    "configuratorDesign.dashboard.videoCard.type": "VideoCard",
    "configuratorDesign.dashboard.videoCard.label": "Adds a Video Card to the dashboard",
    "configuratorDesign.dashboard.infoItemCard.type": "InfoItemCard",
    "configuratorDesign.dashboard.infoItemCard.label": "Adds a Info Item Card to the dashboard",
    "configuratorDesign.dashboard.documentCard.type": "DocumentCard",
    "configuratorDesign.dashboard.documentCard.label": "Adds a Document Card to the dashboard",
    "configuratorDesign.dashboard.mapCard.type": "MapCard",
    "configuratorDesign.dashboard.mapCard.label": "Adds a Map Card to the dashboard",
    "configuratorDesign.dashboard.applicationBar.type": "ApplicationBar",
    "configuratorDesign.dashboard.applicationBar.label": "Adds an Application Bar to the dashboard",
    "configuratorDesign.dashboard.dashboardUnitInfo.type": "DashboardUnitInfo",
    "configuratorDesign.dashboard.dashboardUnitInfo.label": "Adds a Dashboard Unit Info to the dashboard",
    "configuratorDesign.dashboard.unitRecommendations.type": "UnitRecommendations",
    "configuratorDesign.dashboard.unitRecommendations.label": "Adds Unit Recommendations to the dashboard",
    "flatFinder.addNewFlatFinder": "Neuen FlatFinder hinzufügen",
    "flatFinder.editFlatFinder": "FlatFinder bearbeiten",
    "flatFinder.dialog.name": "Name",
    "flatFinder.dialog.dataUrl": "Daten-URL",
    "flatFinder.dialog.url": "URL",
    "flatFinder.dialog.slug": "Slug",
    "flatFinder.dialog.configuratorDesign": "Konfigurator Design",
    "flatFinder.dialog.deleteTitle": "FlatFinder löschen",
    "flatFinder.dialog.deleteMessage": "Soll der FlatFinder wirklich gelöscht werden?",
    "flatFinder.dialog.savedSuccessfully": "FlatFinder erfolgreich gespeichert",
    "flatFinder.dialog.changesNotSaved": "Änderungen nicht gespeichert",
    "flatFinder.dialog.newTitle": "Neuer FlatFinder",
    "flatFinder.dialog.editTitle": "FlatFinder bearbeiten",
    "flatFinder.dialog.default": "Default",
    "flatFinder.dialog.colorHover": "Color hover",
    "flatFinder.dialog.colorActive": "Color active",
    "flatFinder.dialog.welcomeTitle": "Welcome title",
    "flatFinder.dialog.welcomeMessage": "Welcome message",
    "flatFinder.dialog.placeholderImage": "Placeholder image",
    "flatFinder.dialog.config": "JSON config",
    "flatFinder.states.saving": "FlatFinder erfolgreich gespeichert",
    "packageManager.title": "Package Manager",
    "packageManager.install": "Installieren",
    "packageManager.installed": "Installiert",
    "packageManager.noPackages": "Es gibt keine Pakete",
    "observers.title": "Beobachter",
    "imageSelector.singleImage": "Einzelbild",
    "imageSelector.multipleImages": "Mehrere Bilder",
    "imageSelector.noImageSelected": "Kein Bild ausgewählt",
    "imageSelector.selectImage": "Bild auswählen",
    "imageSelector.selectImages": "Bilder auswählen",
    "imageSelector.uploadImage": "Bild hochladen",
    "imageSelector.uploadImages": "Bilder hochladen",
    "imageSelector.uploadImageError": "Fehler beim Hochladen des Bildes",
    "imageSelector.uploadImagesError": "Fehler beim Hochladen der Bilder",
    "imageSelector.mediaLibrary": "Mediathek",
    "imageSelector.cancelImageSelection": "Auswahl abbrechen",
    "imageSelector.confirmImageSelection": "Auswahl bestätigen",
    "imageSelector.imageGallery": "Bildergalerie",
    "imageSelector.totalImagesSelected": "Bilder ausgewählt",
    "imageSelector.removeImage": "Bild entfernen",
    "imageSelector.noImageUploaded": "Es wurden noch keine Bilder gewählt",
    "imageSelector.noImagesInMediaLibrary": "Es wurde noch kein Bild gewählt",
    "imageSelector.noImageSelectedForPreview": "Es wurde noch kein Bild gewählt",
    "imageSelector.fileExceededSize": "Die Datei ist zu groß. Bitte optimieren Sie das Bild.",
    "imageSelector.filterByImageReferenceCount": "Ungenutzte Bilder",
    "imageSelector.imageReferenceCount": "Anzahl Verwendungen",
    "imageSelector.copyButtonLabel": "URL kopieren",
    "imageSelector.copyTitle": "URL kopiert",
    "imageSelector.copyBody": "Die URL wurde in die Zwischenablage kopiert",
    "imageSelector.deleteDialog.deleteTitle": "Bild löschen",
    "imageSelector.deleteDialog.deleteMessage": "Sind Sie sicher, dass Sie das Bild löschen möchten?",
    "imageSelector.deleteDialog.message.success.title": "Löschen",
    "imageSelector.deleteDialog.message.success.description": "Bild wurde erfolgreich gelöscht",
    "imageSelector.deleteDialog.message.error.title": "Löschen",
    "imageSelector.deleteDialog.message.error.description": "Bild konnte nicht gelöscht werden",
    "dataExport.button.label": "Start Export",
    "dataExport.startMessage": "Der Export wurde gestartet",
    "fileImports.title": "Jobs",
    "fileImports.table.role": "Role",
    "fileImports.table.user": "Username",
    "fileImports.table.status": "Status",
    "fileImports.table.createdAt": "Created at",
    "fileImports.table.startedAt": "Started at",
    "fileImports.table.completedAt": "Completed/failed at",
    "fileImports.table.duration": "Duration [s]",
    "fileImports.table.file": "File",
    "projectFiles.uploadButton.label": "Datei hochladen",
    "projectFiles.empty": "Es wurden noch keine Dateien hochgeladen",
    "projectFiles.dialog.title": "Datei hochladen",
    "projectFiles.dialog.submitButton": "Hochladen",
    "devise.confirmations.confirmed": "Your email address has been successfully confirmed.",
    "devise.confirmations.send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
    "devise.confirmations.send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes.",
    "devise.failure.already_authenticated": "You are already signed in.",
    "devise.failure.inactive": "Your account is not activated yet.",
    "devise.failure.invalid": "Invalid %{authentication_keys} or password.",
    "devise.failure.locked": "Your account is locked.",
    "devise.failure.last_attempt": "You have one more attempt before your account is locked.",
    "devise.failure.not_found_in_database": "Invalid %{authentication_keys} or password.",
    "devise.failure.timeout": "Your session expired. Please sign in again to continue.",
    "devise.failure.unauthenticated": "You need to sign in or sign up before continuing.",
    "devise.failure.unconfirmed": "You have to confirm your email address before continuing.",
    "devise.mailer.confirmation_instructions.subject": "Confirmation instructions",
    "devise.mailer.reset_password_instructions.subject": "sonderwunsch manager | Anleitung zur Passwortvergabe",
    "devise.mailer.unlock_instructions.subject": "Unlock instructions",
    "devise.mailer.password_change.subject": "Password Changed",
    "devise.omniauth_callbacks.failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
    "devise.omniauth_callbacks.success": "Successfully authenticated from %{kind} account.",
    "devise.passwords.no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
    "devise.passwords.send_instructions.title": "Anleitung gesendet",
    "devise.passwords.send_instructions.message": "Sie erhalten in wenigen Sekunden eine E-Mail, um Ihr Passwort zurückzusetzen.",
    "devise.passwords.send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
    "devise.passwords.updated": "Your password has been changed successfully. You are now signed in.",
    "devise.passwords.updated_not_active": "Your password has been changed successfully.",
    "devise.registrations.destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
    "devise.registrations.signed_up": "Welcome! You have signed up successfully.",
    "devise.registrations.signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
    "devise.registrations.signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
    "devise.registrations.signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
    "devise.registrations.update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
    "devise.registrations.updated": "Your account has been updated successfully.",
    "devise.sessions.signed_in": "Signed in successfully.",
    "devise.sessions.signed_out": "Signed out successfully.",
    "devise.sessions.already_signed_out": "Signed out successfully.",
    "devise.unlocks.send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
    "devise.unlocks.send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
    "devise.unlocks.unlocked": "Your account has been unlocked successfully. Please sign in to continue.",
    "errors.messages.already_confirmed": "was already confirmed, please try signing in",
    "errors.messages.confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
    "errors.messages.expired": "has expired, please request a new one",
    "errors.messages.not_found": "not found",
    "errors.messages.not_locked": "was not locked",
    "errors.messages.not_saved.one": "1 error prohibited this %{resource} from being saved:",
    "errors.messages.not_saved.other": "%{count} errors prohibited this %{resource} from being saved:"
  }
};